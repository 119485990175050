import React from "react";
import { useAddresseeRelationships } from "../../state/api/addressees/useAddresseeRelationships";
import { useAddresseeRelationshipTypes } from "../../state/api/addressees/useAddresseeRelationshipTypes";
import { IAddressee, IAddresseeRelationship } from "../../types/ApiTypes";
import { getId, getModel } from "../../util/mongoUtil";
import Flex from "../container/Flex";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import { useNavigate } from "react-router-dom";
import { AppRouteParams, AppRoutes, getLink } from "../../config/AppRoutes";
import { useAddressees } from "../../state/api/addressees/useAddressees";

export interface IAddresseeRelationshipItemProps {
  addressee: IAddressee,
  relationship: IAddresseeRelationship,
  isLink?: boolean
}

export default function AddresseeRelationshipItem(props: IAddresseeRelationshipItemProps) {

  const {
    addresseeRelationshipTypesById,
    loadingAddresseeRelationshipTypes
  } = useAddresseeRelationshipTypes();

  const {
    addresseesByDatevId,
    loadingAddressees
  } = useAddressees();

  const {
    addressee,
    isLink,
    relationship
  } = props;

  const navigate = useNavigate();

  if (!addressee) return null;
  if (!relationship) return null;

  if (!relationship.has_addressee_id || !relationship.is_addressee_id) return null;
  if (relationship.has_addressee_id === relationship.is_addressee_id) return null;
  
  const id = relationship.has_addressee_id === addressee.id ? relationship.is_addressee_id : relationship.has_addressee_id;

  const isHasRelationship = relationship.has_addressee_id === addressee.id;
  const displayName = isHasRelationship ? relationship.is_addressee_display_name : relationship.has_addressee_display_name;

  const color = isHasRelationship ? "#DFDFDF" : "#D0D0FF";
  const type = addresseeRelationshipTypesById?.[relationship.relationshipType ?? ""];

  return (
    <Pill
      key={relationship._id}
      color={color}
      loading={loadingAddressees || loadingAddresseeRelationshipTypes}
      onClick={isLink ? () => {
        const addresseeForDatevId = getModel(id, addresseesByDatevId);
        if (!addresseeForDatevId) return;
        const link = getLink(AppRouteParams.Addressee, AppRoutes.Addressee, addresseeForDatevId._id);
        navigate(link)
      } : undefined}
      className="gap-2"
      icon={isHasRelationship ? "arrow-right" : "arrow-left"}
      withDivider
    >
      <Flex gap="0">
        <Typography>{displayName}</Typography>
        {
          type && <span style={{ fontSize: "0.8em" }}>{type.name}</span>
        }
      </Flex>
    </Pill>
  )
}