import React from "react";
import Flex from "../container/Flex";
import MonthlyCalendar from "./MonthlyCalendar";
import DailyAgendaSkeleton from "./DailyAgendaSkeleton";
import WeeklyAgenda from "./WeeklyAgenda";
import NowLine from "./NowLine";
import Typography from "../text/Typography";
import { getDateInXFrom } from "../../util/util";
import AgendaHeader, { AgendaHourWidth } from "./AgendaHeader";
import UpdateTaskButton from "../tasks/UpdateTaskButton";
import UpcomingTasks from "../tasks/UpcomingTasks";
import { useTasks } from "../../state/api/tasks/useTasks";
import { useRecurringTasks } from "../../state/api/tasks/useRecurringTasks";

const DaysDisplayed = 5;

export interface IAgendaProps {
  displayedDays: number,
  start?: Date,
  selectedDate: Date
}

export default function CalendarOverview() {

  const [selected, setSelected] = React.useState<Date>(new Date());
  const [start, setStart] = React.useState<Date>();
  const [end, setEnd] = React.useState<Date>();

  const {
    tasksByDate
  } = useTasks();

  const {
    recurringTasksByDate
  } = useRecurringTasks(selected);

  React.useEffect(() => {

    const day = selected.getDate();
    const newStart = getDateInXFrom({ days: -2 }, selected);
    const newEnd = getDateInXFrom({ days: 2 }, selected);

    if (!end || end.getDate() < day || !start || start.getDate() > day) {
      setStart(newStart);
      setEnd(newEnd);
    }

  }, [selected]);

  const agendaRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className="d-flex flex-column flex-sm-row gap-3 align-items-start w-100 h-100">
      <Flex fullWidth fullHeight style={{ overflow: "hidden" }}>
        <AgendaHeader
          displayedDays={DaysDisplayed}
          onDayClick={setSelected}
          selectedDate={selected}
          start={start}
        />
        <div className="position-relative d-flex flex-row w-100 h-100" style={{ flex: "0 1 auto", overflowY: "auto", scrollbarWidth: "none" }} ref={agendaRef}>
          <NowLine />
          <DailyAgendaSkeleton
            id={`days-skeleton`}
            style={{ width: AgendaHourWidth }}
            renderItem={day => (
              <Flex>
                <Typography color="muted" bold size="12" upper>
                  {new Intl.NumberFormat("en-US", { minimumIntegerDigits: 2 }).format(day)} Uhr
                </Typography>
              </Flex>
            )}
          />
          <WeeklyAgenda
            appointments={tasksByDate}
            recurringAppointments={recurringTasksByDate}
            displayedDays={DaysDisplayed}
            selectedDate={selected}
            start={start}
          />
        </div>
      </Flex>
      <Flex>
        <Flex>
          <MonthlyCalendar
            selectedDay={selected}
            recurringAppointments={recurringTasksByDate}
            onChange={setSelected}
            appointments={tasksByDate}
          />
          <UpdateTaskButton start={selected} />
        </Flex>
        <div
          style={{
            height: "1px",
            width: "100%",
            backgroundColor: "var(--background)"
          }}
        />
        <Flex overflowY="auto" fullWidth fullHeight>
          <UpcomingTasks />
        </Flex>
      </Flex>
    </div>
  )
}