import { useTenant } from "../state/api/tenant/useTenant";

export enum Label {
  TenantName = "tenantName",
  TeamMailAccounts = "teamMailAccounts",
}

export default function useLabels() {

  const {
    tenant
  } = useTenant();

  return (l: Label, multiple: boolean = false): string => {
    if (!l) return "";

    switch (l) {
      case Label.TenantName:

        if (multiple) return tenant?.settings?.titles?.tenantsWord ?? "Tenants";
        return tenant?.settings?.titles?.tenantWord ?? "Tenant";

      case Label.TeamMailAccounts: return tenant?.settings?.titles?.teamMailAccountsTitle ?? "Ihre Teams";
    }
  }
}