import React from "react";
import { featureCreate } from "../../api/Api";
import { IFeatureCreateRequest } from "../../api/requests/subscriptions/subscriptionRequests";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { useSortedFeatures } from "../../hooks/useSortedFeatures";
import { Locale } from "../../locale/Locale";
import { useFeatures } from "../../state/api/subscriptions/useFeatures";
import { useUser } from "../../state/api/user/useUser";
import { TiggiFeature } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import FieldWithLabel from "../forms/FormikField";
import ModalForm from "../modal/ModalForm";

export default function CreateFeatureButton() {

    const {
        user
    } = useUser();

    const { loadingFeatures, features, reloadFeatures } = useFeatures();

    const dispatch = useAppDispatch();
    const callApi = useApi();

    const {
        usableTiggiFeatures
    } = useSortedFeatures();

    if (!user || !user.isSuperAdmin) return null;
    
    return (
        <ModalForm 
            title={Locale.pages.services.addFeature} 
            button={<Button text={Locale.pages.services.addFeature} icon="plus" color="success" />}
            initialValues={{
                name: "",
                feature: TiggiFeature.CustomDomains
            } as IFeatureCreateRequest}
            onSubmit={async (values) => {
                const res = await callApi(featureCreate(values));
                if (!res) return false;
                await reloadFeatures();
                return true;
            }}
            sidebar={formik => <Button type="submit" text="Feature erstellen" icon="plus" loading={formik.isSubmitting} />}
        >
            {
                (formik) => (
                    <Flex className="w-100">
                        <div className="d-flex flex-row align-items-center w-100 gap-3">
                            <FieldWithLabel noMargin name="name" label="Name des Features" placeholder="z.B. Zugriff auf Domainverwaltung" className="w-100" />
                            <SearchableComboBox 
                                className="w-100"
                                label="Interne Feature-ID" 
                                values={usableTiggiFeatures} 
                                itemToId={v => v.key} 
                                itemToString={v => v.key} 
                                onItemClick={(v) => formik.setFieldValue("feature", v?.value)}
                            />
                        </div>
                    </Flex>
                )
            }
        </ModalForm>
    )
}