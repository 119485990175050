import React from "react";
import { useNavigate } from "react-router-dom";
import { AppColor } from "../../app/AppStyles";
import { generateClassName, generateStyle, generateStyleWithBase } from "../../hooks/useAttributes";
import IElementProps from "../../types/element.types";
import { getLuminosityOfColor, getRangeValueBasedOnBrightness, getReadableColor, getTextColorFromBackground, hexWithOpacity, isBrightColor, parseVarColorToHex } from "../../util/util";
import Icon from "../icons/Icon";
import "./Button.css";
import Tooltip from "../tooltip/Tooltip";
import Flex from "../container/Flex";

export type ButtonSize = "tiny" | "small" | "regular" | "large";

export type ButtonVariant = "solid" | "outline" | "text" | "hyperlink" | "icon" | "subtle";
export type ButtonType = "button" | "submit" | "reset";

export interface IButtonPropsBase {
  icon?: string,
  iconPosition?: "start" | "end",
  iconSize?: number,
  variant?: ButtonVariant,
  text?: string,
  type?: ButtonType,
  tooltip?: string,
  externalLink?: boolean,
  disabled?: boolean,
  readOnly?: boolean,
  disabledText?: string,
  size?: ButtonSize,
  openInNewTab?: boolean,
  to?: string,
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => (Promise<any> | any),
  disabledIcon?: string,
}

export interface IButtonProps extends Omit<IElementProps, "onClick">, IButtonPropsBase {
  color?: AppColor,
  hexColor?: string,
  loading?: boolean,
  loadingText?: string,
  align?: "start" | "end" | "center",
  secondStepQuestion?: string,
  preventFloatEndOnSubmit?: boolean,
}

export default function Button(props: IButtonProps) {

  const {
    className,
    externalLink = false,
    readOnly,
    to = "",
    hexColor,
    align = "center",
    iconPosition = "start",
    preventFloatEndOnSubmit = false,
    iconSize,
    variant = "solid",
    disabledIcon,
    disabledText,
    onClick,
    disabled,
    openInNewTab,
    loading,
    tooltip,
    text,
    type,
    loadingText,
    style,
    children,
    icon,
    secondStepQuestion,
    color,
    size = "regular"
  } = props;

  const [secondStepActive, setSecondStepActive] = React.useState<boolean>(false);
  const [hover, setHover] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(loading || false)

  React.useEffect(() => {
    if (loading === undefined) return;
    setIsLoading(loading);
  }, [loading]);

  const navigate = useNavigate();

  const clickHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (readOnly) return;
    
    if (secondStepQuestion && !secondStepActive) {
      setSecondStepActive(true);
      return;
    }

    try {
      setIsLoading(true);

      if (!to) {
        if (!onClick) return;
        await onClick(e);
        return;
      }

      if (!externalLink) {
        navigate(to);
        e.preventDefault();
        return;
      }

      if (openInNewTab) {
        window.open(to, "_blank");
        e.preventDefault();
        return;
      }

      window.location.href = to;
      e.preventDefault();
    }
    catch { }
    finally {
      setIsLoading(false);
      setSecondStepActive(false);
    }
  }

  const usedColor = parseVarColorToHex(color ?? hexColor ?? "primary");

  const getTextColor = () => {
    switch (variant) {
      case "solid":


        return (
          usedColor
            ? getTextColorFromBackground(usedColor, "#FFFFFF")
            : "#000000"
        )

      case "subtle":

        return getReadableColor(usedColor, 0.25);

      default: return usedColor;
    }
  }

  const textColor = getTextColor();
  const bgColor = hexWithOpacity(usedColor, variant === "subtle" ? 0.1 : 1);
  const bgFill = hexWithOpacity(usedColor, 0.2, true);

  const backgroundStyle = generateStyle({
    value: bgColor,
    name: "backgroundColor"
  })

  const getIconSize = () => {
    switch (size) {
      case "large": return iconSize ?? 24;
      case "regular": return iconSize ?? 18;
      case "small": return iconSize ?? 16;
      case "tiny": return 14;
    }
  }

  const getPadding = () => {
    switch (size) {
      case "large": return "0.625em 2em";
      case "tiny": return "0.375em 0.5em";
      default: return ".375em .75em";
    }
  }

  const getFontSize = () => {
    switch (size) {
      case "large": 
      case "regular": 
        return "1";

      case "small": return "0.8";
      case "tiny": return "0.6";
    }
  }

  const getBorderColor = () => {
    switch (variant) {
      case "outline":
      case "solid":

        return usedColor;

      case "subtle":

        return hover ? hexWithOpacity(usedColor, 0.3, true) : bgColor;

      case "icon":
      case "text":

        return hover ? bgFill : "transparent";

      default:

        return "transparent";
    }
  }

  const buttonStyle = generateStyleWithBase(style, [{
    value: textColor,
    name: "color"
  }, {
    unit: "em",
    standard: "1",
    value: getFontSize(),
    name: "fontSize"
  }, {
    name: "borderColor",
    value: getBorderColor()
  }, {
    name: "padding",
    value: getPadding()
  }
  ]);

  const isSubmit = type === "submit";
  const canFloatEnd = isSubmit && !preventFloatEndOnSubmit;

  const buttonClass = generateClassName(className, "button position-relative", {
    value: canFloatEnd,
    onTrue: "align-self-end"
  }, {
    value: variant,
    base: "button-"
  });

  const contentClass = generateClassName("button-content position-relative d-flex flex-row align-items-center gap-2", {
    value: align,
    base: "justify-content-"
  })

  const textClass = generateClassName("button-text text-nowrap m-0 p-0 ", {
    value: variant === "icon",
    onTrue: "button-text-icon",
    standard: "text-uppercase fw-bold"
  })

  const textStyle = generateStyle({
    name: "color",
    value: hover ? getTextColorFromBackground(hexWithOpacity(usedColor, 0.3, true), "#FFFFFF") : "black",
    important: true,
    applyCondition: variant === "icon"
  });

  const getButtonText = () => {
    if (isLoading) return loadingText || "Bitte warten...";
    if (disabled) return disabledText || text;
    if (secondStepActive) return secondStepQuestion ?? "Sicher?";
    if (children && typeof children === "string") return children;
    return text ?? "";
  }

  const buttonText = getButtonText();
  const hasText = !!buttonText;

  const iconComponent = (
    <Icon
      icon={disabled ? (disabledIcon || icon) : icon}
      loading={isLoading}
      size={getIconSize()}
      tooltip={variant === "icon" ? buttonText : ""}
    />
  );

  const btn = (
    <button
      type={type || "button"}
      onClick={clickHandler}
      className={buttonClass}
      style={buttonStyle}
      disabled={isLoading || disabled}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {
        hover && !(disabled || isLoading) && (
          <div
            className="button-nonsolid-hover-effect position-absolute w-100 h-100 top-0 start-0"
            style={generateStyle({
              name: "backgroundColor",
              important: false,
              value: bgFill
            })}
          />
        )
      }
      {
        (variant === "solid" || variant === "subtle") && <div className="button-background w-100 h-100 position-absolute top-0 start-0" style={backgroundStyle} />
      }
      <div className={contentClass}>
        {
          iconPosition === "start" && iconComponent
        }
        {
          (hasText || children) && <div style={textStyle} className={textClass}>{children || buttonText}</div>
        }
        {
          iconPosition === "end" && iconComponent
        }
      </div>
    </button>
  )

  const btnWithSecondStep = (secondStepQuestion && secondStepActive) ? (
    <Flex row gap="0">
      {btn}
      <Icon
        icon="x"
        color="error"
        size={getIconSize() * 1.5}
        onClick={() => setSecondStepActive(false)}
      />
    </Flex>
  ) : btn;

  if (!tooltip) return btnWithSecondStep;

  return (
    <Tooltip tooltip={tooltip}>{btnWithSecondStep}</Tooltip>
  )
}