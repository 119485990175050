import React from "react";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import { useUpcomingTasks } from "../../state/api/tasks/useUpcomingTasks";
import LoadingSpinner from "../loader/LoadingSpinner";
import { formatFromTo } from "../../util/date";
import Card from "../card/Card";
import { formatDate } from "../../util/formatter";
import { getId } from "../../util/mongoUtil";
import { useUser } from "../../state/api/user/useUser";
import useAuthorized from "../../hooks/useAuthorized";
import DeleteTaskButton from "./DeleteTaskButton";
import UpdateTaskButton from "./UpdateTaskButton";

export default function UpcomingTasks() {

  const {
    loadingUpcomingTasks,
    upcomingTasks
  } = useUpcomingTasks();

  const {
    user
  } = useUser();

  return (
    <>
      <Typography bold upper color="primary">Demnächst</Typography>
      {
        loadingUpcomingTasks
          ? <LoadingSpinner />
          : (
            !!upcomingTasks?.length
              ? upcomingTasks.map((t, index) => {

                const d = new Date(t.start);

                if (!d) return null;

                const days = Math.ceil((d.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));

                const getTime = () => {

                  const isToday = days <= 0;
                  const isTomorrow = days < 2;
                  const isThisWeek = days < 7;

                  const time = d.toLocaleTimeString("de-DE", { hour: "2-digit", minute: "2-digit" });

                  if (isToday) return time;
                  else if (isTomorrow) return `Morgen ${isToday}`;
                  else if (isThisWeek) return `In ${days} Tagen um ${time}`;
                  else return formatDate(t.start, true, true);
                }

                return (
                  <Card
                    key={`${t._id}_${index}`}
                    header={(
                      <Flex row justify="between">
                        <Typography bold>{t.subject}</Typography>
                        <Typography size="12" color="primary">
                          {
                            getTime()
                          }
                        </Typography>
                      </Flex>
                    )}
                  >
                    {
                      getId(t.createdBy) === getId(user) && (
                        <Flex fullWidth align="end">
                          <UpdateTaskButton task={t} />
                        </Flex>
                      )
                    }
                  </Card>
                )
              })
              : <Typography color="primary">Keine Termine</Typography>
          )
      }
    </>
  )
}