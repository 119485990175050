
import { ITenantAuthority } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useCurrentTenantAuthority = () => {
    const { data, isLoading, mutate } = useTiggiQuery<ITenantAuthority>(ApiRoutes.User.CurrentTenantAuthority);

    return {
        authority: data,
        loadingAuthority: isLoading,
        reloadAuthority: mutate
    }
}