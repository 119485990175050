import React from "react";
import { getDateInXFrom } from "../../util/util";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import { IAgendaProps } from "./CalendarOverview";

export const AgendaHourWidth = 55;
export const AgendaHeaderHeight = 125;

export interface IAgendaHeaderProps extends IAgendaProps {
  onDayClick: (day: Date) => void
}

export default function AgendaHeader(props: IAgendaHeaderProps) {

  const {
    displayedDays,
    selectedDate,
    start,
    onDayClick
  } = props;

  return (
    <Flex fullWidth row gap="0">
      <div style={{ width: AgendaHourWidth }} />
      {
        Array.from({ length: displayedDays }).map((_, i) => {

          const d = getDateInXFrom({ days: i }, start ?? selectedDate);
          const isSelectedDay = d.toDateString() === selectedDate.toDateString();

          return (
            <Flex 
              key={`agenda-header-${i}`}
              style={{
                width: `calc(100% / ${displayedDays})`,
                height: AgendaHeaderHeight
              }}
              align="start"
              justify="start"
              className="ps-2"
              gap="0"
              onClick={() => onDayClick(d)}
            >
              <Typography
                userSelect="none"
                upper
                bold
                color={isSelectedDay ? "muted" : "backgroundDarker"}
                size="14"
              >
                {d.toLocaleDateString("default", { weekday: "short" })}
              </Typography>
              <Typography
                noLinePadding
                userSelect="none"
                bold
                size="60"
                color={isSelectedDay ? "secondary" : "background"}
              >
                {d.getDate()}
              </Typography>
            </Flex>
          )
        })
      }
    </Flex>
  )
}