import React from "react";
import useApi from "../../hooks/useApi";
import Button from "../buttons/Button";
import { mailTemplatesDelete } from "../../api/Api";
import { useMailTemplates } from "../../state/api/templates/useMailTemplates";
import WithPermissions from "../permissions/WithPermissions";

export interface IDeleteMailTemplateButtonProps {
    templateId: string,
    afterDelete: () => void
}

export default function DeleteMailTemplateButton({templateId, afterDelete}: IDeleteMailTemplateButtonProps) {

    const { reloadMailTemplates } = useMailTemplates();

    const callApi = useApi();

    const deleteTemplate = async () => {
        const res = await callApi(mailTemplatesDelete({id: templateId}));
        if (!res || !res.success) return;
        await reloadMailTemplates();
        if (afterDelete) afterDelete();
    }

    return (
        <WithPermissions permissions={["templates.mails.delete"]}>
            <Button onClick={deleteTemplate} text="Template löschen" color="error" icon="trash" />
        </WithPermissions>
    )
}