import React from "react";
import ClientDetail from "../../components/clients/ClientDetail";
import UpdateClientForm from "../../components/clients/UpdateClientForm";
import Flex from "../../components/container/Flex";
import PageHeader from "../../components/label/PageHeader";
import PageLoader from "../../components/loader/PageLoader";
import Typography from "../../components/text/Typography";
import { AppRouteParams } from "../../config/AppRoutes";
import useRouteParam from "../../hooks/useRouteParam";
import Page from "../../components/container/Page";
import { Locale } from "../../locale/Locale";
import { useClient } from "../../state/api/clients/useClient";

export default function Client() {

  const clientId = useRouteParam(AppRouteParams.Client);

  const {
    client,
    loadingClient
  } = useClient(clientId ?? "");

  return (
    <Page
      header={(
        <Flex row fullWidth justify="between">
          <PageHeader showBackButton text={client?.name ?? Locale.titles.clientDetail} />
          { client && <UpdateClientForm client={client} />}
        </Flex>
      )}
    >
      {
        loadingClient
          ? <PageLoader />
          : (
            client
              ? <ClientDetail client={client} />
              : <Typography>Fehler beim Laden</Typography>
          )
      }
    </Page >
  )

}