import React from "react";
import { useOfficeAccounts } from "../../../state/api/office/useOfficeAccounts";
import { OfficeAccountStatus } from "../../../types/officeAccount.types";
import Flex from "../../container/Flex";
import PageLoader from "../../loader/PageLoader";
import WithPermissions from "../../permissions/WithPermissions";
import Table from "../../tables/Table";
import TableCell from "../../tables/TableCell";
import TableRow from "../../tables/TableRow";
import TeamBanner from "../../teams/TeamBanner";
import Typography from "../../text/Typography";
import UserRoleIndicator from "../../user/RoleBanner";
import LinkOfficeAccountButton from "./LinkOfficeAccountButton";
import OfficeMailAccountListenerStatus from "./OfficeAccountMailListenerStatus";
import OfficeAccountMailListenerStatusButton from "./OfficeAccountMailListenerStatusButton";
import OfficeAccountTypeBanner from "./OfficeAccountTypeBanner";
import RemoveOfficeAccountButton from "./RemoveOfficeAccountButton";
import UpdateOfficeAccountForm from "./UpdateOfficeAccountForm";

export default function LinkedAccountsOverview() {
  const { loadingOfficeAccounts, officeAccounts } = useOfficeAccounts();

  if (loadingOfficeAccounts) return <PageLoader />
  if (!officeAccounts) return <span>Keine Konten verknüpft</span>

  return (
    <WithPermissions permissions={["inboxes.all.read"]}>
      <Table
        headers={[
          {
            label: "Accountname"
          },
          {
            label: "Typ"
          },
          {
            label: "Zuweisungen"
          },
          {
            label: "Status"
          },
          {
            label: ""
          }
        ]}
        items={officeAccounts}
        renderItem={i => (
          <TableRow key={i._id}>
            <TableCell content={i.accountName} >
              <Flex gap="0">
                <Typography size="11">{i.accountName}</Typography>
                <Typography bold>{i.accountMail}</Typography>
              </Flex>
            </TableCell>
            <TableCell>
              <OfficeAccountTypeBanner type={i.type} />
            </TableCell>
            <TableCell>
              {
                i.assignments && i.assignments.personalUser && <UserRoleIndicator user={i.assignments.personalUser} displayNameAsValue />
              }
              {
                i.assignments && i.assignments.team && <TeamBanner team={i.assignments.team} />
              }
            </TableCell>
            <TableCell>
              <OfficeMailAccountListenerStatus officeAccount={i} />
            </TableCell>
            <TableCell>
              <Flex row justify="end" fullWidth className="ps-5" gap={4}>
                <UpdateOfficeAccountForm account={i} />
                {
                  i.status === OfficeAccountStatus.Active
                    ? <OfficeAccountMailListenerStatusButton officeAccount={i} />
                    : <LinkOfficeAccountButton type={i.type} onBehalfOfUser={i.assignments?.personalUser} reAuthOfficeAccountId={i._id} />
                }
                <RemoveOfficeAccountButton account={i} />
              </Flex>
            </TableCell>
          </TableRow>
        )}
      />
    </WithPermissions>
  )
}