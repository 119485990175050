import React from "react";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import IElementProps from "../../types/element.types";
import { getTextColorFromBackground, parseVarColorToHex } from "../../util/util";
import Icon from "../icons/Icon";
import Typography, { ITypographyProps } from "../text/Typography";
import "./Pill.css";
import { FlexAlign, FlexJustify } from "../container/Flex";

interface IPillProps extends IElementProps {
  color?: string;
  text?: string | number | null;
  textOptions?: ITypographyProps;
  hoverText?: string;
  hoverColor?: string;
  hoverIcon?: string;
  icon?: string;
  align?: FlexAlign;
  justify?: FlexJustify;
  size?: "small" | "medium" | "large";
  variant?: "pill" | "text" | "badge";
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  textColor?: string;
  iconColor?: string;
  iconSize?: number;
  iconImage?: string;
  clickable?: boolean;
  dontShowLoading?: boolean;
  withDivider?: boolean;
  rounded?: boolean;
  childPosition?: "start" | "end";
}

export default function Pill({
  children,
  icon,
  hoverIcon,
  loading,
  dontShowLoading,
  loadingText,
  align = "center",
  justify = "start",
  clickable,
  disabled,
  iconImage,
  iconColor,
  iconSize,
  withDivider,
  hoverColor,
  size = "medium",
  variant = "pill",
  hoverText,
  childPosition = "end",
  style,
  rounded = false,
  textColor,
  textOptions,
  onClick,
  text,
  color = "#FFFFFF00",
  className,
  onMouseEnter,
  onMouseLeave,
  onMouseOver
}: IPillProps) {

  const [height, setHeight] = React.useState<number>(0);
  const [hover, setHover] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(!!loading);

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (loading === undefined) return;
    setIsLoading(loading);
  }, [loading]);

  React.useEffect(() => {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();
    setHeight(rect.height * 0.7);
  }, []);

  const clickHandler = async () => {
    if (!onClick) return;

    setHover(false);

    if (!dontShowLoading) setIsLoading(true);

    try {
      await onClick();
    }
    catch { }

    setIsLoading(false);
  }

  const isDisabled = isLoading || disabled;

  const getBackgroundColor = () => {
    if (isDisabled) return "#F3F3F3";
    if (variant === "text") return "transparent";
    return parseVarColorToHex((hover && !!hoverColor) ? hoverColor : color);
  }

  const getTextColor = () => {
    if (isDisabled) return "#A3A3A3";
    if (variant === "text") return "black";
    if (textColor) return textColor;
    return getTextColorFromBackground(background, "#FFFFFF");
  }

  const background = getBackgroundColor();
  const tc = getTextColor();

  const getContent = () => {
    if (isLoading) return loadingText ?? "Lädt...";
    if (hover && hoverText) return hoverText;
    if (children) return children;
    return text;
  }

  const pillContent = getContent();

  const content = (
    pillContent
      ? (
        typeof pillContent === "string"
          ? <Typography userSelect="none" noLinePadding color={tc} {...textOptions}>{pillContent}</Typography>
          : pillContent
      )
      : null
  );

  const divider = (withDivider && !!icon) ? <div style={{ backgroundColor: tc, minHeight: height + "px", width: "1px", opacity: "0.15" }} className="pill-divider" /> : null;

  return (
    <div
      onMouseEnter={e => {
        if (!hoverColor && !hoverText && !hoverIcon && !onMouseEnter) return;
        setHover(true);
        onMouseEnter?.(e);
      }}
      onMouseLeave={e => {
        if (!hoverColor && !hoverText && !hoverIcon && !onMouseLeave) return;
        setHover(false);
        onMouseLeave?.(e);
      }}
      onMouseOver={e => {
        if (!hoverColor && !hoverText && !hoverIcon && !onMouseOver) return;
        setHover(true);
        onMouseOver?.(e);
      }}
      className={generateClassName(className, "pill", {
        value: variant,
        base: "pill-variant-"
      }, { value: !!onClick || clickable, onTrue: "pill-clickable" }, { value: rounded, onTrue: "pill-rounded" })}
      onClick={clickHandler}
    >
      <div className="pill-inner w-100 h-100 m-0 p-0 position-relative">
        <div ref={ref} className="pill-bg position-absolute top-0 start-0 w-100 h-100" style={generateStyle({ name: "backgroundColor", value: background })} />
        <div
          className={generateClassName("pill-content position-relative", {
            value: variant,
            base: "pill-content-"
          }, className, {
            value: align,
            base: "align-items-"
          }, {
            value: justify,
            base: "justify-content-"
          }, "d-flex w-100 gap-2 flex-row")}
          style={style}
        >
          {
            childPosition === "start" && (
              <>
                {content}
                {divider}
              </>
            )
          }
          {
            (loading || icon || hoverIcon) && (
              <Icon
                size={iconSize}
                iconImage={iconImage}
                hexColor={iconColor ?? tc}
                icon={hover && hoverIcon ? hoverIcon : icon}
                loading={isLoading}
                invertThisColor={background}
              />
            )
          }
          {
            childPosition === "end" && (
              <>
                {divider}
                {content}
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}
