import { Form, Formik } from "formik";
import React from "react";
import { userRequestPhoneNumberAccess, userUpdatePhoneNumber, userVerifyPhoneNumberAccess, userVerifyPhoneNumberUpdate } from "../../../api/Api";
import { IUserPhoneNumberUpdateRequest, IUserPhoneNumberVerifyRequest } from "../../../api/ApiRequests";
import { ModalType } from "../../../config/ModalTypes";
import useApi from "../../../hooks/useApi";
import useModal from "../../../hooks/useModal";
import { Locale } from "../../../locale/Locale";
import { IUser } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import Flex from "../../container/Flex";
import FieldWithLabel from "../../forms/FormikField";
import OtpInput from "../../forms/OtpInput";
import LoadingSpinner from "../../loader/LoadingSpinner";
import ModalDialog from "../../modal/ModalDialog";
import Typography from "../../text/Typography";
import MultiStepWizard from "../../wizard/MultiStepWizard";
import MultiStepWizardPage from "../../wizard/MultiStepWizardPage";

export default function AddPhoneNumberButton({ user }: { user: IUser }) {
  const [loadingOtp, setLoadingOtp] = React.useState<boolean>(false);
  const [initialCodeHasBeenLoaded, setInitialCodeHasBeenLoaded] = React.useState<boolean>(false);

  const callApi = useApi();
  const showModal = useModal();

  const refreshVerificationOtp = async (abortController?: AbortController) => {
    setLoadingOtp(true);

    try {
      const res = await callApi(userRequestPhoneNumberAccess(abortController));
  
      if (res?.canceled) return;
      if (res?.success) setInitialCodeHasBeenLoaded(true);

    }
    finally {
      setLoadingOtp(false);
    }
  }

  React.useEffect(() => {
    if (initialCodeHasBeenLoaded) return;

    const abortController = new AbortController();
    refreshVerificationOtp(abortController);

    return () => abortController.abort();
  }, []);

  const title = user.phoneNumber ? "Nummer ändern" : "Neue Nummer hinzufügen";

  return (
    <ModalDialog
      button={<Button text={title} icon="phone" />}
      title={title}
    >
      {
        closeForm => (
          <MultiStepWizard initialIndex={0} hideProgressBar>
            {
              (nextPage, prevPage) => [
                <MultiStepWizardPage>
                  <Formik
                    initialValues={{
                      otp: ""
                    } as IUserPhoneNumberVerifyRequest}
                    onSubmit={async (values) => {
                      if (!values?.otp) {
                        showModal({
                          type: ModalType.Error,
                          text: Locale.errors.logInVerification.noOtp
                        });
                        return;
                      }

                      const res = await callApi(userVerifyPhoneNumberAccess(values));

                      if (!res) return;

                      nextPage();
                    }}
                  >
                    {
                      (formik) => {
                        const storeOtpAndSubmit = (otp: string) => {
                          formik.setFieldValue("otp", otp);
                        }

                        return (
                          <Form className="w-100 h-100 d-flex flex-column">
                            <Flex fullWidth gap="4">
                              <Typography color="primary">Bevor Sie auf dieses Feature zugreifen können, müssen Sie sich authentifizieren.<br/><strong>Sie erhalten einen Code an Ihre E-Mail-Adresse. Bitte geben Sie diesen ein.</strong></Typography>
                              {
                                loadingOtp
                                  ? <LoadingSpinner text="Code wird versandt..." />
                                  : (
                                    <Flex row fullWidth>
                                      <OtpInput saveOtp={storeOtpAndSubmit} />
                                    </Flex>
                                  )
                              }
                              <Flex row justify="between" fullWidth>
                                <Button 
                                  onClick={async () => await refreshVerificationOtp()}
                                  variant="subtle"
                                  icon="arrow-clockwise"
                                  text="Neuen Code anfordern"
                                />
                                <Button 
                                  type="submit" 
                                  icon="arrow-right" 
                                  text="Weiter" 
                                  disabled={!formik.dirty || formik.values.otp.length < 6}
                                  loading={formik.isSubmitting} 
                                />
                              </Flex>
                            </Flex>
                          </Form>
                        )
                      }
                    }
                  </Formik>
                </MultiStepWizardPage>,
                <MultiStepWizardPage>
                  <Formik
                    initialValues={{
                      phoneNumber: ""
                    } as IUserPhoneNumberUpdateRequest}
                    onSubmit={async (values) => {
                      if (!values) return;

                      if (!values.phoneNumber) {
                        showModal({
                          text: "Bitte geben Sie eine Telefonnummer ein.",
                          type: ModalType.Error
                        });
                        return;
                      }

                      const res = await callApi(userUpdatePhoneNumber(values));

                      if (!res || !res.success) return;

                      nextPage();
                    }}
                  >
                    {
                      (formik) => (
                        <Form className="w-100 d-flex flex-column gap-3">
                          <FieldWithLabel type="tel" label="Ihre Telefonnummer" name="phoneNumber" />
                          <Button type="submit" text="Weiter" icon="arrow-right" loading={formik.isSubmitting} loadingText="Nummer wird überprüft..." />
                        </Form>
                      )
                    }
                  </Formik>
                </MultiStepWizardPage>,
                <MultiStepWizardPage>
                  <Formik
                    initialValues={{
                      otp: ""
                    } as IUserPhoneNumberVerifyRequest}
                    onSubmit={async (values) => {
                      if (!values) return;

                      if (!values.otp) {
                        showModal({
                          type: ModalType.Error,
                          text: Locale.errors.logInVerification.noOtp
                        });
                        return;
                      }

                      const res = await callApi(userVerifyPhoneNumberUpdate(values), true);

                      if (!res) return;

                      closeForm();
                    }}
                  >
                    {
                      (formik) => {
                        const storeOtpAndSubmit = (otp: string) => {
                          formik.setFieldValue("otp", otp);
                          formik.submitForm();
                        }

                        return (
                          <Form className="w-100 h-100 d-flex flex-column gap-3">
                            <Typography>
                              <strong>Fast geschafft!</strong><br/>Aktivieren Sie Ihre Telefonnummer, indem Sie den Code eingeben, welchen wir als SMS an die von Ihnen angegebene Telefonnummer versandt haben.
                            </Typography>
                            <Flex row>
                              <OtpInput saveOtp={storeOtpAndSubmit} text="" />
                            </Flex>
                            <div className="d-flex flex-row align-items-center justify-content-between">
                              <Button type="button" icon="arrow-left" variant="subtle" text="Telefonnummer anpassen" disabled={formik.isSubmitting} onClick={prevPage} />
                              <Button type="submit" text="Bestätigen" icon="save" color="success" loading={formik.isSubmitting} />
                            </div>
                          </Form>
                        )
                      }
                    }
                  </Formik>
                </MultiStepWizardPage>
              ]
            }
          </MultiStepWizard>
        )
      }
    </ModalDialog>
  )
}