import { useFormikContext } from "formik";
import React from "react";
import "./DateTimePicker.css";
import DateTimePicker, { IDateTimePickerPropsBase } from "./DateTimePicker";

export interface IFormikDateTimePickerProps extends IDateTimePickerPropsBase {
  name: string
}

export default function FormikDateTimePicker(props: IFormikDateTimePickerProps) {
  const {
    name
  } = props;

  const {
    values,
    setFieldValue
  } = useFormikContext();

  const getValue = () => {
    let value = values as any;

    try {
      const keys = name.split(".");

      for (const key of keys) {
        value = value[key];
      }
    }
    catch { }

    return value;
  }

  const date = getValue();
  const changeHandler = (d: Date) => setFieldValue(name, d);

  if (!date || !(date instanceof Date)) return null;

  return (
    <DateTimePicker {...props} date={date} onChange={changeHandler} />
  )
}