import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import useFilteredOfficeMails, { OfficeMailboxTab } from "../../../hooks/useFilteredOfficeMails";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import { useAllTeamOfficeAccounts } from "../../../state/api/office/useAllTeamOfficeAccounts";
import { useUser } from "../../../state/api/user/useUser";
import { IMailfolder } from "../../../types/mailfolder.schema";
import { IOfficeAccount, OfficeAccountType } from "../../../types/officeAccount.types";
import Flex from "../../container/Flex";
import LoadingSpinner from "../../loader/LoadingSpinner";
import WithPermissions from "../../permissions/WithPermissions";
import TeamBanner from "../../teams/TeamBanner";
import Typography from "../../text/Typography";
import LinkOfficeAccountButton from "../account/LinkOfficeAccountButton";
import "./OfficeInbox.css";
import OfficeInboxMails from "./OfficeInboxMails";

export interface IWithSelectedMailProps {
  selectedMail?: Message,
  setSelectedMail: (mail?: Message) => void,
  setSelectedFolder: (folder?: IMailfolder) => void,
  setEditorVisible: (visible: boolean) => void,
  selectedFolder?: IMailfolder,
  isResponding?: boolean,
  isEditorVisible?: boolean
}

export interface IOfficeInboxProps extends IWithSelectedMailProps {
  account?: IOfficeAccount,
  showAll?: boolean
}

export default function OfficeInbox(props: IOfficeInboxProps) {

  const {
    account,
    showAll
  } = props;

  const [currentAccount, setCurrentAccount] = React.useState<IOfficeAccount>();

  const { getAccountPermissions } = useInboxPermissions();

  const { allTeamOfficeAccounts, loadingAllTeamOfficeAccounts, reloadAllTeamOfficeAccounts } = useAllTeamOfficeAccounts();
  const { user, loadingUser } = useUser();

  const {
    inbox
  } = useFilteredOfficeMails();

  React.useEffect(() => {
    if (inbox !== OfficeMailboxTab.Teams) {
      setCurrentAccount(account);
      return;
    }

    if (loadingAllTeamOfficeAccounts) return;

    if (!allTeamOfficeAccounts?.length) {
      setCurrentAccount(undefined);
      return;
    }

    if (!account) {
      setCurrentAccount(allTeamOfficeAccounts[0]);
      return;
    }

    const teamAccount = allTeamOfficeAccounts.find(a => a._id === account._id);
    setCurrentAccount(teamAccount ?? allTeamOfficeAccounts[0]);
  }, [inbox, loadingAllTeamOfficeAccounts, allTeamOfficeAccounts, account]);

  if (!currentAccount) {

    if (inbox === OfficeMailboxTab.Personal) return (
      <Flex justify="start" fullWidth fullHeight >
        <Typography color="primary">
          Sie haben Ihr Office365-Konto noch nicht mit unserem System verknüpft.<br />
          Verknüpfen Sie Ihr Konto, um Ihre Nachrichten zu sehen.
        </Typography>
        {
          loadingUser
            ? <LoadingSpinner />
            : <LinkOfficeAccountButton type={OfficeAccountType.Personal} onBehalfOfUser={user?._id} />
        }
      </Flex>
    )

    if (inbox === OfficeMailboxTab.Teams && loadingAllTeamOfficeAccounts) return <LoadingSpinner text="Konten werden geladen..." />
    return <span>{showAll ? "Kein Konto ausgewählt" : "Kein Konto verknüpft"}</span>
  }

  const className = generateClassName("office-inbox position-relative w-100", {
    value: inbox === OfficeMailboxTab.Teams,
    onTrue: "office-inbox-team"
  });

  return (
    <WithPermissions permissions={getAccountPermissions("read", currentAccount)}>
      <Flex className={className} gap={3}>
        {
          inbox === OfficeMailboxTab.Teams && (
            <Flex className="w-100">
              <Typography size={14} bold>Verfügbare Teams</Typography>
              <Flex row wrap fullWidth>
                {
                  loadingAllTeamOfficeAccounts
                    ? <LoadingSpinner />
                    : (
                      allTeamOfficeAccounts?.length
                        ? allTeamOfficeAccounts.map(a => (
                          <TeamBanner
                            key={a._id}
                            team={a.assignments.team!}
                            onClick={() => setCurrentAccount(a)}
                            isActive={currentAccount && currentAccount._id === a._id}
                          />
                        ))
                        : <span>Keine Teams</span>
                    )
                }
              </Flex>
            </Flex>
          )
        }
        <OfficeInboxMails {...props} account={currentAccount} />
      </Flex>
    </WithPermissions>
  )
}