import React from "react";
import { officeAccountStartMailService, officeAccountStopMailService } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import { useOfficeMailListenerStatus } from "../../../state/api/office/useOfficeMailListenerStatus";
import Button from "../../buttons/Button";
import WithPermissions from "../../permissions/WithPermissions";
import { IOfficeAccount } from "../../../types/officeAccount.types";
import { useUser } from "../../../state/api/user/useUser";

export default function OfficeAccountMailListenerStatusButton({officeAccount}: {officeAccount: IOfficeAccount}) {
    const { loadingOfficeMailListenerStatus, officeMailListenerStatus, reloadOfficeMailListenerStatus } = useOfficeMailListenerStatus(officeAccount._id);

    const {
        user
    } = useUser();

    const {
        getAccountPermissions
    } = useInboxPermissions();

    const callApi = useApi();

    const isStart = !officeMailListenerStatus || !!user?.isSuperAdmin;

    const clickHandler = async () => {
        if (isStart) await callApi(officeAccountStartMailService({id: officeAccount._id}));
        else callApi(officeAccountStopMailService({id: officeAccount._id}));
        await reloadOfficeMailListenerStatus();
    }

    const permissions = getAccountPermissions("read", officeAccount);

    if (officeMailListenerStatus && !user?.isSuperAdmin) return null;

    return (
        <WithPermissions permissions={permissions} >
            <Button 
                loading={loadingOfficeMailListenerStatus}
                loadingText="Status wird überprüft..."
                text={isStart ? user?.isSuperAdmin ? "Neu starten" : "Service starten"  : "Service stoppen" }
                color={isStart ? "success" : "error"}
                onClick={clickHandler}
                icon={isStart ? "check" : "x"}
            />
        </WithPermissions>
    )
}