import { useFormikContext } from "formik";
import React from "react";
import { IOpeningHours } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import LabelButton from "../../buttons/LabelButton";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import "./DayOpeningHoursField.css";
import Divider from "../../menu/sidebar/Divider";
import Typography from "../../text/Typography";

export interface IDayOpeningHoursFieldProps {
    fieldName: string,
    value: IOpeningHours[],
    label: string,
    applyToAll: (value: IOpeningHours[], workdaysOnly: boolean) => void
}

export default function DayOpeningHoursField({fieldName, value, applyToAll, label}: IDayOpeningHoursFieldProps) {

    const {
        setFieldValue
    } = useFormikContext();

    const removeOpeningHour = (index: number) => {
        if (!value || !value.length) return;
        const newValue = [...value];
        newValue.splice(index, 1);
        setFieldValue(fieldName, newValue);
    }

    const addOpeningHour = () => {
        const newValue = [...value];
        newValue.push({
            fromHour: 8,
            fromMinute: 30,
            toHour: 17,
            toMinute: 0
        });
        setFieldValue(fieldName, newValue);
    }

    const updateOpeningHour = (index: number, hourKey: keyof IOpeningHours, minuteKey: keyof IOpeningHours, newValue: number) => {
        
        if (!value || !value.length) return;

        const totalMinutes = (!isNaN(newValue) ? newValue : 0) / 1000 / 60;

        const minutes = totalMinutes % 60;
        const hours = (totalMinutes - minutes) / 60;

        const newValueArray = [...value];

        newValueArray[index][hourKey] = hours;
        newValueArray[index][minuteKey] = minutes;

        setFieldValue(fieldName, newValueArray);
    }

    return (
        <Card strongColor>
            <Flex className="w-100">
                <Flex row justify="between" className="w-100">
                    <Typography bold size="16" color="primary">{label}</Typography>
                    <Flex row gap="3">
                        <Button 
                            variant="subtle"
                            color="error" 
                            icon="x" 
                            onClick={async () => setFieldValue(fieldName, [])} 
                            text="Als geschlossen markieren" 
                        />
                        <Button 
                            icon="plus" 
                            onClick={async () => addOpeningHour()} 
                            text="Neue Öffnungszeitspanne" 
                        />
                    </Flex>
                </Flex>
                {
                    value && value.length
                    ? value.map((openingHour, index) => (
                        <Card fitContent key={index}>
                            <Flex row gap={3}>
                                <Flex row>
                                    <input 
                                        className="text-center day-time-input"
                                        onChange={e => updateOpeningHour(index, "fromHour", "fromMinute", e.target.valueAsNumber)} 
                                        type="time"
                                        min="0" 
                                        max="24" 
                                        value={`${openingHour.fromHour.toString().padStart(2, '0')}:${openingHour.fromMinute.toString().padStart(2, '0')}`} 
                                    />
                                    <strong>bis</strong>
                                    <input 
                                        className="text-center day-time-input" 
                                        onChange={e => updateOpeningHour(index, "toHour", "toMinute", e.target.valueAsNumber)} 
                                        type="time"
                                        min="0" 
                                        max="24" 
                                        value={`${openingHour.toHour.toString().padStart(2, '0')}:${openingHour.toMinute.toString().padStart(2, '0')}`} 
                                    />
                                </Flex>
                                <Divider width="1" height="25" opacity={0.2} color="dark" />
                                <Button
                                    variant="subtle"
                                    icon="x" 
                                    color="error" 
                                    size="small"
                                    onClick={() => removeOpeningHour(index)} 
                                    text="Schließen"
                                />
                            </Flex>
                        </Card>
                    ))
                    : <strong style={{color: "red"}}>Geschlossen</strong>
                }
            </Flex>
            <Flex fullWidth justify="end" row gap="1">
                <Button 
                    variant="subtle"
                    icon="clipboard2-minus" 
                    onClick={async () => applyToAll(value, true)} 
                    text="Übertragen auf Werktage" 
                    size="small"
                />
                <Button 
                    variant="subtle"
                    icon="clipboard" 
                    onClick={async () => applyToAll(value, false)} 
                    text="Übertragen auf alle Tage" 
                    size="small"
                />
            </Flex>
        </Card>
    )

}