import React, { useState } from "react";
import { ticketsCreate } from "../../api/Api";
import { ITicketCreationRequest } from "../../api/ApiRequests";
import { ModalType } from "../../config/ModalTypes";
import useApi from "../../hooks/useApi";
import useFilteredTickets from "../../hooks/useFilteredTickets";
import useModal from "../../hooks/useModal";
import { useClientContacts } from "../../state/api/clientContacts/useClientContacts";
import { useEmployees } from "../../state/api/employees/useEmployees";
import { useTicketsWithFilter } from "../../state/api/tickets/useTicketsWithFilter";
import { useCurrentTenantRole } from "../../state/api/user/useCurrentTenantRole";
import { useUser } from "../../state/api/user/useUser";
import { useUserClients } from "../../state/api/user/useUserClients";
import { IUser, TicketSource, TicketType } from "../../types/ApiTypes";
import Button, { ButtonVariant } from "../buttons/Button";
import ClientSelect from "../clients/ClientSelect";
import Flex from "../container/Flex";
import CheckBox from "../forms/CheckBox";
import FieldWithLabel from "../forms/FormikField";
import AttachmentSelect from "../mail/inbox/AttachmentSelect";
import ModalForm from "../modal/ModalForm";
import Editor from "../richText/Editor";
import Typography from "../text/Typography";
import UserSelect from "../user/UserSelect";
import "./CreateTicketModal.css";
import TicketChatHtmlMessageContent from "./ticketChat/messages/TicketChatHtmlMessageContent";
import TicketTypeRadioGroup from "./TicketTypeRadioGroup";
import Card from "../card/Card";

export interface ICreateTicketFormProps {
  title?: string,
  variant?: ButtonVariant,
  buttonText?: string,
  initialSubject?: string,
  initialMessage?: string,
  initialMailAddress?: string,
  createFromMailId?: string,
  createFromMailAccountId?: string,
  firstMessageIsFromClient?: boolean,
  afterCreate?: () => Promise<void>
}

export default function CreateTicketModal({ title, afterCreate, createFromMailId, variant = "icon", buttonText = "Neues Ticket erstellen", createFromMailAccountId, initialMailAddress, firstMessageIsFromClient = false, initialMessage, initialSubject }: ICreateTicketFormProps) {

  const [renderHtml, setRenderHtml] = useState<boolean>(true);
  const [initialClientContact, setInitialClientContact] = React.useState<IUser>();
  const [initialType, setInitialType] = React.useState<TicketType>();

  const { getFilterForRoute } = useFilteredTickets();
  const {
    reloadTickets: reloadFilteredTickets
  } = useTicketsWithFilter(getFilterForRoute());

  const { user } = useUser();
  const { role } = useCurrentTenantRole();
  const { clientContacts } = useClientContacts();
  const { employees } = useEmployees();

  const { currentUserClients, loadingCurrentUserClients } = useUserClients();

  const isNotClient = (role && !role.isClient) || (user?.isSuperAdmin);

  const showModal = useModal();
  const callApi = useApi();

  const updateInitialClientContact = (val: IUser | undefined) => {
    setInitialClientContact(val);
    setInitialType(val ? TicketType.TicketByClient : TicketType.ExternalTicket);
  }

  React.useEffect(() => {
    if (!createFromMailId) return;

    if (!initialMailAddress) return updateInitialClientContact(undefined);
    if (!clientContacts || !clientContacts.length) return updateInitialClientContact(undefined);

    const clientContact = clientContacts.find(c => c.mailAddress === initialMailAddress);

    if (!clientContact) return updateInitialClientContact(undefined);
    updateInitialClientContact(clientContact);
  }, [initialMailAddress, clientContacts]);

  const getTicketCreatedMessage = () => {
    if (role && role.isClient) return "Ticket erfolgreich angelegt. Wir werden uns in Kürze bei Ihnen melden!";
    return "Ticket erfolgreich angelegt.";
  }

  return (
    <ModalForm
      title={f => {
        if (title) return title;

        switch (f.values.type) {
          case TicketType.ExternalTicket: return "Externes Ticket eröffnen";
          case TicketType.InternalTicket: return "Internes Ticket eröffnen";
          case TicketType.TicketByClient: return "Ticket eröffnen";
          case TicketType.TicketToClient: return "Ticket an Mandant eröffnen";
        }
      }}
      sidebar={(formik, close) => (
        <Flex fullWidth gap="4">
          <Flex fullWidth>
            {
              isNotClient && (
                <CheckBox
                  tooltip="Sendet eine Mail über das eröffnete Ticket an den Mandanten"
                  name="sendMail"
                  label={formik.values.type === TicketType.InternalTicket ? "Mail an Mitarbeiter senden" : "Mail an Mandant senden"}
                />
              )
            }
            {
              user && user.isSuperAdmin && <CheckBox name="isDeveloperTicket" label="Entwickler-Ticket" />
            }
          </Flex>
          <Button
            loading={formik.isSubmitting}
            disabled={!formik.dirty}
            loadingText="Bitte warten..."
            type="submit"
            text="Ticket erstellen"
            icon="plus"
            color="success"
            className="w-100"
          />
        </Flex>
      )}
      enableReinitialize
      initialValues={{
        subject: initialSubject || "",
        isDeveloperTicket: false,
        firstMessage: initialMessage || "",
        clientMail: initialMailAddress || "",
        createFromMailAccountId: createFromMailId ? createFromMailAccountId : undefined,
        createFromMailId: createFromMailId || "",
        firstMessageIsFromClient: firstMessageIsFromClient,
        source: createFromMailId ? TicketSource.ManualFromMail : TicketSource.Web,
        firstNote: "",
        type: initialType !== undefined ? initialType : TicketType.TicketByClient,
        attachments: [],
        sendMail: true,
        assignee: undefined,
        clientContactId: !isNotClient ? user?._id : (initialClientContact && initialClientContact._id) || undefined,
        clientId: currentUserClients ? (currentUserClients.defaultClient ? currentUserClients.defaultClient._id : (currentUserClients.clients && currentUserClients.clients.length ? currentUserClients.clients[0]._id : undefined)) : undefined,
      } as ITicketCreationRequest}
      onSubmit={async (values) => {

        if (role && !role.isClient) {

          if (!values.clientMail && !values.clientContactId && values.type === TicketType.ExternalTicket) {
            showModal({
              text: "Bitte geben Sie eine Mailadresse ein.",
              type: ModalType.Error
            });

            return false;
          }

          if (values.type !== TicketType.ExternalTicket && !values.clientContactId) {
            showModal({
              text: "Bitte wählen Sie einen Mandantenkontakt aus.",
              type: ModalType.Error
            });

            return false;
          }
        }

        const result = await callApi(ticketsCreate(values));

        if (!result || !result.success) return false;

        reloadFilteredTickets(prev => [...(prev || []), result.data]);

        if (afterCreate) await afterCreate();

        showModal({
          text: getTicketCreatedMessage(),
          type: ModalType.Success
        });

        return true;
      }}
      button={
        <Button variant={variant} iconPosition="end" icon="plus-circle-fill" iconSize={variant === "icon" ? 30 : undefined}>{variant === "icon" ? "" : buttonText}</Button>
      }
    >
      {
        (formik) => {
          const switchClientContact = (c: IUser | undefined) => {
            formik.setFieldValue("clientContactId", c?._id);
            formik.setFieldValue("clientMail", c?.mailAddress);
          }

          const isInternalTicket = formik.values.type === TicketType.InternalTicket;
          const isExternalTicket = formik.values.type === TicketType.ExternalTicket;

          const canAssignDataToTicket = (user && ((user.isSuperAdmin && !role) || (role && !role.isClient)));

          return (
            <Flex className="w-100" gap={3}>
              <FieldWithLabel
                noMargin
                label="Betreff"
                bold
                className="w-100"
                name="subject"
                placeholder="Einkommensteuer, Abschluss, Sonderprüfung..."
              />
              {
                isNotClient && (
                  <>
                    <div className="d-flex flex-column justify-content-start gap-2">
                      <Typography color="primary" bold>Typ des Tickets</Typography>
                      <TicketTypeRadioGroup value={formik.values.type} saveValue={t => formik.setFieldValue("type", t)} />
                    </div>
                    <Card
                      header={<Typography color="primary" bold>Zuweisung</Typography>}
                    >
                      <Flex fullWidth gap="4">
                        <Flex row gap="3" fullWidth>
                          {
                            isExternalTicket
                              ? (
                                <FieldWithLabel
                                  name="clientMail"
                                  label="Mailadresse des externen Empfängers"
                                  type="email"
                                  className="w-100"
                                  placeholder="Geben Sie hier die Mail des Ticketempfängers ein..."
                                />
                              )
                              : canAssignDataToTicket && (
                                <UserSelect
                                  displayed={isInternalTicket ? "employees" : "clients"}
                                  onChange={switchClientContact}
                                  label={isInternalTicket ? "Mitarbeiter zuweisen" : "Mandantenkontakt"}
                                  value={formik.values.clientContactId}
                                />
                              )
                          }
                          {
                            !isInternalTicket && !isExternalTicket && (
                              <ClientSelect
                                saveClient={c => formik.setFieldValue("clientId", c)}
                                clientId={formik.values.clientId}
                                clientContactId={formik.values.clientContactId}
                              />
                            )
                          }
                        </Flex>
                        {
                          (!isInternalTicket && canAssignDataToTicket) && (
                            <UserSelect
                              displayed="employees"
                              onChange={(u) => formik.setFieldValue("assignee", u)}
                              label="Optional: Mitarbeiter / Bearbeiter"
                              placeholder="Mitarbeiter auswählen..."
                              value={formik.values.assignee}
                            />
                          )
                        }
                      </Flex>
                    </Card>
                  </>
                )
              }
              <Flex className="w-100">
                <Flex fullWidth justify="between" row>
                  <Typography color="primary" bold>Optional: Erste Nachricht</Typography>
                  {
                    isNotClient && <CheckBox bold labelClass="text-nowrap" name="firstMessageIsFromClient" label="Erste Nachricht ist vom Mandanten" />
                  }
                </Flex>
                {
                  (!renderHtml || !initialMessage) || !formik.values.firstMessage
                    ? (
                      <Editor
                        useFieldStyle
                        className="w-100"
                        onChange={val => formik.setFieldValue("firstMessage", val)}
                        content={formik.values.firstMessage}
                        onBlur={() => setRenderHtml(true)}
                        onFocus={() => setRenderHtml(false)}
                        disabled={formik.isSubmitting}
                      />
                    )
                    : <TicketChatHtmlMessageContent isEditForm={false} isPreview message={formik.values.firstMessage} onClick={() => setRenderHtml(false)} className="create-ticket-form-first-message-field" />
                }
                <AttachmentSelect
                  disabled={!!createFromMailId}
                  text={createFromMailId ? "Anhänge werden aus Mail übernommen" : "Anhang hinzufügen"}
                  icon="paperclip"
                />
              </Flex>
              {
                isNotClient && (
                  <FieldWithLabel
                    noMargin
                    as="textarea"
                    label="Optional: Interne Notiz"
                    bold
                    className="w-100"
                    inputClass="create-ticket-form-first-notes-field"
                    name="firstNote"
                    placeholder="Geben Sie eine Notiz ein"
                  />
                )
              }
            </Flex>
          )
        }
      }
    </ModalForm>
  )
}