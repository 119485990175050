import React from "react";
import { useClientImportLog } from "../../../state/api/clients/useClientImportLog";
import { useLatestClientImportLog } from "../../../state/api/clients/useLatestClientImportLog";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import Typography from "../../text/Typography";
import useClientImportUtil from "../../../hooks/useClientImportUtil";

export default function ClientImportProgress() {

    const [visible, setVisible] = React.useState<boolean>(false);

    const {
        latestClientImportLog: idSource
    } = useLatestClientImportLog();

    const {
        clientImportLog
    } = useClientImportLog(idSource?._id);

    React.useEffect(() => {
        if (!idSource) return;
        if (!clientImportLog) return;
        if (clientImportLog.completed || clientImportLog.completedAt || clientImportLog.aborted) return;
        setVisible(true);
    }, [idSource, clientImportLog]);
    
    const {
        getImportIcon,
        isImportDone,
        isImportRunning
    } = useClientImportUtil();
    
    if (!clientImportLog) return null;
    if (!visible) return null;

    const isDone = isImportDone(clientImportLog);
    const color = isDone ? "success" : "error";
    const text = isDone ? "Schließen" : "Ausblenden";

    const getMessage = () => {
        if (isDone) return "Import abgeschlossen!";
        else if (clientImportLog.failed) return "Es ist ein Fehler aufgetreten!";
        return clientImportLog.message;
    }

    const icon = getImportIcon(clientImportLog);
    const message = getMessage();

    return (
        <Card 
            strongColor
            header={<Typography bold>Aktueller Import</Typography>}
        >
            <Flex row fullWidth justify="between">
                <Flex row>
                    <Icon size={30} icon={icon} loading={!icon} />
                    <Typography>{message}</Typography>
                </Flex>
                <Button text={text} color={color} onClick={() => setVisible(false)} />
            </Flex>
        </Card>

    )
}