import { StatusCodes } from "http-status-codes";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PageLoader from "../components/loader/PageLoader";
import SideNav from "../components/menu/sidebar/SideNav";
import ImposterSessionIndicator from "../components/session/ImposterSessionIndicator";
import { AnyRoute, AppRouteParams, AppRoutes, getLink } from "../config/AppRoutes";
import { useAppSelector } from "../hooks/reduxHooks";
import { generateClassName } from "../hooks/useAttributes";
import { Locale } from "../locale/Locale";
import Addressee from "../pages/addressees/Addressee";
import AddresseeManagement from "../pages/addressees/AddresseeManagement";
import OfficeInboxPage from "../pages/mail/OfficeInboxPage";
import ReAuthOfficeAccountPage from "../pages/azure/ReAuthOfficeAccountPage";
import BillingOverviewPage from "../pages/billing/BillingOverviewPage";
import Client from "../pages/clients/Client";
import ClientManagement from "../pages/clients/ClientContactManagement";
import MandateManagement from "../pages/clients/ClientManagement";
import ClientResponsibilityOverview from "../pages/clients/ClientResponsibilityOverview";
import Dashboard from "../pages/Dashboard";
import DocumentClearance from "../pages/documentClearance/DocumentClearance";
import GiveDocumentClearance from "../pages/documentClearance/GiveDocumentClearance";
import Error from "../pages/Error";
import LogViewer from "../pages/log/LogViewer";
import MailAddressManagement from "../pages/mail/MailAddressManagement";
import MailLog from "../pages/mail/MailLog";
import RoundMail from "../pages/mail/RoundMail";
import RoleManagement from "../pages/permissions/RoleManagement";
import ServiceManagementPage from "../pages/services/ServicesManagementPage";
import Settings from "../pages/Settings";
import MailTemplatesPage from "../pages/templates/MailTemplatesPage";
import AllDomainsManagement from "../pages/tenant/AllDomainsManagement";
import NoTenantFound from "../pages/tenant/NoTenantFound";
import TenantRequestOnboarding from "../pages/tenant/RequestTenantOnboarding";
import TenantDomainManagement from "../pages/tenant/TenantDomainManagement";
import TenantManagement from "../pages/tenant/TenantManagement";
import TenantOnboarding from "../pages/tenant/TenantOnboarding";
import Testing from "../pages/testing/Testing";
import Ticket from "../pages/tickets/Ticket";
import Tickets from "../pages/tickets/Tickets";
import EmployeeManagement from "../pages/user/EmployeeManagement";
import LogIn from "../pages/user/LogIn";
import Profile from "../pages/user/Profile";
import RequestPasswordReset from "../pages/user/RequestPasswordReset";
import ResetPassword from "../pages/user/ResetPassword";
import SelectVerificationMethod from "../pages/user/SelectVerificationMethod";
import TeamsManagement from "../pages/user/TeamsManagement";
import UserManagement from "../pages/user/UserManagement";
import VerifyLogIn from "../pages/user/VerifyLogIn";
import VerifyUser from "../pages/user/VerifyUser";
import { useCurrentUserOfficeAccount } from "../state/api/office/useCurrentUserOfficeAccount";
import { useSession } from "../state/api/session/useSession";
import { useTenant } from "../state/api/tenant/useTenant";
import { useCurrentTenantAuthority } from "../state/api/user/useCurrentTenantAuthority";
import { useCurrentTenantRole } from "../state/api/user/useCurrentTenantRole";
import { useUser } from "../state/api/user/useUser";
import { SessionVerificationMethod } from "../types/ApiTypes";
import { OfficeAccountStatus } from "../types/officeAccount.types";
import "./App.css";
import TicketSocketConnector from "./TicketSocketConnector";
import TicketEscalatedAlert from "../components/ticketing/alerts/TicketEscalatedAlert";
import Calendar from "../pages/Calendar";
import OfficeAccountNeedsReAuthAlert from "../components/mail/account/alerts/OfficeAccountNeedsReAuthAlert";

export default function App() {

  const {
    isExpandedDesktop
  } = useAppSelector(state => state.sidebar);

  const { user, resetToken, loadingUser } = useUser();
  const { role, loadingRole } = useCurrentTenantRole();
  const { tenant, loadingTenant } = useTenant();

  const { authority, loadingAuthority } = useCurrentTenantAuthority();
  const { loading, session } = useSession();

  const { currentUserOfficeAccount, loadingCurrentUserOfficeAccount } = useCurrentUserOfficeAccount();

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!resetToken) return;
    const path = getLink(AppRouteParams.VerificationToken, AppRoutes.SetNewPassword, resetToken);
    navigate(path);
  }, [resetToken, user]);

  if (loading || loadingTenant || loadingUser) return <div className="vh-100"><PageLoader /></div>

  const loggedOutRoutes = (
    <>
      <Route path={AppRoutes.RequestPasswordReset.path} element={<RequestPasswordReset />} />
      <Route path={AppRoutes.VerifyUser.path} element={<VerifyUser />} />
    </>
  )

  const defaultRoutes = (
    <>
      <Route path={AppRoutes.TenantOnboarding.path} element={<TenantOnboarding />} />
      <Route path={AppRoutes.GiveDocumentClearancePage.path} element={<GiveDocumentClearance />} />
      <Route path={AppRoutes.SetNewPassword.path} element={<ResetPassword />} />
      <Route path={AppRoutes.RequestTenantAccess.path} element={<TenantRequestOnboarding />} />
    </>
  )
  const standardRoutes = (
    <>
      {loggedOutRoutes}
      {defaultRoutes}
    </>
  )

  if (!tenant) return (
    <Routes>
      {standardRoutes}
      <Route path={AnyRoute} element={<NoTenantFound />} />
    </Routes>
  )

  if (session && !session.accessGranted) {
    if (!session.verificationMethod || session.verificationMethod === SessionVerificationMethod.None) return (
      <Routes>
        {standardRoutes}
        <Route path={AnyRoute} element={<SelectVerificationMethod />} />
      </Routes>
    )

    return (
      <Routes>
        {standardRoutes}
        <Route path={AnyRoute} element={<VerifyLogIn />} />
      </Routes>
    )
  }

  if (!user || !session) return (
    <Routes>
      {standardRoutes}
      <Route path={AnyRoute} element={<LogIn />} />
    </Routes>
  )

  if (currentUserOfficeAccount?.status === OfficeAccountStatus.NeedsReAuth) return (
    <Routes>
      {standardRoutes}
      <Route path={AnyRoute} element={<ReAuthOfficeAccountPage />} />
    </Routes>
  )

  const clientRoutes = (
    <>
      <Route path={AppRoutes.Tickets.path} element={<Tickets />} />
      <Route path={AppRoutes.CurrentUserProfile.path} element={<Profile />} />
      <Route path={AppRoutes.Settings.path} element={<Settings />} />
      <Route path={AppRoutes.DocumentClearanceOverview.path} element={<DocumentClearance />} />
      <Route path={AppRoutes.Ticket.path} element={<Ticket />} />
      <Route path={AppRoutes.VerifyUser.path} element={<VerifyUser />} />
    </>
  )

  const tenantAdminRoutes = (
    <>
      <Route path={AppRoutes.RoleManagement.path} element={<RoleManagement />} />
      <Route path={AppRoutes.UserManagement.path} element={<UserManagement />} />
      <Route path={AppRoutes.DomainManagement.path} element={<AllDomainsManagement />} />
      <Route path={AppRoutes.MailManagement.path} element={<MailAddressManagement />} />
      <Route path={AppRoutes.MailLogs.path} element={<MailLog />} />
      <Route path={AppRoutes.Billing.path} element={<BillingOverviewPage />} />
      <Route path={AppRoutes.TenantDomainManagement.path} element={<TenantDomainManagement />} />
      <Route path={AppRoutes.MailTemplates.path} element={<MailTemplatesPage />} />
    </>
  )

  const superAdminRoutes = (
    <>
      <Route path={AppRoutes.Testing.path} element={<Testing />} />
      <Route path={AppRoutes.TenantManagement.path} element={<TenantManagement />} />
      <Route path={AppRoutes.ServiceManagement.path} element={<ServiceManagementPage />} />
      <Route path={AppRoutes.Logs.path} element={<LogViewer />} />
    </>
  )

  const employeeRoutes = (
    <>
      <Route path={AppRoutes.Calendar.path} element={<Calendar />} />
      <Route path={AppRoutes.Home.path} element={<Dashboard />} />
      <Route path={AppRoutes.Inbox.path} element={<OfficeInboxPage />} />
      <Route path={AppRoutes.ClientCompanyManagement.path} element={<MandateManagement />} />
      <Route path={AppRoutes.Client.path} element={<Client />} />
      <Route path={AppRoutes.TeamsManagement.path} element={<TeamsManagement />} />
      <Route path={AppRoutes.RoundMail.path} element={<RoundMail />} />
      <Route path={AppRoutes.ClientManagement.path} element={<ClientManagement />} />
      <Route path={AppRoutes.EmployeeManagement.path} element={<EmployeeManagement />} />
      <Route path={AppRoutes.Addressee.path} element={<Addressee />} />
      <Route path={AppRoutes.AddresseeManagement.path} element={<AddresseeManagement />} />
      <Route path={AppRoutes.ClientResponsibilityManagement.path} element={<ClientResponsibilityOverview />} />
    </>
  )

  const contentClass = generateClassName("d-flex flex-row w-100 h-100", {
    value: session.isImposterSession,
    onTrue: "imposter-session-content"
  });

  const isClient = !!role?.isClient;

  return (
    <div className={contentClass}>
      <ImposterSessionIndicator />
      <SideNav />
      <TicketSocketConnector />
      {
        !isClient && (
          <>
            <TicketEscalatedAlert />
            <OfficeAccountNeedsReAuthAlert />
          </>
        )
      }
      <div className={generateClassName("app-content w-100 h-100", { value: isExpandedDesktop, onTrue: "app-content-sidebar-expanded" })} >
        <Routes>
          {defaultRoutes}
          {clientRoutes}
          {(user.isSuperAdmin || !!authority?.isTenantAdmin || (role && !role.isClient)) && employeeRoutes}
          {user.isSuperAdmin && superAdminRoutes}
          {(user.isSuperAdmin || !!authority?.isTenantAdmin) && tenantAdminRoutes}
          <Route path={AnyRoute} element={<Error message={Locale.errors.notFound} status={StatusCodes.NOT_FOUND} />} />
        </Routes>
      </div>
    </div>
  )
}