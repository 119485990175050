import React from "react";
import Page from "../../components/container/Page";
import TicketOverview from "../../components/ticketing/lists/TicketOverview";

export default function Tickets() {

  return (
    <Page >
      <TicketOverview />
    </Page>
  )
}