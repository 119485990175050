
import { ApiRoutes } from "../../../api/ApiRoutes";
import { ICoupon } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useCoupons = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ICoupon>>(ApiRoutes.Subscriptions.CouponsGetAll);

    return {
        coupons: data,
        loadingCoupons: isLoading,
        reloadCoupons: mutate
    }
}