import React from "react";

import { ApiRoutes } from "../../../api/ApiRoutes";
import { IUser } from "../../../types/ApiTypes";
import { useSession } from "../session/useSession";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useUser = () => {

    const [ resetToken, setResetToken ] = React.useState<string>();
    const [ user, setUser ] = React.useState<IUser | null>();
    const { data, isLoading, mutate } = useTiggiQuery(ApiRoutes.User.Active);

    React.useEffect(() => {
        if (isLoading) return;
        if (!data) return;

        if (data.token) {
            setUser(null);
            setResetToken(data.token);
            return;
        }

        setUser(data);
        setResetToken(undefined);

    }, [data, isLoading, mutate]);

    return {
        user: user,
        resetToken: resetToken,
        loadingUser: isLoading,
        reloadUser: mutate
    }
} 