import React from "react";
import Pill from "../../pills/Pill";
import { OfficeAccountType } from "../../../types/officeAccount.types";
import useLabels, { Label } from "../../../hooks/useLabels";
import useOfficeAccountUtil from "../../../hooks/useOfficeAccountUtil";

export default function OfficeAccountTypeBanner({ type, className }: { type: OfficeAccountType, className?: string }) {

  const {
    getColor,
    getIcon,
    getText
  } = useOfficeAccountUtil();

  return (
    <Pill
      text={getText(type)}
      color={getColor(type)}
      icon={getIcon(type)}
      className={className}
    />
  )
}