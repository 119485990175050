import React, { useEffect } from "react";
import useAddresseeUtil from "../../hooks/useAddresseeUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { useAddressees } from "../../state/api/addressees/useAddressees";
import { useRoles } from "../../state/api/roles/useRoles";
import { DatevAddresseeType, IAddressee, IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import ClientContactListItem from "./ClientContactListItem";
import { useUsers } from "../../state/api/user/useUsers";
import { useClientContacts } from "../../state/api/clientContacts/useClientContacts";
import { getId } from "../../util/mongoUtil";

export interface ISortedClientContact {
  user?: IUser,
  addressees: IAddressee[]
}

export default function ClientContactList() {

  const { clientContacts, loadingClientContacts } = useClientContacts();
  const { addressees, loadingAddressees } = useAddressees();

  const {
    usersById
  } = useUsers();

  const getSortedClientContacts = (): ISortedClientContact[] => {
    if (loadingClientContacts || loadingAddressees) return [];

    if (!addressees?.length) {

      if (!clientContacts?.length) return [];

      return clientContacts.map((e: IUser): ISortedClientContact => ({
        addressees: [],
        user: e
      }));

    }

    const noUserItem: ISortedClientContact = { addressees: [] }
    const items: { [key: string]: ISortedClientContact } = {};

    for (var addressee of addressees) {
      if (!addressee) continue;
      if (addressee.type === DatevAddresseeType.LegalPerson) continue;

      const userId = getId(addressee.user);

      if (!userId) {
        noUserItem.addressees.push(addressee);
        continue;
      }

      const u = usersById[userId];

      if (!u) continue;

      if (!items[userId]) items[userId] = {
        addressees: [],
        user: u
      };

      items[userId].addressees.push(addressee);
    }

    const result = Object.values(items);

    result.sort((a, b) => {
      if (!a.user && !b.user) return 0;
      if (!a.user) return -1;
      if (!b.user) return 1;

      return a.user.firstName.localeCompare(b.user.firstName);
    })

    if (result.length > 0) result.push(noUserItem);

    return result;
  }

  const {
    getName
  } = useUserUtil();

  const contacts = getSortedClientContacts();

  return (
    <WithPermissions permissions={["users.clientContacts.read"]}>
      {
        loadingAddressees || loadingClientContacts
          ? <LoadingSpinner centered={false} text="Mandantenkontakte werden geladen..." />
          : (
            <Table
              border
              className="w-100"
              headers={[
                {
                  label: "Name",
                  filterItem: (c, filter) => {
                    if (!c.user) return false;
                    const name = getName(c.user);

                    if (name && name.toLowerCase().includes(filter.toLowerCase())) return true;
                    if (c.user.mailAddress && c.user.mailAddress.toLowerCase().includes(filter.toLowerCase())) return true;

                    return false;
                  }
                }, {
                  label: "Addressaten",
                  filterItem: (c, filter) => {
                    if (!c.addressees || !c.addressees.length) return false;
                    const values = c.addressees.map(a => a.actualName);
                    return values.some(v => v.toLowerCase().includes(filter.toLowerCase()));
                  }
                }, {
                  label: "Mandanten"
                }, {
                  label: "Alias"
                },
                { label: "" }
              ]}
              items={contacts}
              renderItem={s => <ClientContactListItem key={s.user ? s.user._id : "no-user-addressees"} clientContact={s} />}
            />
          )
      }
    </WithPermissions>
  )
}