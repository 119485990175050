import React from "react";
import useUserUtil from "../../hooks/useUserUtil";
import { useClients } from "../../state/api/clients/useClients";
import { IClient } from "../../types/client.schema";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import ClientListItem from "./ClientListItem";

export default function ClientList() {

  const { clients, loadingClients } = useClients();

  const {
    getName,
    getMail
  } = useUserUtil();

  return (
    <WithPermissions permissions={["clients.all.read"]}>
      {
        loadingClients
          ? <LoadingSpinner centered={false} />
          : (
            <>

              {
                clients
                  ? (
                    <Table
                      border
                      className="w-100"
                      headers={[
                        {
                          label: "Nummer",
                          filterItem: (c, filter) => c.number.toString().includes(filter)
                        },
                        {
                          label: "Name",
                          filterItem: (c, filter) => c.name.toLowerCase().includes(filter.toLowerCase())
                        },
                        "Typ",
                        {
                          label: "Ansprechpartner",
                          filterItem: (c, filter) => {
                            if (!c.clientManager) return false;

                            const name = getName(c.clientManager);
                            const mail = getMail(c.clientManager);

                            if (name && name.toLowerCase().includes(filter.toLowerCase())) return true;
                            if (mail && mail.toLowerCase().includes(filter.toLowerCase())) return true;

                            return false;
                          }
                        }
                      ]}
                      itemShouldRender={c => !!c.name}
                      renderItem={(c: IClient) => <ClientListItem key={c._id} client={c} />}
                      items={clients}
                    />
                  )
                  : <span>Keine Mandate</span>
              }
            </>
          )
      }
    </WithPermissions>

  )
}