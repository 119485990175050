import React from "react";
import { useTenants } from "../../state/api/tenant/useTenants";
import { useUser } from "../../state/api/user/useUser";
import { ITenant, TenantStatus } from "../../types/ApiTypes";
import { formatDate } from "../../util/formatter";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import Pill from "../pills/Pill";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import TenantStatusBanner from "./TenantStatusBanner";
import TenantUpdateForm from "./settings/TenantUpdateForm";
import ModalDialog from "../modal/ModalDialog";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { tenantsAbortDelete, tenantsDeleteForce } from "../../api/Api";

export default function TenantList() {

    const {
        user
    } = useUser();

    const { loadingTenants, tenants, reloadTenants } = useTenants();
    const callApi = useApi();

    if (!user || !user.isSuperAdmin) return null;

    if (loadingTenants) return <LoadingSpinner centered={false} />
    if (!tenants || !tenants.length) return <span>Keine Tenants gefunden.</span>
    
    return ( 
        <Table 
            border 
            headers={[{label: "Name"}, {label: "Aktiv seit"}, {label: "Status"}, {label: ""}]}
            items={tenants}
            renderItem={(t: ITenant) => (
                <TableRow key={t._id}>
                    <TableCell className="d-flex flex-column align-items-start gap-1">
                        <Flex gap="1">
                            <strong>{t.name}</strong>
                            <Flex row>
                                {
                                    t.domains && !!t.domains.length
                                    ? t.domains.map(d => typeof d === "string" ? null : <Pill key={d._id} text={d.domain} color="#AFB1FA" />)
                                    : <span>Keine Domains.</span>
                                }
                            </Flex>
                        </Flex>
                    </TableCell>
                    <TableCell content={formatDate(t.createdAt)} />
                    <TableCell>
                        <TenantStatusBanner tenant={t} />
                    </TableCell>
                    <TableCell justify="end">
                        {
                            t.status !== TenantStatus.AboutToBeDeleted
                            ? (
                                <ModalDialog 
                                    button={<Button icon="gear">Einstellungen</Button>}
                                    title={t.name ?? "Bearbeiten"}
                                >
                                    <TenantUpdateForm tenant={t} />
                                </ModalDialog>
                            )
                            : (
                                <Flex fullWidth align="end">
                                    <Button 
                                        onClick={async () => {
                                            await callApi(tenantsDeleteForce({
                                                id: t._id
                                            }));

                                            await reloadTenants();
                                        }}
                                        text="Löschung jetzt erzwingen"
                                        secondStepQuestion="Sind Sie SICHER?"
                                        color="error"
                                        icon="trash"
                                    />
                                    <Button 
                                        onClick={async () => {
                                            await callApi(tenantsAbortDelete({
                                                id: t._id
                                            }));

                                            await reloadTenants();
                                        }}
                                        text="Löschung abbrechen"
                                        color="success"
                                        icon="check"
                                    />
                                </Flex>
                            )
                        }
                    </TableCell>
                </TableRow>
            )}
        />
    )
}