import React from "react";
import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery, { IMutateOpts } from "../../../hooks/useTiggiQuery";
import { ISession } from "../../../types/ApiTypes";
import { Cookies, deleteCookie, setCookie } from "../../../util/cookies";
import { useUser } from "../user/useUser";

export const useSession = () => {
  const [token, setToken] = React.useState<string>();
  const { data, isLoading, mutate } = useTiggiQuery<ISession>(ApiRoutes.Session.Active);

  const { reloadUser } = useUser();

  const setSessionToken = (t: string | null | undefined) => {
    if (!t) {
      deleteCookie(Cookies.Session);
      setToken("");
      return;
    }

    setCookie(Cookies.Session, t);
    setToken(t);
  }

  const reloadSession = async (data?: ISession | undefined, opts?: IMutateOpts) => {

    if (!data) await mutate(undefined, opts);
    else await mutate(prev => data, opts);
    
    await reloadUser(undefined, { revalidate: true });
  }

  return {
    session: data,
    setSessionToken: setSessionToken,
    sessionToken: token,
    loading: isLoading,
    reloadSession: reloadSession
  }
} 