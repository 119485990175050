
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IAlias } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAnyClientAlias = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IAlias>>(ApiRoutes.Clients.Alias.getValue({ param: RouteParam.ClientId, value: id}));

    return {
        clientAlias: data,
        loadingClientAlias: isLoading,
        reloadClientAlias: mutate
    }
}