import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Status } from "../../config/Status";
import Card from "../card/Card";
import PageLoader from "../loader/PageLoader";
import SubscriptionServiceGroup from "./SubscriptionServiceGroup";
import { IService } from "../../types/ApiTypes";
import { useTenant } from "../../state/api/tenant/useTenant";
import { useServices } from "../../state/api/subscriptions/useServices";
import WithPermissions from "../permissions/WithPermissions";

export default function CurrentSubscriptionOverview() {

    const { tenant } = useTenant();
    const { services, loadingServices } = useServices();

    const purchasedServices = (!tenant.activePlans || !tenant.activePlans.length) 
    ? [] 
    : (
        services && !!services.length 
        ? services.filter(s => tenant.activePlans.find(p => p.service._id === s._id))
        : []
    );

    const availableServices = (!tenant.activePlans || !tenant.activePlans.length) 
    ? services : (
        services && !!services.length
        ? services.filter(s => !tenant.activePlans.find(t => t.service._id === s._id))
        : []
    );

    const getSubscriptionGroup = (title: string, services?: Array<IService>) => (
        <div className="d-flex flex-column gap-2">
            <h6 className="fw-bold m-0 p-0">{title}</h6>
            <div className="d-flex flex-column gap-4">
                {
                    services && !!services.length
                    ? services.map(s => <SubscriptionServiceGroup key={s._id} service={s} tenant={tenant} />)
                    : <span>Es stehen keine Abonnements zur Verfügung.</span>
                }
            </div>
        </div>
    )

    return (
        <WithPermissions permissions={["billing.tenant.read"]}>
            {
                loadingServices 
                ? <PageLoader />
                : (
                    services && !!services.length
                    ? <span>Es stehen keine Abonnements zur Verfügung.</span>
                    : (
                        <div className="d-flex flex-column gap-4">
                            {getSubscriptionGroup("Aktive Abonnements", purchasedServices)}
                            {getSubscriptionGroup("Verfügbare Abonnements", availableServices)}
                        </div>
                    )
                )    
            }
        </WithPermissions>
    )
}