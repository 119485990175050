import { MailFolder } from "@microsoft/microsoft-graph-types";
import React from "react";
import { generateClassName } from "../../../../hooks/useAttributes";
import useInboxPermissions from "../../../../hooks/useInboxPermissions";
import { useMailfolders } from "../../../../state/api/mailfolders/useMailfolders";
import { IMailfolder } from "../../../../types/mailfolder.schema";
import { IOfficeAccount } from "../../../../types/officeAccount.types";
import Flex from "../../../container/Flex";
import Icon from "../../../icons/Icon";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import WithPermissions from "../../../permissions/WithPermissions";
import SearchBox from "../../../search/SearchBox";
import Typography from "../../../text/Typography";
import OfficeFolder from "./OfficeFolder";
import "./OfficeInboxFolders.css";

export interface IOfficeInboxFoldersProps {
  folder?: IMailfolder,
  account: IOfficeAccount,
  onChange: (folder?: IMailfolder) => void,
}

const FolderPrecedence: { [key: string]: number } = {
  "Posteingang": 9,
  "Inbox": 9,
  "Gesendete Elemente": 7,
  "Sent Items": 7,
  "Outbox": 7,
  "Drafts": 5,
  "Entwürfe": 5,
  "Gelöschte Elemente": 3,
  "Deleted Items": 3,
}

export default function OfficeInboxFolders({ account, folder, onChange }: IOfficeInboxFoldersProps) {

  const [folderSelectVisible, setFolderSelectVisible] = React.useState<boolean>(false);
  const [availableFolders, setAvailableFolders] = React.useState<Array<IMailfolder>>([]);
  const [filterValue, setFilterValue] = React.useState<string>("");
  const [sortedFolders, setSortedFolders] = React.useState<Array<IMailfolder>>([]);

  const {
    loadingMailfolders,
    mailfolders
  } = useMailfolders(account._id);

  const {
    getAccountPermissions
  } = useInboxPermissions();

  const updateSelectedFolder = (f?: IMailfolder) => {
    setFolderSelectVisible(false);
    onChange(f);
  }

  React.useEffect(() => {
    if (loadingMailfolders) return;
    if (!mailfolders?.length) return;

    const result = [...mailfolders];

    result.sort((a: MailFolder, b: MailFolder) => {
      const aPrecedence = FolderPrecedence[a.displayName || ""] || 0;
      const bPrecedence = FolderPrecedence[b.displayName || ""] || 0;

      if (aPrecedence > bPrecedence) return -1;

      return 1;
    });

    setSortedFolders(result);

    if (!!result.length) onChange(result[0]);

  }, [mailfolders, loadingMailfolders]);

  React.useEffect(() => {
    if (!sortedFolders?.length) return;

    if (!filterValue) {
      setAvailableFolders(sortedFolders);
      return;
    }

    const result = sortedFolders.filter(f => f.displayName?.toLowerCase().includes(filterValue.toLowerCase()));
    setAvailableFolders(result);

  }, [filterValue, sortedFolders]);

  const contentClass = generateClassName("office-inbox-folders-content w-100 h-100", {
    value: folderSelectVisible,
    base: "office-inbox-folders-content-",
    onTrue: "visible",
    standard: "hidden"
  });

  const permissions = getAccountPermissions("read", account);

  return (
    <WithPermissions permissions={permissions}>
      <Flex className="h-100 position-relative">
        <Flex row>
          <Icon className="office-inbox-folders-expander-icon" size={24} icon={folderSelectVisible ? "x" : "filter"} onClick={() => setFolderSelectVisible(!folderSelectVisible)} />
        </Flex>
        <Flex className={contentClass}>
          <Flex row>
            <Icon className="office-inbox-folders-expander-icon" size={24} icon={"x"} onClick={() => setFolderSelectVisible(!folderSelectVisible)} />
            <Typography bold>Ordner</Typography>
          </Flex>
          <SearchBox
            noShadow
            initialValue={filterValue}
            onChange={setFilterValue}
            placeholder="Ordner durchsuchen..."
            className="w-100"
          />
          <Flex className="h-100 w-100" style={{ overflowY: "auto" }}>
            <Flex className="w-100" gap={0} style={{ backgroundColor: "var(--grey)" }}>
              {
                loadingMailfolders
                  ? <LoadingSpinner text="Lädt Ordner..." className="p-3" />
                  : (
                    !!availableFolders?.length
                      ? availableFolders.map(f => (
                        <OfficeFolder
                          key={f._id}
                          hierarchyLevel={0}
                          account={account}
                          folder={f}
                          onChange={updateSelectedFolder}
                          activeFolder={folder}
                        />))
                      : <span className="p-3">Keine Ordner</span>
                  )
              }
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </WithPermissions>
  )
}