import React from "react";
import { useTicketNote } from "../../../state/api/tickets/notes/useTicketNote";
import Typography from "../../text/Typography";
import TicketNote from "./TicketNote";

export interface ITicketNotePreviewProps {
  noteId: string,
  ticketId: string
}

export default function TicketNotePreview(props: ITicketNotePreviewProps) {

  const {
    noteId,
    ticketId
  } = props;

  const {
    ticketNote,
    loadingTicketNote
  } = useTicketNote(ticketId, noteId);

  if (loadingTicketNote) return <Typography>Loading...</Typography>
  if (!ticketNote) return null;

  return <TicketNote note={ticketNote} ticket={ticketId} />
}