
import { ApiRoutes } from "../../../api/ApiRoutes";
import { Sorted } from "../../../types/collection.types";
import { IUsedMailTemplate } from "../../../types/usedMailTemplates.schema";
import { MailTemplate } from "../../../types/globalMailTemplates.schema";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useUsedMailTemplates = () => {
  const { data, isLoading, mutate } = useTiggiQuery<Sorted<IUsedMailTemplate, MailTemplate>>(ApiRoutes.MailTemplates.Used.All);

  console.log(data);

  return {
    usedMailTemplatesById: data?.byId ?? {},
    usedMailTemplatesByTemplate: data?.templateType ?? {},
    usedMailTemplates: data?.items ?? [],
    loadingUsedMailTemplates: isLoading,
    reloadUsedMailTemplates: mutate
  }
}