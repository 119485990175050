import React from "react";
import useTaskUtil from "../../hooks/useTaskUtil";
import { ITask } from "../../types/task.schema";
import { getDateInXFrom } from "../../util/util";
import { IAgendaProps } from "./CalendarOverview";
import DailyAgenda from "./DailyAgenda";

export interface IWeeklyAgendaProps extends IAgendaProps {
  appointments?: { [key: string]: ITask[] },
  recurringAppointments?: { [key: string]: ITask[] }
}

export default function WeeklyAgenda(props: IWeeklyAgendaProps) {

  const {
    start,
    appointments,
    recurringAppointments,
    displayedDays,
    selectedDate
  } = props;

  const {
    getDateId
  } = useTaskUtil();

  return (
    <>
      {
        Array.from({ length: 5 }).map((_, i) => {
          const date = getDateInXFrom({ days: i}, start ?? selectedDate);
          const dateId = getDateId(date);

          const allAppointments = [
            ...(appointments?.[dateId] ?? []),
            ...(recurringAppointments?.[dateId] ?? [])
          ];

          return (
            <DailyAgenda
              key={`daily-agenda-${i}`}
              selectedDate={selectedDate}
              appointments={allAppointments}
              day={date}
              displayedDays={displayedDays}
            />
          )
        })
      }
    </>
  );
}