import { Form, Formik } from "formik";
import React from "react";
import { settingsUpdate } from "../../api/Api";
import { ISettingsUpdateRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import { ISettings } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import FieldWithLabel from "../forms/FormikField";
import LoadingSpinner from "../loader/LoadingSpinner";
import { useSettings } from "../../state/api/settings/useSettings";
import Flex from "../container/Flex";
import { useUser } from "../../state/api/user/useUser";

export default function GlobalSettings() {

    const {
        user
    } = useUser();

    const [cleanSettings, setCleanSettings] = React.useState<ISettings>({ links: { faq: "" } } as ISettings);

    const callApi = useApi();

    const { settings, loadingSettings, reloadSettings } = useSettings();

    React.useEffect(() => {
        const cleanedSettings: ISettings = {
            _id: "",
            links: {
                faq: ""
            }
        };

        if (!settings) {
            setCleanSettings(cleanedSettings);
            return;
        }

        setCleanSettings({...cleanedSettings, ...settings});

    }, [settings]);
    
    if (!user || !user.isSuperAdmin) return null;
    if (loadingSettings) return <LoadingSpinner />
    
    return (
        <Formik
            enableReinitialize
            initialValues={cleanSettings as ISettingsUpdateRequest}
            onSubmit={async (values) => {
                const res = await callApi(settingsUpdate(values), true);

                if (!res) return;

                await reloadSettings();
            }}
        >

            {
                (formik) => (
                    <Form className="w-100">
                        <Flex fullWidth>
                            <p className="fw-bold">Links</p>
                            <FieldWithLabel className="w-100" type="url" name="links.faq" label="FAQ-Link" />
                            <Button disabled={!formik.dirty} icon="save" loading={formik.isSubmitting} color="success" type="submit" text="Speichern" loadingText="Speichert..."/>
                        </Flex>
                    </Form>
                )
            }
        </Formik>
    )
}