import { EmailAddress, NullableOption, Recipient } from "@microsoft/microsoft-graph-types";

export const formatTime = (d: any): string => {
  if (!d) return "";

  try {
    return new Date(d).toLocaleTimeString("DE-de", {
      timeStyle: "short"
    });
  }
  catch { return "" }
}

const appendZero = (str: any, length: number = 2): string => {
  if (!str) return "0".repeat(length);
  if (typeof str !== "string") str = str.toString();
  const diff = length - str.length;
  if (diff <= 0) return str;
  return "0".repeat(diff) + str;
}

export const getMonthName = (month: number, locale: string = "de") => {
  try {
    const date = new Date(`1970-${month + 1}-01`);
    const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
    return formatter.format(date);
  }
  catch (err) {
    console.log(err);
    return "";
  }
}

export const formatFileSize = (bytes: number, metricUnits: boolean = false, decimals: number = 1) => {
  const thresh = metricUnits ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) return bytes + ' B';

  const units = metricUnits
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** decimals;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(decimals) + ' ' + units[u];
}

export const formatDate = (d: any, withTime: boolean = false, withShortTime: boolean = false, hideDateIfToday: boolean = false, timeOnly: boolean = false): string => {
  if (!d) return "-";

  try {
    const date = new Date(d);

    if (isNaN(date.getDate())) return "-";
    if (!date) return "-";

    let time = appendZero(date.getHours()) + ":" + appendZero(date.getMinutes());
    if (!withShortTime) time += `/${appendZero(date.getSeconds())}`;

    if (timeOnly) return time;

    let result = `${appendZero(date.getDate())}.${appendZero(date.getMonth() + 1)}.${appendZero(date.getFullYear(), 4)}`;

    if (!withTime) return result;
    if (date.getDate() === new Date().getDate() && hideDateIfToday) return time;

    result += ` ${time}`;

    return result;
  }
  catch {
    return `${d}`;
  }
}

export const formatDateGmailStyle = (d: any, long: boolean = false): string => {
  if (!d) return "-";

  try {
    const date = new Date(d);

    if (isNaN(date.getDate())) return "-";
    if (!date) return "-";

    const time = appendZero(date.getHours()) + ":" + appendZero(date.getMinutes());

    if (date.getDate() === new Date().getDate()) return time;

    const dateString = `${appendZero(date.getDate())}${long ? "." : ""} ${date.toLocaleDateString("de-DE", { month: long ? "long" : "short" })}`;

    if (date.getFullYear() === new Date().getFullYear()) return dateString;

    return date.toLocaleDateString("de-DE", { month: "2-digit", year: "2-digit", day: "2-digit" });
  }
  catch {
    return "-";
  }
}

export const formatDateTiggiStyle = (d: any, hideTime: boolean = false): string => {
  if (!d) return "-";

  try {
    const date = new Date(d);

    if (isNaN(date.getDate())) return "-";
    if (!date) return "-";

    const getTwoLetterDayFromDate = () => {
      const days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
      return days[date.getDay()];
    }

    let dateString = `${getTwoLetterDayFromDate()}, ${appendZero(date.getDate())}.${appendZero(date.getMonth() + 1)}.`;

    if (date.getFullYear() !== new Date().getFullYear()) dateString += ` ${date.getFullYear()}`;

    if (hideTime) return dateString;

    const time = appendZero(date.getHours()) + ":" + appendZero(date.getMinutes());

    return `${dateString} - ${time} Uhr `;
  }
  catch (err) {
    return "-";
  }
}

export const formatMailAddress = (name?: any, mail?: any) => {
  if (name) return `${name} <${mail}>`;
  return mail;
}

export const getMailAddressFromOfficeMail = (recipient?: NullableOption<Recipient>) => {
  if (!recipient || !recipient.emailAddress || !recipient.emailAddress.address) return "";
  return recipient.emailAddress.address;
}

export const formatOfficeMailAddress = (mail?: NullableOption<EmailAddress>) => mail ? formatMailAddress(mail.name, mail.address) : "";

export const formatOfficeMailAddressByRecipient = (recipient?: NullableOption<Recipient>) => recipient ? formatOfficeMailAddress(recipient.emailAddress) : "";

export const formatNumber = (n: any, withFraction: boolean = false): string => {
  try {
    let input = n;
    if (typeof n !== "number") input = parseFloat(n);
    return new Intl.NumberFormat("de-DE", { maximumFractionDigits: withFraction ? 2 : 0, minimumFractionDigits: withFraction ? 2 : 0 }).format(input);
  }
  catch {

  }

  return n;
}

export const formatCurrency = (n: any, currency: string = "EUR"): string => {
  try {
    if (typeof n !== "number") return n;
    return `${new Intl.NumberFormat("de-DE", { currency: currency, maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(n)} EUR`;
  }
  catch { }

  return n;
}

export const formatDuration = (duration: number): string => {
  try {
    const numToString = (n: any): string => n.toString().padStart(2, '0');

    const hours = Math.floor(duration / 60);
    const minutes = Math.floor(duration % 60);

    if (hours > 24) {
      const days = Math.floor(hours / 24);
      return `${numToString(days)}d ${numToString(hours % 24)}h ${numToString(minutes)}min`;
    }

    return `${numToString(hours)}h ${numToString(minutes)}min`;
  }
  catch { }

  return duration.toString();
}