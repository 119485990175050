import React, { EventHandler, useEffect } from "react";
import { getColorFromType, getIconFromType, ModalType } from "../../config/ModalTypes";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { generateClassName, generateStyle } from "../../hooks/useAttributes";
import { INotification, removeNotification } from "../../state/slices/notificationSlice";
import Icon from "../icons/Icon";
import "./ModalNotification.css";
import Card from "../card/Card";
import Typography from "../text/Typography";
import Flex from "../container/Flex";
import { hexWithOpacity } from "../../util/util";
import Button from "../buttons/Button";

export interface IModalNotificationProps {
  notification: INotification
}

export default function ModalNotification(props: IModalNotificationProps) {

  const [visible, setVisible] = React.useState<boolean>(true);
  const [isDisappearing, setIsDisappearing] = React.useState<boolean>(false);
  const [hover, setHover] = React.useState<boolean>(false);

  const showTimer = React.useRef<any>(null);

  const {
    notification
  } = props;

  const {
    id,
    text,
    type,
    action,
    title
  } = notification;

  const dispatch = useAppDispatch();

  const handleHover = (entry: boolean) => {
    setHover(entry);
    refreshHideTimeout(!entry);
  }

  const refreshHideTimeout = (setNew: boolean = true, updateDisappearing: boolean = true) => {
    clearTimeout(showTimer.current);
    if (updateDisappearing) setIsDisappearing(setNew);
    if (setNew) showTimer.current = setTimeout(() => {
      dispatch(removeNotification(id!));
      setVisible(false);
    }, 3800);
  }

  useEffect(() => {
    refreshHideTimeout(true, false);
    return () => clearTimeout(showTimer.current);
  }, []);

  
  const color = getColorFromType(type);
  
  const clickHandler = async () => {
    refreshHideTimeout(false, true);
    try {
      if (action && action.onClick) await action.onClick();
    }
    finally {
      refreshHideTimeout(true, true);
    }
  }
  
  if (!notification) return null;
  if (!visible) return null;

  return (
    <Card
      wrapperClass={generateClassName("modal-notification", {
        value: isDisappearing,
        onTrue: "modal-notification-disappearing"
      }, {
        value: hover,
        onTrue: "modal-notification-sticky"
      })}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      color={color}
      header={(
        <Flex row fullWidth justify="between">
          <Typography bold basedOnThisBackground={color}>{title}</Typography>
          <Icon icon="x" onClick={() => dispatch(removeNotification(id!))} />
        </Flex>
      )}
    >
      <Flex fullWidth>
        <Typography wrap>{text}</Typography>
        {
          action && (
            <Flex fullWidth align="end">
              <Button {...action} onClick={clickHandler} color={color} />
            </Flex>
          )
        }
      </Flex>
    </Card>
  )
}