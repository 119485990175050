import React from "react";
import Button from "../../components/buttons/Button";
import Flex from "../../components/container/Flex";
import Page from "../../components/container/Page";
import Typography from "../../components/text/Typography";
import { AppRoutes } from "../../config/AppRoutes";

export default function NoTenantFound() {
  return (
    <Page fullscreen centered className="p-5" colored>
      <Flex gap="5">
        <Flex gap="2">
          <Flex row reverse gap="3">
            <Button
              to="https://tiggi.io"
              color="secondary"
              size="large"
              externalLink
              icon="info"
              variant="subtle"
              text="Über Tiggi"
            />
            <Button
              to={AppRoutes.RequestTenantAccess.path}
              size="large"
              color="success"
              icon="person-plus-fill"
              text="Jetzt Registrieren"
            />
          </Flex>
          <Typography bold size="100" noLinePadding upper color="bright">Willkommen bei Tiggi!</Typography>
        </Flex>
        <Flex gap="0">
          <Typography size="16" color="muted">Sie befinden sich auf einer Domain, die auf unseren Service verweist.</Typography>
          <Typography size="16" color="secondary">
            Wenn Sie diese Verknüpfung selbst angelegt haben und trotzdem dieses Fenster sehen, nehmen Sie bitte Kontakt mit uns auf.<br />
            Nennen Sie uns dabei den Namen Ihres Tenants sowie Ihren Namen, damit wir unseren Service schnellstmöglich für Sie freischalten können.
          </Typography>
        </Flex>
      </Flex>
    </Page>
  )
}