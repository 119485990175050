import React from "react";
import { cloudGatewayRunImport } from "../../api/Api";
import { useCloudGateway } from "../../state/api/cloudGateway/useCloudGateway";
import RunDatevImportButton from "./RunDatevImportButton";

export default function NewCloudGatewayImportButton() {
       
    const { 
        cloudGatewaySettings, 
        loadingCloudGatewaySettings 
    } = useCloudGateway();

    return (
        <RunDatevImportButton
            loading={loadingCloudGatewaySettings}
            disabled={!cloudGatewaySettings}
            disabledText="Keine CloudGateway-Zugangsdaten vorhanden"
            id={cloudGatewaySettings?._id}
            apiCall={cloudGatewayRunImport}
            title="Neuer Abruf vom CloudGateway"
            color="success"
            icon="play"
            text="CloudGateway-Abruf starten"
        />
    );
}