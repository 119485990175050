import React from "react";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import { formatDate, formatDateTiggiStyle } from "../../util/formatter";
import Card from "../card/Card";
import Editor from "../richText/Editor";
import RichTextViewer from "../richText/RichTextViewer";
import CompleteTaskButton from "./UpdateTaskStatusButton";
import { ITask } from "../../types/task.schema";
import UpdateTaskButton from "./UpdateTaskButton";

export interface ITaskItemProps {
  task: ITask
}

export default function TaskItem({ task }: ITaskItemProps) {
  return (
    <Card
      color="bright"
      header={
        <Flex row justify="between">
          <Typography bold color="primary">{task.subject}</Typography>
          <Typography>{formatDateTiggiStyle(task.start)}{ task.end && ` - ${formatDateTiggiStyle(task.end)}`}</Typography>
        </Flex>
      }
    >
      <Flex fullWidth>
        <RichTextViewer content={task.description} canShowMore />
        <Flex row justify="between" fullWidth>
          <CompleteTaskButton task={task} />
          <UpdateTaskButton task={task} />
        </Flex>
      </Flex>
    </Card>
  )
}