
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IUser, IUserAbsence } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAnyUserAbsence = (user: IUser | undefined | null) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IUserAbsence>>(ApiRoutes.User.Absence.ForUser.getValue({
        param: RouteParam.UserId,
        value: user ? user._id : ""
    }));

    return {
        userAbsence: data,
        loadingUserAbsence: isLoading,
        reloadUserAbsence: mutate
    }
}
