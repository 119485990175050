import Page from "../components/container/Page";
import React from "react";
import { StatusCodes } from "http-status-codes";
import { useNavigate } from "react-router-dom";
import Icon from "../components/icons/Icon";
import { generateStyle } from "../hooks/useAttributes";
import { Locale } from "../locale/Locale";

interface IErrorProps {
  message?: string,
  status?: number,
  hideBackButton?: boolean
}

export default function Error({ message = Locale.errors.generic, status = StatusCodes.NOT_FOUND, hideBackButton = false }: IErrorProps) {

  const navigate = useNavigate();

  const style = generateStyle(
    {
      name: "top",
      value: "20",
      unit: "px"
    },
    {
      name: "left",
      value: "20",
      unit: "px"
    }
  );

  return (
    <Page fullscreen centered>
      {
        !hideBackButton && (
          <div className="position-absolute" style={style}>
            <Icon icon="arrow-left-short" onClick={() => navigate(-1)} label="Zurück" />
          </div>
        )
      }
      <h1 className="fw-bold">{status}</h1>
      <p>{message}</p>
    </Page>
  )
}