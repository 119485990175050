import { SelectItem } from "../components/comboBox/Select";
import { ITask, ITaskAssignment, ITaskReminder, MinTaskInterval, TaskAttendance } from "../types/task.schema";
import { getDateInXFrom } from "../util/util";
import useUserUtil from "./useUserUtil";

export interface ITaskDuration {
  minuteStart: number,
  hourStart: number,
  minuteLength: number,
}

export interface ITaskSize {
  start: number,
  width: number,
  step: number
}

export enum AllowedReminders {
  Now = 0,
  FifteenMinutes = 15,
  ThirtyMinutes = 30,
  FortyFiveMinutes = 45,
  OneHour = 60,
  TwoHours = 120,
  ThreeHours = 180,
  FourHours = 240,
  FiveHours = 300,
  SixHours = 360,
  SevenHours = 420,
  EightHours = 480,
  NineHours = 540,
  TenHours = 600,
  ElevenHours = 660,
  HalfDay = 720,
  OneDay = 1440,
  TwoDays = 2880,
  ThreeDays = 4320,
  FourDays = 5760,
  FiveDays = 7200,
  SixDays = 8640,
  OneWeek = 10080,
  TwoWeeks = 20160
}

export interface IMappedTasksForDay {
  durations: Map<string, ITaskDuration>;
  getTaskSize: (t: ITask) => ITaskSize
}

export default function useTaskUtil() {

  const {
    getName
  } = useUserUtil();

  const getAttendantName = (a: ITaskAssignment) => {
    if (a.user) return getName(a.user);
    if (a.mailAddress) return a.mailAddress;
    return "Unbekannter Teilnehmer";
  }

  const getAttendanceStatusLabel = (s?: TaskAttendance) => {
    if (!s) return "Offen";

    switch (s) {
      case TaskAttendance.Open: return "Offen";
      case TaskAttendance.WillAttend: return "Zugesagt";
      case TaskAttendance.WillNotAttend: return "Abgesagt";
      case TaskAttendance.Maybe: return "Vielleicht";
    }
  }

  const getAttendanceStatusIcon = (s?: TaskAttendance) => {
    if (!s) return "circle";

    switch (s) {
      case TaskAttendance.Open: return "dash";
      case TaskAttendance.WillAttend: return "check";
      case TaskAttendance.WillNotAttend: return "x";
      case TaskAttendance.Maybe: return "question";
    }
  }

  const getAttendanceStatusColor = (s?: TaskAttendance) => {
    if (!s) return "#F3F3F3";

    switch (s) {
      case TaskAttendance.Open: return "#F3F3F3";
      case TaskAttendance.WillAttend: return "#A3F3A3CC";
      case TaskAttendance.WillNotAttend: return "#F3C3A3CC";
      case TaskAttendance.Maybe: return "#D3C3F988";
    }
  }

  const getDateId = (d: Date) => {
    return d.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "CET"
    });
  }

  const getReminderLabel = (minutesBefore: AllowedReminders) => {
    switch (minutesBefore) {
      case AllowedReminders.Now: return "Jetzt";
      case AllowedReminders.FifteenMinutes: return "15 Minuten";
      case AllowedReminders.ThirtyMinutes: return "30 Minuten";
      case AllowedReminders.FortyFiveMinutes: return "45 Minuten";
      case AllowedReminders.OneHour: return "1 Stunde";
      case AllowedReminders.TwoHours: return "2 Stunden";
      case AllowedReminders.ThreeHours: return "3 Stunden";
      case AllowedReminders.FourHours: return "4 Stunden";
      case AllowedReminders.FiveHours: return "5 Stunden";
      case AllowedReminders.SixHours: return "6 Stunden";
      case AllowedReminders.SevenHours: return "7 Stunden";
      case AllowedReminders.EightHours: return "8 Stunden";
      case AllowedReminders.NineHours: return "9 Stunden";
      case AllowedReminders.TenHours: return "10 Stunden";
      case AllowedReminders.ElevenHours: return "11 Stunden";
      case AllowedReminders.HalfDay: return "1/2 Tag";
      case AllowedReminders.OneDay: return "1 Tag";
      case AllowedReminders.TwoDays: return "2 Tage";
      case AllowedReminders.ThreeDays: return "3 Tage";
      case AllowedReminders.FourDays: return "4 Tage";
      case AllowedReminders.FiveDays: return "5 Tage";
      case AllowedReminders.SixDays: return "6 Tage";
      case AllowedReminders.OneWeek: return "1 Woche";
      case AllowedReminders.TwoWeeks: return "2 Wochen";
    }
  }

  const getAvailableReminders = (start?: Date, existing?: ITaskReminder[]): SelectItem<number>[] => {
    const r: SelectItem<number>[] = [];

    const now = new Date();

    const addReminder = (m: number) => {
      if (existing?.find(e => e.minutesBefore === m)) return;

      if (start) {
        const reminderDate = getDateInXFrom({ minutes: -m }, start);
        if (reminderDate < now) return;
      }

      const label = getReminderLabel(m);

      if (!label) return;

      r.push({ label, data: m });
    }

    for (const v of Object.values(AllowedReminders)) addReminder(v as AllowedReminders);

    return r;
  }

  const mapTasksForDay = (day: Date, tasks?: ITask[]): IMappedTasksForDay => {

    const doAtTaskInterval = (d: ITaskDuration, f: (index: string) => void) => {
      const start = new Date(1970, 0, 0, d.hourStart, d.minuteStart);

      for (let steps = 0; steps <= d.minuteLength; steps += MinTaskInterval) {
        const id = `${start.getHours()}:${start.getMinutes()}`;
        f(id);
        start.setMinutes(start.getMinutes() + MinTaskInterval);
      }
    }

    const getTaskDuration = (t: ITask) => {

      const start = new Date(t.start);

      if (!t.end) return {
        hourStart: start.getHours(),
        minuteStart: start.getMinutes(),
        minuteLength: 5
      }

      const end = new Date(t.end);

      if (end.toDateString() !== start.toDateString()) {
        if (start.toDateString() === day.toDateString()) return {
          hourStart: start.getHours(),
          minuteStart: start.getMinutes(),
          minuteLength: 60 * 24 - (start.getHours() * 60 + start.getMinutes())
        }

        if (end.toDateString() === day.toDateString()) return {
          hourStart: 0,
          minuteStart: 0,
          minuteLength: end.getHours() * 60 + end.getMinutes()
        }

        return {
          hourStart: 0,
          minuteStart: 0,
          minuteLength: 60 * 24
        }
      }

      return {
        hourStart: start.getHours(),
        minuteStart: start.getMinutes(),
        minuteLength: (end.getHours() * 60 + end.getMinutes()) - (start.getHours() * 60 + start.getMinutes())
      }
    }

    const tasksForTime = new Map<string, Set<string>>();
    const directTaskNeighbors = new Map<string, Set<string>>();
    const durations = new Map<string, ITaskDuration>();

    tasks?.forEach(t => {
      const duration = getTaskDuration(t);

      durations.set(t._id, duration);

      doAtTaskInterval(duration, i => {
        const current = tasksForTime.get(i) ?? new Set<string>();
        if (current.has(t._id)) return;
        current.add(t._id);
        tasksForTime.set(i, current);
      });
    });

    for (const tasks of tasksForTime.values()) {
      for (const t of tasks) {
        const c = directTaskNeighbors.get(t) ?? new Set<string>();
        for (const n of tasks) {
          if (c.has(n)) continue;
          c.add(n);
        }
        directTaskNeighbors.set(t, c);
      }
    }

    const sortedTaskNeighbors = new Map<string, Set<string>>();

    for (const [id, neighbors] of directTaskNeighbors) {

      const sorted = Array.from(neighbors).sort((a, b) => {
        const ad = durations.get(a);
        const bd = durations.get(b);

        if (!ad || !bd) return 0;

        return ad.minuteLength - bd.minuteLength;
      });

      sortedTaskNeighbors.set(id, new Set<string>(sorted));
    }

    const taskOrder = new Map<string, number>();

    for (const [id, neighbors] of sortedTaskNeighbors) {
      taskOrder.set(id, neighbors.size - 1);
    }

    const accessOrder = Array.from(taskOrder.entries()).sort((a, b) => b[1] - a[1]).map(([id]) => id);
    const taskIndices = new Map<string, number>();

    for (const id of accessOrder) {

      const taskWithNeighbors = sortedTaskNeighbors.get(id);

      if (!taskWithNeighbors) continue;

      const setTaskIndex = (t: string, i: number) => {
        if (taskIndices.has(t)) return;
        taskIndices.set(t, i);
        i++;
      }

      let x = 0;

      for (const n of taskWithNeighbors) {
        setTaskIndex(n, x++);
      }
    }

    const neighborIndices = new Map<string, Map<string, number>>();

    for (const [id, neighbors] of sortedTaskNeighbors) {

      const indices = new Map<string, number>();

      for (const n of neighbors) {
        indices.set(n, taskIndices.get(n) ?? 0);
      }

      neighborIndices.set(id, indices);
    }

    const taskSizes = new Map<string, ITaskSize>();

    for (const [id, neighbors] of neighborIndices) {

      let step = 1;
      let start = taskIndices.get(id) ?? 0;
      let min = start;
      let max = 0;

      for (const [n, i] of neighbors) {
        const neighborSize = sortedTaskNeighbors.get(n)?.size ?? 1;
        if (neighborSize > step) step = neighborSize;
        if (i < min) min = i;
        if (i > max) max = i;
      }

      let next = max;

      for (const [n, i] of neighbors) {
        if (n === id) continue;
        if (i <= start) continue;
        if (i < next) next = i;
      }

      if (min === start) start = 0;

      taskSizes.set(id, {
        start,
        step,
        width: next - start
      });
    }

    return {
      getTaskSize: (t: ITask) => {
        const map = taskSizes.get(t._id);

        if (map) return map;

        return {
          start: 0,
          step: 1,
          width: 1
        }
      },
      durations
    }
  }

  return {
    getAvailableReminders,
    getDateId,
    getAttendantName,
    mapTasksForDay,
    getReminderLabel,
    getAttendanceStatusLabel,
    getAttendanceStatusIcon,
    getAttendanceStatusColor
  }
}