
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { ITicket } from "../../../types/ApiTypes";
import { Sorted } from "../../../types/collection.types";
import { ITicketActivity } from "../../../types/ticketActivity.schema";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTicketActivity = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<ITicketActivity, string>>(ApiRoutes.Tickets.TicketActivity.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketActivity: data?.items ?? [],
        ticketActivityById: data?.byId ?? {} as { [key: string]: ITicketActivity },
        loadingTicketActivity: isLoading,
        reloadTicketActivity: mutate
    }
}