import React from "react";
import { useCurrentUserOfficeAccount } from "../../../../state/api/office/useCurrentUserOfficeAccount";
import useModalNotifications from "../../../../hooks/useModalNotifications";
import { OfficeAccountStatus } from "../../../../types/officeAccount.types";
import { ModalType } from "../../../../config/ModalTypes";
import useApi from "../../../../hooks/useApi";
import { AppRoutes } from "../../../../config/AppRoutes";

export default function OfficeAccountNeedsReAuthAlert() {

  const {
    currentUserOfficeAccount
  } = useCurrentUserOfficeAccount();

  const showNotification = useModalNotifications();

  React.useEffect(() => {
    if (!currentUserOfficeAccount) return;

    switch (currentUserOfficeAccount.status) {
      case OfficeAccountStatus.NeedsReAuth:
      case OfficeAccountStatus.Inactive:
      case OfficeAccountStatus.TokenExpired:

        showNotification({
          id: currentUserOfficeAccount._id,
          text: "Bitte melden Sie sich erneut bei Ihrem Microsoft-Konto an. Die Verknüpfung ist ausgelaufen oder ungültig.",
          type: ModalType.Error,
          title: "Aktion erforderlich",
          action: {
            text: "Zum Posteingang",
            icon: "arrow-right",
            to: AppRoutes.Inbox.path
          }
        })

        break;

      default: return;
    }
  }, [currentUserOfficeAccount]);

  return <></>
}