import React from "react";
import { formatDate } from "../../util/formatter";
import { DailyAgendaDayHeight } from "./DailyAgendaSkeleton";

export default function NowLine() {

  const minuteHeight = DailyAgendaDayHeight / 60;

  const [date, setDate] = React.useState<Date>(new Date());

  const moveMinuteInterval = React.useRef<number | NodeJS.Timeout | undefined>(0);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {

    const offsetToNextMinute = 60 - date.getSeconds();

    const i = setTimeout(() => {
      moveMinuteInterval.current = setInterval(() => setDate(p => new Date()), 10000);
    }, offsetToNextMinute * 1000);

    return () => {
      clearTimeout(i);
      clearInterval(moveMinuteInterval.current);
    }
  }, []);

  React.useEffect(() => {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const hoursTop = hours * DailyAgendaDayHeight;
  const minutesTop = minutes * minuteHeight;
  const top = hoursTop + minutesTop;

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 10,
        backgroundColor: "var(--error)",
        transform: `translateY(${top}px)`,
        transition: "all 0.3s ease",
        top: 0,
        height: 1,
        left: 0,
        right: 0
      }}
      ref={ref}
    >
      <div
        className="position-absolute p-2 d-flex justify-content-center align-items-center"
        style={{
          borderRadius: "var(--borderRadius)",
          transform: "translateY(-50%)",
          backgroundColor: "var(--error)",
          zIndex: 100,
          width: "fit-content",
          color: "white",
          height: 20,
          fontWeight: "bold",
          fontSize: "0.8rem"
        }}
      >
        {formatDate(date, true, true, false, true)}
      </div>
    </div>
  )
}