import React from "react";
import Banner from "../../pills/Banner";
import { useTicketAssignments } from "../../../state/api/tickets/assignments/useTicketAssignments";
import { ITicket } from "../../../types/ApiTypes";
import LoadingSpinner from "../../loader/LoadingSpinner";
import useUserUtil from "../../../hooks/useUserUtil";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import Pill from "../../pills/Pill";
import Typography from "../../text/Typography";
import { setRightSidebarExpanded } from "../../../state/slices/sidebarSlice";
import useTicketSidebar, { TicketChatSidebarTab } from "../../../hooks/useTicketSidebar";
import { hexWithOpacity } from "../../../util/util";

export interface ITicketMentionsBannerProps {
    ticket: ITicket
}

export default function TicketMentionsBanner(props: ITicketMentionsBannerProps) {

    const {
        ticket
    } = props;

    const dispatch = useAppDispatch();

    const {
        getName
    } = useUserUtil();

    const {
        switchTab
    } = useTicketSidebar();

    const {
        loadingTicketAssignments,
        ticketAssignments
    } = useTicketAssignments(ticket);

    if (loadingTicketAssignments) return null;
    if (!ticketAssignments || !ticketAssignments.length) return null;

    const usableAssignments = ticketAssignments.filter(t => !!t.isCc).sort((a, b) => !!a.user ? -1 : (!!b.user ? 1 : 0)) ?? [];
    
    if (usableAssignments.length <= 0) return null;

    const getAssignmentPreview = () => {
        const firstThree = usableAssignments.slice(0, 3);

        const result = [];

        for (const item of firstThree) {
            if (!item.user || typeof item.user === "string") {
                if (!item.mailAddress) continue;
                result.push(item.mailAddress);
                continue;
            }

            const name = getName(item.user, false, true);

            if (name === "-") {
                if (!item.mailAddress) continue;
                result.push(item.mailAddress);
                continue;
            }

            result.push(name);
        }

        const text = `CC: ${result.join(", ")}`;

        if (usableAssignments.length > 3) return `${text} + ${usableAssignments.length - 3}`;

        return text;
    }

    const items = getAssignmentPreview();



    return (
        <Pill 
            color="#F6F6F6"
            textColor="#434343"
            text={items} 
            onClick={() => {
                dispatch(setRightSidebarExpanded(true))
                switchTab(TicketChatSidebarTab.Assignments);
            }} 
        />
    )
}