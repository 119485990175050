import React from "react";
import { IRole } from "../../types/ApiTypes";
import WithPermissions from "./WithPermissions";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { useRoles } from "../../state/api/roles/useRoles";
import { rolesDelete } from "../../api/Api";

export interface IDeleteRoleButtonProps {
    role: IRole        
}  

export default function DeleteRoleButton({role}: IDeleteRoleButtonProps) {
    
    const callApi = useApi();
    
    const {
        reloadRoles
    } = useRoles();

    return (
        <WithPermissions permissions={[ "roles.all.delete" ]}>
            <Button 
                color="error"
                text="Löschen" 
                icon="trash"
                onClick={async () => {
                    const res = await callApi(rolesDelete({id: role._id}));
                    if (res && res.success) await reloadRoles();
                }}
            /> 
        </WithPermissions>
    )
}