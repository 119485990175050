
import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { IOfficeAccount } from "../../../types/officeAccount.types";

export const useAllTeamOfficeAccounts = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IOfficeAccount>>(ApiRoutes.OfficeAccounts.Accounts.ForTeams);

    return {
        allTeamOfficeAccounts: data,
        loadingAllTeamOfficeAccounts: isLoading,
        reloadAllTeamOfficeAccounts: mutate
    }
}