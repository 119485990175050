import React from "react";
import { RoundMailTargetType } from "../../api/ApiRequests";
import { useClients } from "../../state/api/clients/useClients";
import Button from "../buttons/Button";
import Card from "../card/Card";
import ClientTypeBanner from "../clients/ClientTypeBanner";
import Flex from "../container/Flex";
import InfiniteScroll from "../infiniteScroll/InfiniteScroll";
import LoadingSpinner from "../loader/LoadingSpinner";
import SearchBox from "../search/SearchBox";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import TeamBanner from "../teams/TeamBanner";
import Typography from "../text/Typography";
import TicketAssigneeBanner from "../ticketing/banner/TicketAssigneeBanner";
import TicketTypeBanner from "../ticketing/banner/TicketTypeBanner";
import TicketCardHeader from "../ticketing/lists/TicketCardHeader";
import { useTicketsWithFilter } from "../../state/api/tickets/useTicketsWithFilter";
import { TicketFilter } from "../../hooks/useFilteredTickets";

export interface IRoundMailTargetListProps {
    saveIds: (ids: string[]) => void,
    values: string[],
    saveTargetType: (type: RoundMailTargetType) => void
}

export default function RoundMailTargetList({saveIds, values, saveTargetType}: IRoundMailTargetListProps) {
    const [filter, setFilter] = React.useState<string>("");
    const [targetType, setTargetType] = React.useState<RoundMailTargetType>(RoundMailTargetType.Clients);
    const [selectedItems, setSelectedItems] = React.useState<Array<string>>([]);
    
    const { tickets, loadingTickets } = useTicketsWithFilter({ show: TicketFilter.All });
    const { clients, loadingClients } = useClients();

    const selectAll = () => {
        switch (targetType) {
            case RoundMailTargetType.Clients:
                if (!clients || !clients.length) return;
                setSelectedItems(clients.map(c => c._id));
                break;
            case RoundMailTargetType.Tickets:
                if (!tickets || !tickets.length) return;
                setSelectedItems(tickets.map(t => t._id));
                break;
        }
    }

    const isAllSelected = () => {
        switch (targetType) {
            case RoundMailTargetType.Clients:
                if (!clients || !clients.length) return false;
                return selectedItems.length === clients.length;
            case RoundMailTargetType.Tickets:
                if (!tickets || !tickets.length) return false;
                return selectedItems.length === tickets.length;
        }
    }

    const toggleIdSelected = (id: string) => {
        if (isSelected(id)) removeFromSelectedItems(id);
        else addToSelectedItems(id);
    }

    const removeFromSelectedItems = (id: string) => {
        if (!selectedItems || !selectedItems.length) return;
        setSelectedItems(selectedItems.filter(i => i !== id));
    }

    const isSelected = (id: string) => selectedItems && !!selectedItems.length && selectedItems.includes(id);

    const addToSelectedItems = (id: string) => {
        if (!selectedItems || !selectedItems.length) return setSelectedItems([id]);
        if (selectedItems.includes(id)) return;
        setSelectedItems([...selectedItems, id]);
    }

    const clearSelectedItems = () => setSelectedItems([]);  

    React.useEffect(() => {
        clearSelectedItems()
        saveTargetType(targetType);
    }, [targetType]);

    React.useEffect(() => {
        saveIds(selectedItems)
    }, [selectedItems]);

    const getList = () => {
        switch (targetType) {
            case RoundMailTargetType.Clients:
                if (loadingClients) return [<LoadingSpinner key="round-mail-target-list-loader" />]
                if (!clients || !clients.length) return [<Typography key="no-clients-found-for-roundmail">Keine Mandanten gefunden</Typography>]

                return clients.filter(c => !filter || c.name.includes(filter)).map(c => getSelectable(c._id, (
                    <ClientTypeBanner key={c._id} client={c} showClientName />
                )));

            case RoundMailTargetType.Tickets: 

                if (loadingTickets) return [<LoadingSpinner />]
                if (!tickets || !tickets.length) return [<Typography>Keine Tickets gefunden</Typography>]
                return tickets.filter(t => !filter || t.ticket?.subject.includes(filter)).map(t => getSelectable(t._id, (
                    <Card key={t._id} className="p-2 w-100" header={<TicketCardHeader ticket={t.ticket} />} style={{backgroundColor: "var(--backgroundLighter)"}}>
                        <Flex row className="w-100" justify="between" align="start">
                            <Flex>
                                <span>{t.ticket?.subject}</span>
                                <Flex row>
                                    <TicketTypeBanner ticket={t.ticket} />
                                    { t.ticket?.team && <TeamBanner team={t.ticket?.team} /> }
                                    <TicketAssigneeBanner ticket={t.ticket} />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Card>
                )));
        }
    }


    const getSelectable = (id: string, content: any) => (
        <Flex row fullWidth key={id} align="start">
            <div className="d-flex flex-column check-box align-items-start p-2" onClick={() => toggleIdSelected(id)}>
                <div className="d-flex flex-row align-items-center" >
                    <input readOnly type="checkbox" style={{height: "16px", width: "16px"}} checked={isSelected(id)} className="form-check-input m-0 p-0" />
                </div>
            </div>
            {content}
        </Flex>
    )
    
    const hasSelectedAll = isAllSelected();

    return (
        <Flex fullWidth>
            <Typography bold color="primary">Empfänger auswählen</Typography>
            <Flex wrap row justify="between" fullWidth>
                <TabSwitcher 
                    size="tiny"
                    tabs={[
                        { data: RoundMailTargetType.Clients, label: "Mandanten" },
                        { data: RoundMailTargetType.Tickets, label: "Tickets" }
                    ]}
                    tabQueryParamKey="targetType"
                    saveActiveTab={type => setTargetType(type as RoundMailTargetType)}
                />
                <Flex row>
                    <SearchBox delay={50} onChange={val => setFilter(val)} initialValue={filter}  />
                    <Button variant="icon" onClick={async () => hasSelectedAll ? clearSelectedItems() : selectAll()} color={hasSelectedAll ? "error" : "primary"} icon={hasSelectedAll ? "x" : "check-all"} />
                </Flex>
            </Flex>
            <Flex fullWidth>
                <Flex fullWidth fullHeight >
                    <InfiniteScroll>
                        {
                            getList()
                        }
                    </InfiniteScroll>
                </Flex>
            </Flex>
        </Flex>
    )
}