import React from "react";
import { importValidationErrorCheck } from "../../api/Api";
import { IRequestWithId } from "../../api/ApiRequests";
import useAddresseeUtil from "../../hooks/useAddresseeUtil";
import useApi from "../../hooks/useApi";
import useImportValidationUtil from "../../hooks/useImportValidationUtil";
import { useImportValidationError } from "../../state/api/importValidation/useImportValidationError";
import { IAddressee, IImportValidationError } from "../../types/ApiTypes";
import { formatDateTiggiStyle } from "../../util/formatter";
import AddresseePill from "../addressee/AddresseeBanner";
import Button from "../buttons/Button";
import Card from "../card/Card";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import RoleBanner from "../user/RoleBanner";

export interface IImportValidationChipProps {
  addressee: IAddressee,
  importValidationError: IImportValidationError
}

export default function ImportValidationForm(props: IImportValidationChipProps) {

  const {
    addressee,
    importValidationError
  } = props;

  const {
    reloadImportValidationErrors
  } = useImportValidationError({ addressee: importValidationError.addressee, client: importValidationError.client, employee: importValidationError.employee });

  const {
    color,
    description,
    text
  } = useImportValidationUtil(importValidationError);

  const {
    checked
  } = importValidationError;

  const callApi = useApi();

  const {
    getAddresseeDuplicates,
    getAddresseeTypeColor,
  } = useAddresseeUtil();

  if (!importValidationError) return null

  const duplicates = getAddresseeDuplicates(addressee);

  return (
    <WithPermissions permissions={["interfaces.datev.read"]}>
      <Pill
        color={color}
        className="gap-1"
        style={{ fontSize: "0.9em" }}
      >
        <Icon
          tooltipDelay={0}
          tooltipAction={
            hide => (
              <ModalForm
                button={<Button icon="check" color="success" text="Überprüfen und erledigen" />}
                initialValues={{
                  id: importValidationError._id
                } as IRequestWithId}
                onSubmit={async (values) => {
                  const res = await callApi(importValidationErrorCheck(values));
                  await reloadImportValidationErrors();
                  return !!res && !!res.success;
                }}
                onClose={hide}
                title="Import-Fehler"
                submitText="Als Geprüft markieren"
                submitIcon="check"
                submitDisabled={importValidationError.checked}
              >
                {
                  formik => (
                    <Flex fullWidth gap={3}>
                      <Flex>
                        <Typography bold color="primary">Fehler</Typography>
                        <Typography wrap className="w-100">{description}</Typography>
                      </Flex>
                      {
                        duplicates?.length && (
                          <Flex>
                            <Typography color="primary" bold>Duplikats-Datensätze</Typography>
                            {
                              duplicates.map(d => <AddresseePill addressee={d} showName key={d._id} />)
                            }
                          </Flex>
                        )
                      }
                      {
                        importValidationError.checked && (
                          <Card
                            color="success"
                            header={
                              <Flex row justify="between" fullWidth>
                                <Typography bold>Geprüft</Typography>
                                <Typography bold>{formatDateTiggiStyle(importValidationError.checkedAt)}</Typography>
                              </Flex>
                            }
                          >
                            <Flex>
                              <Typography>Der Fehler wurde geprüft von:</Typography>
                              <RoleBanner user={importValidationError.checkedBy} displayNameAsValue showMail />
                            </Flex>
                          </Card>
                        )
                      }
                    </Flex>
                  )
                }
              </ModalForm>
            )
          }
          icon={
            checked
              ? "check"
              : "info-circle"
          }
          tooltipTitle={checked ? undefined : "Was bedeutet das?"}
          color="bright"
          tooltip={checked ? undefined : description}
        />
        <Typography basedOnThisBackground={color} bold>{text}</Typography>
      </Pill>
    </WithPermissions>
  )
}