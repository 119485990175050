import React from "react";
import AddDomainForm from "../../components/domain/AddDomainForm";
import DomainList from "../../components/domain/DomainList";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import Page from "../../components/container/Page";
import { Locale } from "../../locale/Locale";
import { useTenantDomains } from "../../state/api/domains/useTenantDomains";
import useLabels from "../../hooks/useLabels";

export default function TenantDomainManagement() {
    const { tenantDomains, loadingTenantDomains } = useTenantDomains();

    const getLabel = useLabels();
    
    return (
        <Page header={Locale.titles.tenantDomainManagement} actionButtons={<AddDomainForm />}>
            {
                loadingTenantDomains
                ? <LoadingSpinner />
                : <DomainList domains={tenantDomains || []} />
            }
        </Page>
    )
}