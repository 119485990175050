
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IUserDefaultTeams } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAnyUserDefaultTeam = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<IUserDefaultTeams>(ApiRoutes.User.Teams.Default.getValue({ param: RouteParam.UserId, value: id}));

    return {
        defaultTeam: data,
        loadingDefaultTeam: isLoading,
        reloadDefaultTeam: mutate
    }
}