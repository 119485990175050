import React from "react";
import Button from "../buttons/Button";
import ModalForm from "../modal/ModalForm";
import { Locale } from "../../locale/Locale";
import { Field, Form, Formik } from "formik";
import { ICouponCreateRequest, IFeatureCreateRequest, IServiceCreateRequest } from "../../api/requests/subscriptions/subscriptionRequests";
import FieldWithLabel from "../forms/FormikField";
import useApi from "../../hooks/useApi";
import { couponsCreate, featureCreate, servicesCreate } from "../../api/Api";
import { CouponType, TiggiFeature } from "../../types/ApiTypes";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import useCoupon from "../../hooks/useCoupon";
import Flex from "../container/Flex";
import { useCoupons } from "../../state/api/subscriptions/useCoupons";
import { useUser } from "../../state/api/user/useUser";

export default function CreateCouponButton() {
    const {
        user
    } = useUser();

    const callApi = useApi();

    const { reloadCoupons } = useCoupons();

    const {
        couponDurations,
        couponTypes
    } = useCoupon(); 

    if (!user || !user.isSuperAdmin) return null;
    
    return (
        <ModalForm 
            title={Locale.pages.services.addCoupon} 
            button={<Button icon="plus" color="success">{Locale.pages.services.addCoupon}</Button>}
            initialValues={{
                name: "",
                amount: 0,
                appliesTo: [],
                canBeAppliedToAnything: true,
                duration: "once",
                maxRedemptions: 0,
                repeatsForMonths: 0,
                type: CouponType.PercentOff,
                validUntil: new Date()
            } as ICouponCreateRequest}
            onSubmit={async (values) => {
                const res = await callApi(couponsCreate(values));

                if (!res) return false;

                await reloadCoupons();
                return true;
            }}
            sidebar={formik => <Button type="submit" text="Rabattgruppe erstellen" icon="plus" loading={formik.isSubmitting} />}
        >
            {
                (formik) => (
                    <Flex className="w-100">
                        <FieldWithLabel noMargin name="name" label="Name der Rabattgruppe" placeholder="50% Rabatt, 3 Monate umsonst, 50€ Bonus..." className="w-100" />
                        <SearchableComboBox 
                            className="w-100"
                            label="Gültigkeitsdauer" 
                            values={couponDurations} 
                            itemToId={v => v.key} 
                            itemToString={v => v.key} 
                            onItemClick={(v) => formik.setFieldValue("duration", v?.value)}
                        />
                        {
                            formik.values.duration === "repeating" && (
                                <div className="d-flex flex-column gap-1">
                                    <label htmlFor="coupon-months-field">Dauer in Monaten</label>
                                    <Field id="coupon-months-field" name="repeatsForMonths" type="number" min="0" step="1" className="form-control" placeholder="0" />
                                </div>
                            )
                        }
                        <SearchableComboBox
                            className="w-100"
                            label="Typ des Rabatts"
                            values={couponTypes}
                            itemToId={t => t.key} 
                            itemToString={t => t.key} 
                            onItemClick={(v) => formik.setFieldValue("type", v?.value)}
                        />
                        <FieldWithLabel type="date" name="validUntil" label="Gültig bis" noMargin />
                        <div className="d-flex flex-column gap-1">
                            <label htmlFor="coupon-amount-field">Betrag</label>
                            <Field id="coupon-amount-field" name="amount" type="number" min="0" max={formik.values.type === CouponType.PercentOff ? "100" : undefined} step="1" className="form-control" placeholder="0" />
                        </div>
                        <div className="d-flex flex-column gap-1">
                            <label htmlFor="coupon-usages-field">Maximale Verwendungen (0 = unbegrenzt)</label>
                            <Field id="coupon-usages-field" name="maxRedemptions" type="number" min="0" step="1" className="form-control" placeholder="0" />
                        </div>
                    </Flex>
                )
            }
        </ModalForm>
    )
}