import React from "react";
import { IService } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { servicesDelete } from "../../api/Api";
import { AppRoutes } from "../../config/AppRoutes";
import { AppColor } from "../../app/AppStyles";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { useServices } from "../../state/api/subscriptions/useServices";
import { useUser } from "../../state/api/user/useUser";

export default function DeleteServiceButton({service}: {service: IService}) {
    const {
        user
    } = useUser();

    const callApi = useApi();

    const { reloadServices } = useServices();

    const clickHandler = async () => {
        const res = await callApi(servicesDelete({id: service._id}));
        if (!res || !res.success) return;
        await reloadServices();
    }

    if (!user || !user.isSuperAdmin) return null;

    return <Button onClick={clickHandler} text="Löschen" icon="trash" loadingText="Wird gelöscht..." color="error" />
}