import React from "react";
import Button from "../buttons/Button";
import ModalForm from "../modal/ModalForm";
import { Locale } from "../../locale/Locale";
import { AppColor } from "../../app/AppStyles";
import useApi from "../../hooks/useApi";
import { tenantsAddPaymentMethod } from "../../api/Api";
import { useAppSelector } from "../../hooks/reduxHooks";
import LoadingSpinner from "../loader/LoadingSpinner";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { StripePK } from "../../util/envVars";
import ModalDialog from "../modal/ModalDialog";
import { useTenant } from "../../state/api/tenant/useTenant";
import WithPermissions from "../permissions/WithPermissions";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import useModal from "../../hooks/useModal";
import { Form, Formik } from "formik";
import { ModalType } from "../../config/ModalTypes";
import { tenantsSetHasPaymentMethod } from "../../api/Api";
import PageLoader from "../loader/PageLoader";

const stripePromise = loadStripe(StripePK);

export default function CreatePaymentMethodButton() {
    const [formVisible, setFormVisible] = React.useState<boolean>(false);
    const [creatingSetupIntent, setCreatingSetupIntent] = React.useState<boolean>(false);
    const [setupSecret, setSetupSecret] = React.useState<string>();

    const { tenant } = useTenant();

    const callApi = useApi();

    React.useEffect(() => {
        if (!formVisible) return;
        if (!tenant) return;
        if (creatingSetupIntent) return;

        setCreatingSetupIntent(true);

        const abortController = new AbortController();

        const createSetupIntent = async () => {
            const secret = await callApi<{clientSecret: string}>(tenantsAddPaymentMethod({_id: tenant._id}, abortController));

            if (!secret || !secret.success || !secret.data || !secret.data.clientSecret) {
                setCreatingSetupIntent(false);
                setSetupSecret(undefined);
                setFormVisible(false);
                return;
            }

            setSetupSecret(secret.data.clientSecret);
            setCreatingSetupIntent(false);
        }

        createSetupIntent();

        return () => abortController.abort();

    }, [formVisible]);
    
    const loader = <div className="d-flex flex-row align-items-center justify-content-start"><LoadingSpinner size={20} text="Bitte warten..." /></div>;

    return (
        <WithPermissions permissions={[ "billing.tenant.create" ]}>
            <ModalDialog 
                title={Locale.pages.billing.addPaymentMethod} 
                button={<Button color="success" icon="plus">{Locale.pages.billing.addPaymentMethod}</Button>}
            >
                {
                    creatingSetupIntent
                    ? loader
                    : (
                        setupSecret 
                        ? (
                            <Elements stripe={stripePromise} options={{clientSecret: setupSecret, loader: "auto", appearance: { theme: "stripe" } }}>
                                <CreatePaymentMethodForm tenantId={tenant._id} />
                            </Elements>
                        )
                        : loader
                    )
                }
            </ModalDialog>
        </WithPermissions>
    )
}

function CreatePaymentMethodForm({tenantId}: {tenantId: string}) {
    const stripe = useStripe();
    const elements = useElements();

    const callApi = useApi();
    const showModal = useModal();
    
    return (
        <Formik
            initialValues={{}}
            onSubmit={async () => {
                if (!stripe || !elements) return;

                elements.submit();

                const { error } = await stripe.confirmSetup({
                    elements: elements,
                    redirect: "if_required",
                    confirmParams: { return_url: window.location.href }
                });

                if (error) {
                    showModal({
                        text: "Bei der Validierung Ihrer Zahlungsangaben ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben.",
                        type: ModalType.Error
                    });
                    return;
                }

                await callApi(tenantsSetHasPaymentMethod({_id: tenantId}));
            }}
        >
            {
                (formik) => stripe
                ? (
                    <Form className="w-100 h-100 d-flex flex-column justify-content-center gap-3">
                        <h6 className="fw-bold">Bitte geben Sie Ihre Zahlungsdetails ein.</h6>
                        <PaymentElement id="payment-element" />
                        <Button type="submit" text="Speichern" icon="save" loading={formik.isSubmitting} />
                    </Form>
                )
                : <PageLoader />
            }
        </Formik>
    )
}