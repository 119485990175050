import React from "react";
import MailLogOverview from "../../components/log/mail/MailLogOverview";
import RefreshMailLogButton from "../../components/log/mail/RefreshMailLogButton";
import Page from "../../components/container/Page";
import { Locale } from "../../locale/Locale";
import { Formik } from "formik";
import Button from "../../components/buttons/Button";
import useApi from "../../hooks/useApi";
import { useUser } from "../../state/api/user/useUser";
import { useCurrentTenantAuthority } from "../../state/api/user/useCurrentTenantAuthority";
import { ISendRoundMailRequest, RoundMailTargetType } from "../../api/ApiRequests";
import { roundMailsSend } from "../../api/Api";
import Flex from "../../components/container/Flex";
import FormikField from "../../components/forms/FormikField";
import Typography from "../../components/text/Typography";
import Editor from "../../components/richText/Editor";
import RoundMailTargetList from "../../components/mail/RoundMailTargetList";
import AttachmentSelect from "../../components/mail/inbox/AttachmentSelect";

export default function RoundMail() {
    const callApi = useApi();
    const { user } = useUser();
    const { authority } = useCurrentTenantAuthority();

    if ((!user || !user.isSuperAdmin) && (!authority || !authority.isTenantAdmin)) return null;

    return (
        <Formik
            initialValues={{
                content: "",
                subject: "",
                targets: [],
                targetType: RoundMailTargetType.Clients
            } as ISendRoundMailRequest}
            onSubmit={async (values) => {
                const res = await callApi(roundMailsSend(values));
                if (!res || !res.success) return false;
                return true;  
            }}
        >
            {
                formik => (
                    <Page 
                        header={Locale.titles.roundMail} 
                        actionButtons={[ 
                            <Button 
                                text="Rundmail senden" 
                                icon="send" 
                                color="secondary" 
                                onClick={() => formik.submitForm()} 
                                key={"send-roundmail-btn"}
                            /> 
                        ]}
                    >
                        <Flex 
                            fullWidth 
                            fullHeight 
                            gap={3}
                        >
                            <FormikField className="w-100" name="subject" label="Betreff" bold labelColor="primary" />
                            <AttachmentSelect 
                                label="Anhänge"
                            />
                            <Flex fullWidth>
                                <Typography bold color="primary">Inhalt</Typography>
                                <Editor
                                    onChange={content => formik.setFieldValue("content", content)}
                                    content={formik.values.content}
                                    useFieldStyle
                                    disabled={formik.isSubmitting} 
                                />
                            </Flex>
                            <RoundMailTargetList 
                                saveIds={ids => formik.setFieldValue("targets", ids)}
                                values={formik.values.targets}
                                saveTargetType={type => formik.setFieldValue("targetType", type)}
                            />
                        </Flex>
                    </Page>
                )
            }
        </Formik>
    )
}