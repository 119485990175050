import React from "react";
import { useSearchParams } from "react-router-dom";
import { SortDirection } from "../components/buttons/SortDirectionToggle";
import useFilterQuery from "./useFilterQuery";

export enum OfficeMailSort {
  Date = "date",
  Subject = "subject",
  From = "from",
  To = "to"
}

export interface IOfficeFilterBase {
  sort?: OfficeMailSort,
  sortDirection?: SortDirection,
  search?: string
  hasAttachments?: boolean,
}

export interface IOfficeMailQuery extends IOfficeFilterBase {
  unread?: boolean,
}

export enum OfficeMailboxTab {
  Personal = "personal",
  Teams = "teams",
  Tenant = "tenant",
  All = "all"
}

export interface IOfficeMailFilter extends IOfficeFilterBase {
  view?: OfficeMailboxTab,
  show?: OfficeMailShow
}

export enum OfficeMailFilterQueryParam {
  Inbox = "inbox",
  Visible = "visible",
  HasAttachments = "hasAttachments",
  Sort = "sort",
  Search = "search",
  Direction = "sortDirection"
}

export enum OfficeMailShow {
  Unread = "unread",
  All = "all"
}

export default function useFilteredOfficeMails() {
  
  const [direction, setDirection] = React.useState<SortDirection>(SortDirection.Descending);
  const [sort, setSort] = React.useState<OfficeMailSort>(OfficeMailSort.Date);
  const [hasAttachments, setHasAttachments] = React.useState<boolean>(false);
  const [visible, setVisible] = React.useState<OfficeMailShow>(OfficeMailShow.All);
  const [filterText, setFilterText] = React.useState<string>("");
  const [inbox, setInbox] = React.useState<OfficeMailboxTab>(OfficeMailboxTab.Personal);

  const { updateQueryFromFilter } = useFilterQuery();

  const [query, setQuery] = useSearchParams();

  React.useEffect(() => {
    if (!query) return;

    const v = query.get(OfficeMailFilterQueryParam.Inbox);

    if (v) setInbox(v as OfficeMailboxTab);
    else setInbox(OfficeMailboxTab.Personal);

    const u = query.get(OfficeMailFilterQueryParam.Visible);

    if (!u || u !== OfficeMailShow.Unread) setVisible(OfficeMailShow.All);
    else setVisible(u as OfficeMailShow);

    const a = query.get(OfficeMailFilterQueryParam.HasAttachments);

    if (!a) setHasAttachments(false);
    else setHasAttachments(true);

    const s = query.get(OfficeMailFilterQueryParam.Sort);

    if (!s) setSort(OfficeMailSort.Date);
    else setSort(s as OfficeMailSort);

    const t = query.get(OfficeMailFilterQueryParam.Search);

    if (!t) setFilterText("");
    else setFilterText(t);

    const d = query.get(OfficeMailFilterQueryParam.Direction);

    if (!d) setDirection(SortDirection.Descending);
    else setDirection(d as SortDirection);
  }, [query]);

  const updateFilter = (filter?: IOfficeMailFilter) => {

    const update: IOfficeMailFilter = {
      hasAttachments: filter?.hasAttachments || hasAttachments,
      show: filter?.show || visible || OfficeMailShow.All,
      sort: filter?.sort || sort || OfficeMailSort.Date,
      sortDirection: filter?.sortDirection || SortDirection.Descending,
      search: filter?.search || "",
    }

    updateQueryFromFilter(update);
  }

  return {
    hasAttachments,
    inbox,
    sort,
    visible,
    direction,
    filterText,
    filter: {
      hasAttachments: hasAttachments,
      unread: visible === OfficeMailShow.Unread,
      sort: sort,
      sortDirection: direction,
      search: filterText
    } as IOfficeMailQuery,
    updateFilter
  }
}