
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IUserProfilePicture } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAnyUserAvatar = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<IUserProfilePicture>(ApiRoutes.User.Avatar.getValue({ param: RouteParam.UserId, value: id}));

    return {
        userAvatar: data,
        loadingUserAvatar: isLoading,
        reloadUserAvatar: mutate
    }
}