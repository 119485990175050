import React from "react";
import { BlacklistType, IBlacklist, IBlacklistEntry } from "../../types/blacklist.schema";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import { boolean } from "yup";
import Button from "../buttons/Button";
import { Form, Formik, FormikProvider } from "formik";
import { IApiResponse } from "../../api/Api";
import useApi from "../../hooks/useApi";
import Card from "../card/Card";
import FormikField from "../forms/FormikField";
import Pill from "../pills/Pill";

export interface IBlacklistEntriesProps {
  type: BlacklistType,
  entries: Array<IBlacklist>,
  saveEntry: (entry: IBlacklistEntry) => Promise<IApiResponse<any>>,
  deleteEntry: (id: string) => Promise<IApiResponse<any>>,
  reloadEntries: () => Promise<any>
}

export default function BlacklistEntries(props: IBlacklistEntriesProps) {

  const [formVisible, setFormVisible] = React.useState<boolean>(false);

  const {
    reloadEntries,
    deleteEntry,
    saveEntry,
    entries,
    type
  } = props;

  const getLabel = () => {
    switch (type) {
      case BlacklistType.Domain: return "Domains";
      case BlacklistType.Email: return "E-Mail-Addressen";
      case BlacklistType.IP: return "IP-Adressen";
      case BlacklistType.Phone: return "Telefonnummern";
    }
  }

  const getIcon = () => {
    switch (type) {
      case BlacklistType.Domain: return "globe";
      case BlacklistType.Email: return "envelope";
      case BlacklistType.IP: return "ethernet";
      case BlacklistType.Phone: return "phone";
    }
  }

  const getColor = () => {
    switch (type) {
      case BlacklistType.Domain: return "backgroundDarker";
      case BlacklistType.Email: return "primary";
      case BlacklistType.IP: return "secondary";
      case BlacklistType.Phone: return "#333CAA";
    }
  }

  const callApi = useApi();
  const label = getLabel();

  return (
    <Flex fullWidth>
      <Flex row justify="between" fullWidth>
        <Typography size="12" color="primary" bold>{label}</Typography>
        <Formik
          initialValues={{
            type,
            value: ""
          } as IBlacklistEntry}
          onSubmit={async (values, actions) => {
            const res = await callApi(saveEntry(values));
            if (!res?.success) return;
            setFormVisible(false);
            actions.resetForm();
            await reloadEntries();
          }}
        >
          {
            formik => (
              <Form>
                <Flex row>
                  {
                    formVisible && (
                      <>
                        <FormikField
                          name="value"
                          placeholder={`${label} hinzufügen`}
                        />
                        <Button
                          onClick={formik.submitForm}
                          text="Speichern"
                          color="success"
                          size="small"
                          icon="save"
                        />
                      </>
                    )
                  }
                  <Button
                    onClick={() => {
                      if (formVisible) formik.resetForm();
                      setFormVisible(!formVisible)
                    }}
                    variant="subtle"
                    text={
                      formVisible
                        ? "Abbrechen"
                        : "Neuer Eintrag"
                    }
                    color={
                      formVisible
                        ? "error"
                        : "primary"
                    }
                    size="small"
                    icon={
                      formVisible
                        ? "slash"
                        : "plus"
                    }
                  />
                </Flex>
              </Form>
            )
          }
        </Formik>
      </Flex>
      <Flex row>
        {
          !!entries?.length
            ? entries.map(e => (
              <Pill
                key={e._id}
                text={e.value}
                icon={getIcon()}
                color={getColor()}
                hoverIcon="x"
                hoverColor="error"
                onClick={async () => {
                  await callApi(deleteEntry(e._id));
                  await reloadEntries();
                }}
              />
            ))
            : <Typography>Keine Einträge vorhanden</Typography>
        }
      </Flex>
    </Flex>
  )
}