import { IUser } from "../types/ApiTypes";
import { useAppSelector } from "./reduxHooks";
import useUserUtil from "./useUserUtil";
import { useClientContacts } from "../state/api/clientContacts/useClientContacts";
import { DatevClientType, IClient } from "../types/client.schema";

export default function useClientUtil() {

    const { clientContacts } = useClientContacts();

    const {
        getCurrentTenantAuthority
    } = useUserUtil();

    const getClientIcon = (client?: IClient) => {
        if (!client) return "";

        switch (client.type) {
            case DatevClientType.IndividualEnterprise: return "house-heart";
            case DatevClientType.LegalPerson: return "building";
            case DatevClientType.NaturalPerson: return "person-fill";
        }
    }

    const getTypeNameOfClient = (client?: IClient): string => {
        if (!client) return "";

        switch (client.type) {
            case DatevClientType.IndividualEnterprise: return "Einzelkaufmann";
            case DatevClientType.LegalPerson: return "Juristische Person";
            case DatevClientType.NaturalPerson: return "Natürliche Person";
        }
    }

    const getClientTypeColor = (client?: IClient): string => {
        if (!client) return "#303030";

        switch (client.type) {
            case DatevClientType.IndividualEnterprise: return "#D33F3F";
            case DatevClientType.LegalPerson: return "#909090";
            case DatevClientType.NaturalPerson: return "#3030AF";
        }
    }

    const getClientName = (client?: IClient): string => client ? client.name : "-";

    const getClientContactsForClient = (client?: IClient): Array<IUser> => {
        if (!clientContacts || !clientContacts.length) return [];
        if (!client) return [];

        return clientContacts.filter(c => {
            const currentTenantAuth = getCurrentTenantAuthority(c);

            if (!currentTenantAuth || !currentTenantAuth.userAuthority) return false;

            const currentTenantClients = currentTenantAuth.userAuthority.clients;

            if (!currentTenantClients || !currentTenantClients.length) return false;

            return !! currentTenantClients.find(a => a._id === client._id);
        });
    }

    return {
        getClientIcon,
        getClientContactsForClient,
        getClientName,
        getClientTypeColor,
        getTypeNameOfClient
    }
}