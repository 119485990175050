import React from "react";
import { featuresUpdate } from "../../api/Api";
import { IFeatureUpdateRequest } from "../../api/requests/subscriptions/subscriptionRequests";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { useSortedFeatures } from "../../hooks/useSortedFeatures";
import { Locale } from "../../locale/Locale";
import { useFeatures } from "../../state/api/subscriptions/useFeatures";
import { useUser } from "../../state/api/user/useUser";
import { IFeature } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import FieldWithLabel from "../forms/FormikField";
import ModalForm from "../modal/ModalForm";

export default function UpdateFeatureButton({feature}: {feature: IFeature}) {

    const {
        user
    } = useUser();

    const dispatch = useAppDispatch();
    const callApi = useApi();

    const { reloadFeatures } = useFeatures();

    const {
        usableTiggiFeatures,
        convertNumberToTiggiFeature
    } = useSortedFeatures();

    if (!user || !user.isSuperAdmin) return null;

    return (
        <ModalForm 
            title={convertNumberToTiggiFeature(feature.feature) || Locale.pages.services.updateFeature} 
            button={<Button text={Locale.pages.services.updateFeature} icon="pen" />}
            initialValues={{
                name: feature.name,
                id: feature._id
            } as IFeatureUpdateRequest}
            onSubmit={async (values) => {
                const res = await callApi(featuresUpdate(values));
                if (!res) return false;
                await reloadFeatures();
                return true;
            }}
            sidebar={formik => <Button type="submit" text="Speichern" icon="save" loading={formik.isSubmitting} />}
        >
            {
                (formik) => (
                    <Flex className="d-flex flex-column gap-2">
                        <div className="d-flex text-start flex-row align-items-center w-100 gap-3">
                            <FieldWithLabel noMargin name="name" label="Name des Features" placeholder="z.B. Zugriff auf Domainverwaltung" className="w-100" />
                        </div>
                        
                    </Flex>
                )
            }
        </ModalForm>
    )
}