import React from "react";
import { usePlans } from "../../state/api/subscriptions/usePlans";
import { useUser } from "../../state/api/user/useUser";
import { IService } from "../../types/ApiTypes";
import Card from "../card/Card";
import LoadingSpinner from "../loader/LoadingSpinner";
import CreatePlanButton from "./CreatePlanButton";
import DeletePlanButton from "./DeletePlanButton";
import DeleteServiceButton from "./DeleteServiceButton";
import EditablePlanCard from "./PlanCard";
import UpdatePlanButton from "./UpdatePlanButton";
import UpdateServiceButton from "./UpdateServiceButton";

export default function ServiceCard({service}: {service: IService}) {

    const {
        user
    } = useUser();

    const { plans, loadingPlans } = usePlans(service._id);
    
    if (!user || !user.isSuperAdmin) return null;

    return (
        <Card className="d-flex flex-column gap-2">
            <div className="d-flex flex-row align-items-center justify-content-between w-100">
                <div className="d-flex flex-column">
                    <h6 className="fw-bold m-0 p-0">{service.name}</h6>
                    {
                        service.isGlobalBaseSubscription && <span>Globales Basis-Abo</span>
                    }
                </div>
                <div className="d-flex flex-row align-items-center gap-2">
                    <UpdateServiceButton service={service} />
                    <DeleteServiceButton service={service} />
                </div>
            </div>
            <div className="d-flex flex-column">
                <strong>Beschreibung</strong>
                <span>{ service.description || "Keine Beschreibung hinzugefügt." }</span>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between w-100">
                <strong>Pläne</strong>
                <CreatePlanButton service={service} />
            </div>
            <div className="d-flex flex-row align-items-center w-100 gap-3" style={{overflowX: "auto"}}>
                {
                    loadingPlans
                    ? <LoadingSpinner />
                    : (
                        plans && plans.length
                        ? (
                            plans.map(p => (
                                <EditablePlanCard key={p._id} plan={p} >
                                    <UpdatePlanButton plan={p} service={service} />
                                    <DeletePlanButton plan={p} service={service} />
                                </EditablePlanCard>
                            ))
                        )
                        : <span>Keine Pläne hinterlegt. Hinterlegen Sie einen Plan, um einen Kauf dieses Abos zu ermöglichen.</span>
                    )
                }
            </div>
        </Card>
    )

}