import React from "react";
import Flex from "../container/Flex";
import Typography from "./Typography";
import { AppColor } from "../../app/AppStyles";

export interface IWithLabelProps extends React.PropsWithChildren {
  label: string,
  gap?: 0 | 1 | 2 | 3 | 4 | 5,
  horizontal?: boolean,
  color?: AppColor
}

export default function WithLabel({ horizontal, color = "primary", label, gap, children }: IWithLabelProps) {
  return (
    <Flex gap={gap} fullWidth row={horizontal} >
      <Typography color={color} size={12}>{label}</Typography>
      {children}
    </Flex>
  )
}