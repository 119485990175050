import React from "react";
import { ticketsDelete, ticketsUpdateStatus } from "../../../api/Api";
import { ITicketsUpdateStatusRequest } from "../../../api/ApiRequests";
import { AppColor } from "../../../app/AppStyles";
import useApi from "../../../hooks/useApi";
import useTicketUtil from "../../../hooks/useTicketUtil";
import { useTicket } from "../../../state/api/tickets/useTicket";
import { useCurrentTenantRole } from "../../../state/api/user/useCurrentTenantRole";
import { ITicket, TicketState } from "../../../types/ApiTypes";
import { getDateInXFromNow } from "../../../util/util";
import Button, { ButtonSize, ButtonVariant } from "../../buttons/Button";
import Flex from "../../container/Flex";
import CheckBox from "../../forms/CheckBox";
import FormikField from "../../forms/FormikField";
import ModalForm from "../../modal/ModalForm";
import Tooltip from "../../tooltip/Tooltip";
import { useTicketsWithFilter } from "../../../state/api/tickets/useTicketsWithFilter";
import Typography from "../../text/Typography";

export interface ITicketActionProps {
  variant: ButtonVariant,
  afterAction?: () => void,
}

interface IUpdateTicketButtonProps extends ITicketActionProps {
  isSingleTicket?: boolean,
  size?: ButtonSize,
  isDelete?: boolean,
  state?: TicketState,
  tickets: ITicket[]
}

export default function UpdateTicketStatusButton(props: IUpdateTicketButtonProps) {

  const {
    tickets,
    afterAction,
    size,
    variant,
    isSingleTicket,
    state = TicketState.Open,
    isDelete
  } = props;

  const singleTicketId = isSingleTicket && tickets && tickets.length === 1 ? tickets[0]._id : "";

  const { role } = useCurrentTenantRole();
  const { reloadTickets } = useTicketsWithFilter(undefined, {
    enabled: false
  });

  const { reloadTicket } = useTicket(singleTicketId, true);

  const {
    isClosed: isTicketClosed
  } = useTicketUtil();

  const callApi = useApi();

  if (!tickets || !tickets.length) return null;

  const ticketName = isSingleTicket || tickets.length === 1 ? "Ticket" : "Tickets";

  const getActionName = (short: boolean) => {
    if (isDelete) return short ? "löschen" : "permanent löschen";

    switch (state) {
      case TicketState.Archived: return "archivieren";
      case TicketState.Closed: return "schließen";
      case TicketState.Open: return "erneut öffnen";
      case TicketState.Resubmission: return "auf Wiedervorlage";
      case TicketState.Solved: return "lösen";
    }
  }

  const getTitle = (short: boolean = false) => `${ticketName} ${getActionName(short)}`;
  const getButtonText = () => variant === "text" ? "" : getTitle(true);

  const getIcon = () => {
    if (isDelete) return "trash";

    switch (state) {
      case TicketState.Closed: return "x-circle-fill";
      case TicketState.Open: return "envelope-open";
      case TicketState.Archived: return "archive";
      case TicketState.Resubmission: return "calendar-week";
      case TicketState.Solved: return "check-circle-fill";
    }
  }

  const getColor = (): AppColor => {
    if (size === "tiny") return "primary";
    if (isDelete) return "error";

    switch (state) {
      case TicketState.Solved: return "success";
      case TicketState.Resubmission: return "primary";
      default: return "muted";
    }
  }

  const getDisabled = () => {
    if (isDelete) return false;
    if (tickets?.length !== 1) return false;

    switch (state) {
      case TicketState.Closed:
      case TicketState.Solved:

        return isTicketClosed(tickets[0]);

      case TicketState.Resubmission:

        return (tickets[0].state === TicketState.Resubmission);

      default:

        return tickets[0].state === TicketState.Open;
    }
  }

  if (role && role.isClient) return null;

  const disabled = getDisabled();
  const title = getTitle();
  const color = getColor();
  const icon = getIcon();

  if (disabled && isSingleTicket) return null;

  const btn = (
    <Button
      icon={icon}
      variant={variant}
      size={size}
      iconSize={variant === "text" ? 30 : undefined}
      color={color}
    >
      {getButtonText()}
    </Button>
  );

  const getDateForDatePicker = (d: Date) => {
    return `${d.getFullYear()}-${(d.getUTCMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
  }

  const getInitialDate = () => {
    const d = getDateInXFromNow({ days: 7 });
    return getDateForDatePicker(d);
  }

  return (
    <ModalForm
      button={
        (variant === "text" && size === "regular") ? (
          <Tooltip tooltip={getTitle()}>
            {btn}
          </Tooltip>
        )
          : btn
      }
      title={title}
      initialValues={{
        message: "",
        state: state,
        resubmitAt: getInitialDate(),
        ticketIds: tickets.map(ticket => ticket._id),
        sendMail: true
      } as ITicketsUpdateStatusRequest}
      onSubmit={async (values) => {
        const res = (
          isDelete
            ? await callApi(ticketsDelete(values))
            : await callApi(ticketsUpdateStatus(values))
        );

        if (!res || !res.success) return false;

        if (afterAction) afterAction();

        await reloadTickets(prev => (prev || []).filter(ticket => !values.ticketIds.includes(ticket._id)), { revalidate: true });
        await reloadTicket();

        return true;
      }}
      sidebar={formik => <Button type="submit" text={title} loading={formik.isSubmitting} color={color} icon={icon} />}
    >
      {
        formik => (
          <Flex fullWidth gap={3}>
            {
              isDelete && <Typography>Wollen Sie {tickets.length === 1 ? "das ausgewählte Ticket" : "die ausgewählten Tickets"} wirklich permanent und unwiderruflich löschen?<br />Diese Aktion kann <strong>nicht</strong> rückgängig gemacht werden.</Typography>
            }
            {
              state === TicketState.Resubmission && (
                <FormikField
                  min={getDateForDatePicker(new Date())}
                  max={getDateForDatePicker(getDateInXFromNow({ days: 365 }))}
                  name="resubmitAt"
                  type="date"
                  label="Wiedervorlage am"
                />
              )
            }
            <CheckBox name="sendMail" label="E-Mail mit Statusänderung an Mandanten versenden" />
          </Flex>
        )
      }
    </ModalForm>
  )
}