import { ISortedClientContact } from "../components/clientContacts/ClientContactList";
import { useAddressees } from "../state/api/addressees/useAddressees";
import { useClientContacts } from "../state/api/clientContacts/useClientContacts";
import { useLegalForms } from "../state/api/legalForms/useLegalForms";
import { useUsers } from "../state/api/user/useUsers";
import { DatevAddresseeType, DatevDuplicateReason, IAddressee, IDatevAddresseeAddress, IUser } from "../types/ApiTypes";
import { getId } from "../util/mongoUtil";

export default function useAddresseeUtil() {

  const { addressees, loadingAddressees } = useAddressees();
  const { legalFormsById, loadingLegalForms } = useLegalForms();

  const getAddresseeTypeColor = (a: IAddressee) => a.type === DatevAddresseeType.LegalPerson ? "#F0F0F0" : "#8383FF";
  const getAddresseeTypeIcon = (a: IAddressee) => a.type === DatevAddresseeType.LegalPerson ? "building" : "person";

  const addresseeIsProbablyEqual = (a: IAddressee, b: IAddressee) => {
    if (!a || !b) return false;

    if (a._id === b._id) return true;
    if (a.type !== b.type) return false;

    if (a.actualName !== b.actualName) return false;

    const addressA = getAddresseeAddress(a);
    const addressB = getAddresseeAddress(b);

    if (!addressA || !addressB) return true;

    return addressA.postal_code === addressB.postal_code;
  }

  const getAddresseeDuplicates = (a: IAddressee): Array<IAddressee> | null | undefined => {
    if (!addressees || !addressees.length) return null;
    if (!a || !a.isDuplicate) return null;

    return addressees.filter(b => {
      if (!b) return false;
      if (b._id === a._id) return false;
      if (!b.isDuplicate) return false;
      if (b.duplicateReason !== a.duplicateReason) return false;

      switch (a.duplicateReason) {
        case DatevDuplicateReason.Mail:

          return b.originalMail === a.originalMail;

        default:

          return b.actualName === a.actualName;
      }
    });
  }

  const getAddresseeAddress = (a: IAddressee): IDatevAddresseeAddress | undefined => {
    if (!a) return;
    if (!a.addresses || !a.addresses.length) return;

    const correspondenceAddress = a.addresses.find(a => a.is_correspondence_address);

    if (!!correspondenceAddress) return correspondenceAddress;

    const managementAddress = a.addresses.find(a => a.is_management_address);

    if (!!managementAddress) return managementAddress;

    const mainAddress = a.addresses.find(a => a.is_main_street_address);

    if (!!mainAddress) return mainAddress;

    return a.addresses[0];
  }

  const getAddresseeLegalForm = (a: IAddressee): string | null => {
    if (!a) return null;
    if (a.type === DatevAddresseeType.NaturalPerson) return "Natürliche Person";

    if (!a.legalForm) {
      if (a.type === DatevAddresseeType.LegalPerson) return "Rechtliche Einheit";
      else return null;
    }

    if (!legalFormsById) return "Rechtliche Einheit";

    const matchingLegalForm = legalFormsById[a.legalForm];

    if (!matchingLegalForm) return "Rechtliche Einheit";

    if (matchingLegalForm.friendlyName) return matchingLegalForm.friendlyName;
    if (matchingLegalForm.short_name) return matchingLegalForm.short_name;
    if (matchingLegalForm.name) return matchingLegalForm.short_name;
    if (matchingLegalForm.display_name) return matchingLegalForm.display_name;
    if (matchingLegalForm.long_name) return matchingLegalForm.long_name;

    return "Rechtliche Einheit";
  }

  return {
    loadingLegalForms,
    getAddresseeLegalForm,
    addresseeIsProbablyEqual,
    getAddresseeTypeIcon,
    getAddresseeTypeColor,
    getAddresseeAddress,
    getAddresseeDuplicates
  }
}