import React from "react";
import { IMailLog } from "../../../types/ApiTypes";
import { formatDateTiggiStyle, formatMailAddress } from "../../../util/formatter";
import Card from "../../card/Card";
import Flex from "../../container/Flex";
import MailLogSourceBanner from "./MailLogSourceBanner";
import MailLogTypeBanner from "./MailLogTypeBanner";
import MailRejectReasonBanner from "./MailRejectReasonBanner";

export default function MailLogCard({ item }: { item: IMailLog }) {

  return (
    <Card color="backgroundLighter" >
      <Flex className="w-100">
        <Flex gap={0}>
          {
            item.subject && (
              <Flex row>
                <em style={{ minWidth: "70px" }}>Betreff</em>
                <span>{item.subject}</span>
              </Flex>
            )
          }
          <Flex row>
            <em style={{ minWidth: "70px" }}>Datum</em>
            {item.createdAt && <span>{formatDateTiggiStyle(item.createdAt)}</span>}
          </Flex>
          <Flex row>
            <em style={{ minWidth: "70px" }}>Von</em>
            <span>{formatMailAddress(item.fromName, item.fromMail)}</span>
          </Flex>
          <Flex row>
            <em style={{ minWidth: "70px" }}>An</em>
            <span>{item.toMail}</span>
          </Flex>
          {
            !!item.cc?.length && (
              <Flex row>
                <em style={{ minWidth: "70px" }}>CC</em>
                <span>{item.cc.join(",")}</span>
              </Flex>
            )
          }
        </Flex>
        <Flex row>
          <MailLogTypeBanner type={item.type} />
          <MailLogSourceBanner source={item.source} />
          <MailRejectReasonBanner reason={item.rejectReason} />
        </Flex>
      </Flex>
    </Card>
  )
}