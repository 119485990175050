import React from "react";
import { clientsDeleteAll } from "../../api/Api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { useClients } from "../../state/api/clients/useClients";
import { useUser } from "../../state/api/user/useUser";
import Button from "../buttons/Button";
import WithPermissions from "../permissions/WithPermissions";

export default function DeleteClientsButton() {

    const { reloadClients } = useClients();
    const { user } = useUser();

    const callApi = useApi();

    if (!user) return null;
    if (!user.isSuperAdmin) return null;

    const deleteClients = async () => {
        const res = await callApi(clientsDeleteAll());
        if (!res || !res.success) return;
        await reloadClients();
    }

    return (
        <WithPermissions permissions={[ "clients.all.delete" ]}>
            <Button icon="trash" color="error" text="Mandanten löschen" onClick={deleteClients} loadingText="Mandanten werden gelöscht..." />
        </WithPermissions>
    )
}