import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { Sorted } from "../../../types/collection.types";
import { IOfficeAccount } from "../../../types/officeAccount.types";

export const useOfficeAccounts = () => {
  const { data, isLoading, mutate } = useTiggiQuery<Sorted<IOfficeAccount>>(ApiRoutes.OfficeAccounts.GetAll);

  return {
    officeAccounts: data?.items ?? [],
    officeAccountsById: data?.byId ?? {},
    loadingOfficeAccounts: isLoading,
    reloadOfficeAccounts: mutate
  }
}
