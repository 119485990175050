import React from "react";
import { Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

export interface IChartData {
  value: number,
  name: string,
  fill?: string
}

export interface ITicketTeamStatisticProps {
  statistics: Array<IChartData>
}

export default function TicketStatisticPie({ statistics }: ITicketTeamStatisticProps) {

  const total = statistics?.length ? statistics.reduce((acc, curr) => acc + curr.value, 0) : 0;

  if (!total) return null;

  return (
    <ResponsiveContainer className="d-flex flex-column" height={180}>
      <PieChart width={200} height={200} >
        <Pie
          isAnimationActive={false}
          data={statistics}
          dataKey="value"
          legendType="line"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={25}
          outerRadius={80}
          fill="#8884d8"
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}