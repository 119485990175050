import React from "react";
import { useAreasOfResponsibility } from "../state/api/areasOfResponsibilities/useAreasOfResponsibility";
import { useClients } from "../state/api/clients/useClients";
import { useDatevEmployees } from "../state/api/employees/useDatevEmployees";
import { useEmployeeResponsibilities } from "../state/api/employees/useEmployeeResponsibilities";
import { IDatevAreaOfResponsibility } from "../types/ApiTypes";
import { IDatevEmployee } from "../types/employee.schema";
import useClientResponsibilityUtil from "./useClientResponsibilityUtil";
import { IClient } from "../types/client.schema";

export interface ISortedClientResponsibility {
  areaOfResponsibility: IDatevAreaOfResponsibility,
  employees: Array<IDatevEmployee>
}

export interface ISortedClientResponsibilities {
  client: IClient,
  responsibilities: Array<ISortedClientResponsibility>
}

export default function useClientResponsibility() {

  const {
    sortClientResponsibility
  } = useClientResponsibilityUtil();

  const { areasOfResponsibility, loadingAreasOfResponsibility } = useAreasOfResponsibility();
  const { employeeResponsibilities, loadingEmployeeResponsibilities } = useEmployeeResponsibilities();
  const { clients, loadingClients } = useClients();
  const { datevEmployees, loadingDatevEmployees } = useDatevEmployees();

  const loading = loadingAreasOfResponsibility || loadingEmployeeResponsibilities || loadingClients || loadingDatevEmployees;

  return {
    loading: loading,
    sortedClientResponsibility: sortClientResponsibility(clients || [], areasOfResponsibility || [], datevEmployees || [], employeeResponsibilities || []),
    rawResponsibilities: employeeResponsibilities
  }
}