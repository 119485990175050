import { Params } from "react-router-dom";
import { Locale } from "../locale/Locale";

export interface IAppRouteSearchParam {
  param: string,
  getWithValueRemoved: (existingParams: URLSearchParams) => string,
  createNewValue: (val: any, existingParams: URLSearchParams) => string,
  getCurrentValue: (searchParams: URLSearchParams) => any,
}

const getParam = (param: string): IAppRouteSearchParam => ({
  param: param,
  getWithValueRemoved: (existingParams: URLSearchParams) => {
    var params = new URLSearchParams(existingParams ? existingParams.toString() : "");
    params.delete(param);
    return params.toString();
  },
  getCurrentValue: (searchParams: URLSearchParams) => searchParams && searchParams.get(param),
  createNewValue: (val: any, existingParams: URLSearchParams): string => {
    var params = new URLSearchParams(existingParams ? existingParams.toString() : "");
    params.set(param, val);
    return params.toString();
  }
})
export const QueryParams = {
  ImportId: getParam("importId")
}

export interface IAppRouteParam {
  forPath: string,
  keyName: string,
  getPath: (route: IAppRoute, val: string) => string,
  getValue: (params: Readonly<Params<string>>) => string | undefined
}

const getAppRouteParam = (keyName: string): IAppRouteParam => ({
  forPath: `:${keyName}`,
  keyName: keyName,
  getPath: (route, val): string => route.path.replace(`:${keyName}`, val),
  getValue: (params) => params[keyName]
})

export const getLink = (param: IAppRouteParam, route: IAppRoute, val: string) => param.getPath(route, val);

export const AppRouteParams = {
  Client: getAppRouteParam("client"),
  Addressee: getAppRouteParam("addressee"),
  DocumentClearanceReminderId: getAppRouteParam("documentClearanceReminderId"),
  UserId: getAppRouteParam("userId"),
  VerificationToken: getAppRouteParam("verificationToken"),
  PasswordResetToken: getAppRouteParam("passwordResetToken"),
  TicketId: getAppRouteParam("ticketId")
}

export interface IAppRouteGetValueParam {
  param: IAppRouteParam,
  value: string
}

export interface IAppRoute {
  path: string,
  icon?: string,
  iconActive?: string,
  title?: string
}

const buildPath = (...parts: string[]) => {
  if (!parts || !parts.length) return "*";
  return `/${parts.join("/")}`;
}

export const AppRoutes: { [key: string]: IAppRoute } = {
  Calendar: {
    path: "/calendar",
    icon: "calendar-date",
    title: Locale.titles.calendar
  },
  Client: {
    path: buildPath("clients", AppRouteParams.Client.forPath)
  },
  Addressee: {
    path: buildPath("addressees", AppRouteParams.Addressee.forPath)
  },
  Salutations: {
    path: "/salutations",
    icon: "person-raised-hand",
    title: Locale.titles.salutations
  },
  RoundMail: {
    path: "/roundmail",
    icon: "send-plus",
    title: Locale.titles.roundMail
  },
  Testing: {
    path: "/testing",
    icon: "rulers",
    title: Locale.titles.testing
  },
  RoleManagement: {
    path: "/roles",
    iconActive: "person-badge-fill",
    icon: "person-badge",
    title: Locale.titles.roleManagement
  },
  MailTemplates: {
    path: "/mail-templates",
    icon: "body-text",
    title: "Mailvorlagen"
  },
  MailManagement: {
    path: "/mail-addresses",
    icon: "inboxes",
    title: Locale.titles.mailManagement
  },
  GiveDocumentClearancePage: {
    path: buildPath("clearance", AppRouteParams.DocumentClearanceReminderId.forPath)
  },
  DocumentClearanceOverview: {
    path: buildPath("clearance"),
    icon: "file-earmark-check",
    iconActive: "file-earmark-check-fill",
    title: Locale.titles.documentClearance
  },
  AddresseeManagement: {
    path: "/addressees",
    icon: "signpost-split",
    iconActive: "signpost-split-fill",
    title: Locale.titles.addresseeManagement
  },
  Feedback: {
    path: "https://www.tiggi.io/feedback-tiggi",
    title: Locale.titles.feedback,
    icon: "chat",
    iconActive: "chat-fill"
  },
  Home: {
    path: "/dashboard",
    icon: "grid",
    iconActive: "grid-fill",
    title: Locale.titles.dashboard
  },
  Tickets: {
    path: "/",
    icon: "ticket-detailed",
    iconActive: "ticket-detailed-fill",
    title: Locale.titles.tickets
  },
  Ticket: {
    path: buildPath("tickets", AppRouteParams.TicketId.forPath),
    title: "Ticket"
  },
  RequestTenantAccess: {
    path: "/request-onboarding",
    title: "Onboarding anfragen"
  },
  RequestPasswordReset: {
    path: "/reset-password",
    title: "Passwort zurücksetzen"
  },
  SetNewPassword: {
    path: buildPath("reset-password", AppRouteParams.VerificationToken.forPath),
    title: "Neues Passwort setzen"
  },
  LogIn: {
    path: "/log-in",
    title: Locale.titles.logIn
  },
  UserManagement: {
    path: "/users",
    icon: "people",
    iconActive: "people-fill",
    title: Locale.titles.userManagement
  },
  TenantManagement: {
    path: "/tenants",
    icon: "building",
    title: Locale.titles.tenantManagement
  },
  Billing: {
    path: "/billing",
    icon: "credit-card-2-back",
    iconActive: "credit-card-2-back-fill",
    title: Locale.titles.billing
  },
  Inbox: {
    path: "/inbox",
    icon: "mailbox",
    iconActive: "mailbox2",
    title: Locale.titles.inbox
  },
  TenantOnboarding: {
    path: buildPath("join", AppRouteParams.VerificationToken.forPath),
    title: Locale.titles.register
  },
  VerifyUser: {
    path: buildPath("verify", AppRouteParams.VerificationToken.forPath),
    title: Locale.titles.register
  },
  CurrentUserProfile: {
    path: "/profile",
    icon: "person",
    iconActive: "person-fill",
    title: Locale.titles.profile
  },
  Settings: {
    path: "/settings",
    icon: "gear",
    iconActive: "gear-fill",
    title: Locale.titles.settings
  },
  ClientManagement: {
    path: "/clientContacts",
    icon: "person-badge",
    iconActive: "person-badge-fill",
    title: Locale.titles.clientContactManagement
  },
  ClientResponsibilityManagement: {
    path: "/clients/responsibilities",
    icon: "bounding-box",
    title: Locale.titles.clientResponsibilityManagement
  },
  TeamsManagement: {
    path: "/teams",
    icon: "diagram-3",
    iconActive: "diagram-3-fill",
    title: Locale.titles.teamsManagement
  },
  ClientCompanyManagement: {
    path: "/clients",
    icon: "building",
    iconActive: "building-fill",
    title: Locale.titles.clientManagement
  },
  EmployeeManagement: {
    path: "/employees",
    icon: "cup-hot",
    iconActive: "cup-hot-fill",
    title: Locale.titles.employeeManagement
  },
  TenantDomainManagement: {
    path: "/domains/tenant",
    icon: "router",
    iconActive: "router-fill",
    title: Locale.titles.tenantDomainManagement
  },
  DomainManagement: {
    path: "/domains",
    icon: "router",
    iconActive: "router-fill",
    title: Locale.titles.domainManagement
  },
  Logs: {
    path: "/logs",
    icon: "journal-x",
    title: Locale.titles.logs
  },
  MailLogs: {
    path: "/logs/mail",
    icon: "envelope",
    iconActive: "envelope-fill",
    title: Locale.titles.mailLog
  },
  ServiceManagement: {
    path: "/services",
    iconActive: "credit-card-2-front-fill",
    icon: "credit-card-2-front",
    title: Locale.titles.serviceManagement
  },
}

export const AnyRoute = "*";
