import React from "react";
import { IUser } from "../../types/ApiTypes";
import ModalForm from "../modal/ModalForm";
import ModalDialog from "../modal/ModalDialog";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import UserAbsenceList from "../user/vacations/UserAbsenceList";
import { useUser } from "../../state/api/user/useUser";
import useUserUtil from "../../hooks/useUserUtil";
import WithPermissions from "../permissions/WithPermissions";

export interface IManageEmployeeAbsenceButtonProps {
    user: IUser | null | undefined
}

export default function ManageEmployeeAbsenceButton({user}: IManageEmployeeAbsenceButtonProps) {

    const { user: currentUser } = useUser();
    
    const {
        getName
    } = useUserUtil();
    
    if (!user) return null;
    
    const isOwn = currentUser && currentUser._id === user._id;

    return (
        <WithPermissions permissions={[ isOwn ? "absence.own.update" : "absence.all.update" ]}>
            <ModalDialog
                button={<Button color="muted" text="Abwesenheiten verwalten" icon="pen" />}
                title={isOwn ? "Abwesenheiten verwalten" : `Abwesenheiten von ${getName(user)} verwalten`}
                sidebar={close => <Button onClick={async () => close()} text="Schließen" />}
            >
                {
                    close => (
                        <Flex fullWidth>
                            <UserAbsenceList user={user} />
                        </Flex>
                    )
                }
            </ModalDialog>
        </WithPermissions>
    )
}