import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { Sorted } from "../../../types/collection.types";
import { IMailfolder } from "../../../types/mailfolder.schema";

export const useMailfolders = (accountId: string) => {
  const { data, isLoading, mutate } = useTiggiQuery<Sorted<IMailfolder, string, Array<IMailfolder>>>(ApiRoutes.Mailfolders.All.getValue({
    param: RouteParam.OfficeAccountId,
    value: accountId
  }));

  return {
    mailfolders: data?.items ?? [],
    mailfoldersById: data?.byId ?? {} as {[key: string]: IMailfolder},
    mailfoldersByParentFolderId: data?.parentFolderId ?? {} as {[key in string]: IMailfolder[]},
    loadingMailfolders: isLoading,
    reloadMailfolders: mutate
  }
}