import React from "react";
import { salutationsCreate, salutationsCreateGlobal, salutationsUpdate, salutationsUpdateGlobal } from "../../api/Api";
import useSalutationUtil from "../../hooks/useSalutationUtil";
import { useSalutations } from "../../state/api/salutations/useSalutations";
import { TemplateElementType } from "../../types/globalMailTemplates.schema";
import { Gender, ISalutation, ISalutationDocument } from "../../types/salutation.schema";
import { TemplatePlaceholder } from "../../types/templatePlaceholder.schema";
import Button from "../buttons/Button";
import EditButton from "../buttons/EditButton";
import Flex from "../container/Flex";
import CheckBox from "../forms/CheckBox";
import FormikField from "../forms/FormikField";
import ModalForm from "../modal/ModalForm";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import TemplateElementEdit from "../templates/TemplateElementEdit";
import TemplatePreview from "../templates/TemplatePreview";
import Typography from "../text/Typography";

export interface IWithSalutationProps {
  isGlobal: boolean
}

export interface IUpdateSalutationDialogProps extends IWithSalutationProps {
  salutation?: ISalutation
}

export default function UpdateSalutationDialog(props: IUpdateSalutationDialogProps) {

  const {
    salutation,
    isGlobal
  } = props;

  const [tab, setTab] = React.useState<keyof ISalutation>(
    salutation?.useSameTemplateForAllGenders
      ? "templateForAllGenders"
      : Gender.Female
  );

  const {
    getUsedTemplateKeys,
    getTemplateKeyLabel
  } = useSalutationUtil();

  const { reloadSalutations } = useSalutations(isGlobal);

  const isReadOnly = salutation ? salutation.isGlobalTemplate !== isGlobal : false;

  const genderValuesForSalutation = Object.values(Gender).reduce((p, c) => {
    p[c] = salutation?.[c] ?? [];
    return p;
  }, {} as ISalutationDocument);

  return (
    <ModalForm
      title={salutation ? "Anrede bearbeiten" : `${isGlobal ? "Anredenvorlage" : "Anrede"} erstellen`}
      button={<EditButton readOnly={isReadOnly} isCreate={!salutation} />}
      initialValues={{
        ...genderValuesForSalutation,
        name: salutation?.name || "",
        isGlobalTemplate: salutation?.isGlobalTemplate ?? isGlobal,
        useSameTemplateForAllGenders: salutation?.useSameTemplateForAllGenders ?? true,
        templateForAllGenders: salutation?.templateForAllGenders ?? [],
        isGlobalDefault: salutation?.isGlobalDefault ?? false,
        baseTemplate: salutation?.baseTemplate ?? "",
        _id: salutation?._id ?? ""
      } as ISalutation}
      sidebar={formik => (
        <Flex fullWidth align="end">
          <Flex fullWidth>
            {
              getUsedTemplateKeys(formik.values.useSameTemplateForAllGenders).map(k => (
                <TemplatePreview
                  key={`template-preview-${k}`}
                  content={formik.values[k]}
                  label={getTemplateKeyLabel(k)}
                />
              ))
            }
          </Flex>
          <Button
            onClick={formik.submitForm}
            icon="save"
            color="success"
            text={
              salutation
                ? "Änderungen speichern"
                : "Anrede erstellen"
            }
          />
        </Flex>
      )}
      onSubmit={async (values) => {
        const res = await (
          salutation
            ? isGlobal ? salutationsUpdateGlobal(values) : salutationsUpdate(values)
            : isGlobal ? salutationsCreateGlobal(values) : salutationsCreate(values)
        );

        if (!res?.success) return false;

        await reloadSalutations();

        return true;
      }}
    >
      {
        formik => (
          <Flex fullWidth gap="3">
            <FormikField
              bold
              className="w-100"
              name="name"
              label="Name"
              readOnly={isReadOnly}
              placeholder="Formell, Informell, Sie-Form, Du-Form, etc."
            />
            {
              isGlobal && (
                <Flex>
                  <Flex gap="0">
                    <Typography color="primary" bold>Vorlage für alle Kanzleien</Typography>
                    <Typography color="primary" wrap size="11">Diese Anrede ist der Standard für Tenants, die ihre Anrede nicht angepasst haben.</Typography>
                  </Flex>
                  <CheckBox name="isGlobalDefault" label="Als globaler Standard verwenden" />
                </Flex>
              )
            }
            <Flex gap="2" fullWidth>
              <Flex row justify="between" fullWidth>
                <Typography bold color="primary">Anreden</Typography>
                <CheckBox
                  name="useSameTemplateForAllGenders"
                  label="Gleiche Vorlage für alle Geschlechter"
                  onChange={val => {
                    if (val) setTab("templateForAllGenders");
                    else setTab(Gender.Female);
                  }}
                />
              </Flex>
              <TemplateElementEdit
                readOnly={isReadOnly}
                label={
                  <TabSwitcher
                    tabQueryParamKey="gender"
                    size="small"
                    removeOnUnmount
                    tabs={
                      getUsedTemplateKeys(formik.values.useSameTemplateForAllGenders).map(k => ({
                        label: getTemplateKeyLabel(k),
                        data: k
                      }))
                    }
                    saveActiveTab={setTab as any}
                  />
                }
                name={tab}
                availablePlaceholders={[TemplatePlaceholder.UserFirstName, TemplatePlaceholder.UserLastName]}
                availableTypes={[TemplateElementType.Text, TemplateElementType.Placeholder]}
              />
            </Flex>
          </Flex>
        )
      }
    </ModalForm>
  )
}