import React from "react";
import Flex from "../../container/Flex";
import { ITicket } from "../../../types/ApiTypes";
import { useTicketMails } from "../../../state/api/tickets/useTicketMails";
import LoadingSpinner from "../../loader/LoadingSpinner";
import MailLogCard from "../../log/mail/MailLogCard";
import SidebarHeader from "../../menu/sidebar/SidebarHeader";
import { useTicketTasks } from "../../../state/api/tickets/useTicketTasks";
import TaskItem from "../../tasks/TaskItem";
import UpdateTaskButton from "../../tasks/UpdateTaskButton";

export default function TicketTasks({ ticket }: { ticket: ITicket }) {

  const {
    loadingTicketTasks,
    reloadTicketTasks,
    ticketTasks
  } = useTicketTasks(ticket._id);

  if (loadingTicketTasks) return <LoadingSpinner />

  return (
    <Flex>
      <Flex row wrap justify="between">
        <SidebarHeader text="Aufgaben und Termine" />
        <UpdateTaskButton ticket={ticket._id} />
      </Flex>
      {
        ticketTasks && ticketTasks.length
          ? ticketTasks.map(d => <TaskItem key={d._id} task={d} />)
          : <span>Keine Aufgaben</span>
      }
    </Flex>
  )
}