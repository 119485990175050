import { MailFolder } from "@microsoft/microsoft-graph-types";
import React from "react";
import Icon from "../../../icons/Icon";
import { TicketFolderName } from "../../../../types/mailfolder.schema";
import { AppRoutes } from "../../../../config/AppRoutes";

export default function OfficeFolderIcon({ folder }: { folder: MailFolder }) {
  const getIcon = () => {
    switch (folder.displayName) {
      case TicketFolderName:
      case TicketFolderName.toLowerCase():

        return AppRoutes.Tickets?.icon ?? "chat";

      case "Posteingang":
      case "Inbox":
        return "inbox";

      case "Gesendete Elemente":
      case "Sent Items":

        return "send";

      case "Outbox":
      case "Postausgang":

        return "stopwatch";

      case "Entwürfe":
      case "Drafts":

        return "file-earmark";

      case "Gelöschte Elemente":
      case "Deleted Items":

        return "trash";

      case "Archiv":
      case "Archive":

        return "archive";

      case "Junk-E-Mail":
      case "Junk Email":
      case "Spam":

        return "exclamation-triangle";

    }

    return "folder";
  }

  const icon = getIcon();

  if (!icon) return null;

  return <Icon icon={icon} color="primary" />
}