import React from "react";
import Button from "../buttons/Button";
import { Form, Formik, useFormikContext } from "formik";
import { FeedbackType, IFeedbackDocument } from "../../types/ApiTypes";
import { useAppSelector } from "../../hooks/reduxHooks";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import FieldWithLabel from "../forms/FormikField";
import useApi from "../../hooks/useApi";
import { feedbackAddNew } from "../../api/Api";
import ModalDialog from "../modal/ModalDialog";
import { useUser } from "../../state/api/user/useUser";

export default function FeedbackButton() {

    const callApi = useApi();

    const { user } = useUser();

    return (
        <ModalDialog
            button={
                <Icon 
                    tooltip="Feedback geben oder Bug melden"
                    size={25} 
                    hexColor="#B2C0D9"
                    icon="hand-thumbs-up-fill" 
                /> 
            }
            title="Feedback" 
        >
            {
                close => (
                    <Formik
                        initialValues={{
                            appliesTo: {
                                ticket: undefined,
                            },
                            comment: "",
                            type: FeedbackType.General,
                            givenBy: {
                                company: "",
                                firstName: user ? user.firstName : "",
                                lastName: user ? user.lastName : "",
                                mail: user ? user.mailAddress : "",
                                phone: user ? user.phoneNumber : "",
                                user: user ? user._id : undefined
                            },
                            ratings: {
                                overall: 0
                            }
                        } as IFeedbackDocument}
                        onSubmit={async (values) => {
                            const res = await callApi(feedbackAddNew(values), true);
                            if (!res || !res.success) return;
                            close();

                        }}
                    >
                        {
                            formik => (
                                <Form>
                                    <Flex gap={3}>
                                        <Flex>
                                            <span>Bewertung</span>
                                            <FeedbackStarRating name="ratings.overall" value={formik.values.ratings.overall} />
                                        </Flex>
                                        <Flex className="w-100">
                                            <span>Ihre Nachricht</span>
                                            <textarea style={{minHeight: "300px", resize: "none"}} onChange={(e) => formik.setFieldValue("comment", e.target.value)} className="form-control" placeholder="Ihre Nachricht" />
                                        </Flex>
                                        {
                                            !user && (
                                                <Flex>
                                                    <Flex row>
                                                        <FieldWithLabel name="givenBy.firstName" label="Vorname" />
                                                        <FieldWithLabel name="givenBy.lastName" label="Nachname" />
                                                    </Flex>
                                                    <FieldWithLabel name="givenBy.mail" label="E-Mail" />
                                                    <FieldWithLabel name="givenBy.phone" label="Telefon" />
                                                </Flex>
                                            )
                                        }
                                        <Button type="submit" text="Senden" />
                                    </Flex>
                                </Form>
                            )
                        }
                    </Formik>
                )
            }
        </ModalDialog>
    )
}

function FeedbackStarRating({name, value}: {name: string, value: number}) {

    const {
        setFieldValue
    } = useFormikContext();

    const props = {
        currentValue: value,
        saveValue: (val: number) => setFieldValue(name, val)
    }

    return (
        <Flex row>
            <FeedbackStar value={1} {...props} />
            <FeedbackStar value={2} {...props}  />
            <FeedbackStar value={3} {...props}  />
            <FeedbackStar value={4} {...props}  />
            <FeedbackStar value={5} {...props}  />
        </Flex>
    )
}

function FeedbackStar({value, currentValue, saveValue}: {value: number, currentValue: number, saveValue: (val: number) => void}) {
    const active = currentValue >= value;
    return (
        <Icon hexColor="#002E7F" size={36} onClick={() => saveValue(value)} icon={active ? "star-fill" : "star"} />
    )
}