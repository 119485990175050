import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import { useAppDispatch } from "../../hooks/reduxHooks";
import Page from "../container/Page";
import * as yup from "yup";
import { mail, min, required } from "../../hooks/useLocale";
import { isErrorApiResponse, tenantsAddNew, usersRequestLogIn } from "../../api/Api";
import FieldWithLabel from "../forms/FormikField";
import Button from "../../components/buttons/Button";
import { ITenantCreateRequest, IUserLogInRequest } from "../../api/ApiRequests";
import { AppRoutes } from "../../config/AppRoutes";
import useModal from "../../hooks/useModal";
import { ModalType } from "../../config/ModalTypes";
import useApi from "../../hooks/useApi";

export default function TenantRequestAccountForm() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const callApi = useApi();
    const showModal = useModal();

    return (
        <Page fullscreen logoAsBackground>
            <div className="position-relative w-100 h-100">
                <div className="position-absolute top-25 p-5">
                    <h1 className="display-1 fw-bold" style={{color: "var(--primaryAccent)"}}>Willkommen!</h1>
                    <h1>Ihr neues Ticketsystem wartet auf Sie!</h1>
                </div>
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <Formik 
                        initialValues={{
                            mailAddress: "",
                            onboardingIsManaged: false
                        } as ITenantCreateRequest }
                        validationSchema={yup.object().shape({
                            mailAddress: yup.string().email(mail()).required(required("E-Mail")),
                        })}
                        onSubmit={async (values, actions) => {
                            if (!values) return;

                            const res = await callApi(tenantsAddNew(values));

                            console.log(res);
                            
                            if (!res) return;

                            showModal({
                                text: "Erfolg! Sie erhalten in Kürze eine E-Mail mit dem Link. Wir freuen uns, Sie schon bald begrüßen zu dürfen.",
                                type: ModalType.Success
                            });

                            navigate(AppRoutes.Home.path);
                        }}
                        >
                        {
                            formik => {
                                return (
                                    
                                    <Form className="card p-3 w-50">
                                        <span className="mb-3">Bitte geben Sie Ihre E-Mail-Addresse ein, damit wir Ihnen einen Link zum Anmelden zusenden können.</span>
                                        <FieldWithLabel name="mailAddress" bold label="E-Mail" placeholder="E-Mail" />
                                        <Button type="submit" loading={formik.isSubmitting} loadingText="Lädt..." text="Zugriff anfragen" />
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </div>
            </div>
        </Page>
    )
}