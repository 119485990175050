import React from "react";
import { ticketEscalationWorkflowCreate, ticketEscalationWorkflowUpdate } from "../../api/Api";
import useApi from "../../hooks/useApi";
import useTicketEscalation from "../../hooks/useTicketEscalation";
import { useTicketEscalationWorkflow } from "../../state/api/escalation/useTicketEscalationWorkflows";
import { useMailTemplates } from "../../state/api/templates/useMailTemplates";
import { ITicketEscalationWorkflow, ITicketEscalationworkflowDocument, TicketEscalationAction, TicketEscalationTrigger } from "../../types/ApiTypes";
import { formatDuration } from "../../util/formatter";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Select from "../comboBox/Select";
import Flex from "../container/Flex";
import CheckBox from "../forms/CheckBox";
import FieldWithLabel from "../forms/FormikField";
import LoadingSpinner from "../loader/LoadingSpinner";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";
import TicketEscalationActionChip from "./TicketEscalationActionChip";
import { MailTemplate } from "../../types/globalMailTemplates.schema";
import { useTemplateUtil } from "../../hooks/useTemplateUtil";

export interface ITicketEscalationWorkflowFormProps {
    workflowItem?: ITicketEscalationWorkflow,
    lastItem?: ITicketEscalationWorkflow
}

export default function TicketEscalationWorkflowForm({workflowItem, lastItem}: ITicketEscalationWorkflowFormProps) {

    const { getStringified } = useTemplateUtil();

    const { loadingMailTemplates, mailTemplates, reloadMailTemplates } = useMailTemplates();
    const { reloadTicketEscalationWorkflow } = useTicketEscalationWorkflow();

    const { getNameForTrigger } = useTicketEscalation();

    const callApi = useApi();
    
    console.log(workflowItem);

    return (
        <WithPermissions permissions={["tenant.own.update" ]}>
            <ModalForm 
                button={<Button variant={workflowItem ? "text" : "solid"} text={workflowItem ? "" : "Neue Aktion"} icon={workflowItem ? "pen" : "plus"} />}
                title={workflowItem ? "Event bearbeiten" : "Neues Eskalationsevent"}
                enableReinitialize
                initialValues={{
                    threshhold: 0,
                    trigger: TicketEscalationTrigger.MinutesOpen,
                    actions: workflowItem ? workflowItem.actions : [],
                    marksTicketAsEscalated: workflowItem ? workflowItem.marksTicketAsEscalated : false,
                    mailTemplate: workflowItem ? workflowItem.mailTemplate : "",
                } as ITicketEscalationworkflowDocument}
                onSubmit={async (values) => {
                    console.log(values);
                    const res = workflowItem ? await callApi(ticketEscalationWorkflowUpdate(values, workflowItem._id)) : await callApi(ticketEscalationWorkflowCreate(values));
                    if (!res) return false;
                    await reloadTicketEscalationWorkflow();
                    return true;
                }}
                sidebar={formik => (
                    <Flex>
                        <CheckBox name="marksTicketAsEscalated" label="Markiert Ticket bei Auslösung als Eskaliert" />
                        <Button 
                            type="submit" 
                            text={workflowItem ? "Aktualisieren" : "Speichern"} 
                            icon="save" 
                            loading={formik.isSubmitting} 
                        />
                    </Flex>
                )}

            >
                {
                    formik => {

                        const getMailTemplates = () => {
                            if (isActionSelected(TicketEscalationAction.MailToClient)) return mailTemplates?.filter(m => m.templateType === MailTemplate.TicketEscalationMailToClient) ?? [];
                            if (isActionSelected(TicketEscalationAction.MailToSupervisor)) return mailTemplates?.filter(m => m.templateType === MailTemplate.TicketEscalationMailToSupervisor) ?? [];
                            return [];
                        }

                        const isActionSelected = (action: TicketEscalationAction) => !!formik.values.actions.find(a => a.action === action);
                        const addAction = (action: TicketEscalationAction) => {
                            if (isActionSelected(action)) return;
                            formik.setFieldValue("actions", [...formik.values.actions, {action}]);
                        }

                        const removeAction = (action: TicketEscalationAction) => {
                            if (!isActionSelected(action)) return;
                            formik.setFieldValue("actions", formik.values.actions.filter(a => a.action !== action));
                        }

                        const availableMailTemplates = getMailTemplates();

                        return (
                            <Flex className="w-100" gap={3}>
                                <Select
                                    bold
                                    value={formik.values.trigger}
                                    onChange={e => formik.setFieldValue("trigger", e as TicketEscalationTrigger)}
                                    values={[
                                        { 
                                            label: getNameForTrigger(TicketEscalationTrigger.MinutesOpen), 
                                            data: TicketEscalationTrigger.MinutesOpen 
                                        },
                                        { 
                                            label: getNameForTrigger(TicketEscalationTrigger.MinutesWithoutAssigneeMessage), 
                                            data: TicketEscalationTrigger.MinutesWithoutAssigneeMessage 
                                        },
                                        { 
                                            label: getNameForTrigger(TicketEscalationTrigger.MinutesWithoutClientResponse), 
                                            data: TicketEscalationTrigger.MinutesWithoutClientResponse 
                                        },
                                    ]}
                                    label="Trigger"
                                />
                                <Flex fullWidth justify="between">
                                    <FieldWithLabel 
                                        bold
                                        className="w-100" 
                                        name="threshhold" 
                                        label="Auslöse-Schwelle" 
                                        type="number" 
                                        min="1"
                                        max="43200"
                                        placeholder="Schwellwert der Ausführung"
                                    />
                                    <Typography bold color="primary" truncate={false}>= {formatDuration(formik.values.threshhold)}</Typography>
                                </Flex>
                                <Flex gap={1}>
                                    <strong>Aktionen</strong>
                                    <Flex row wrap>
                                        <TicketEscalationActionChip 
                                            action={TicketEscalationAction.EscalateTicket}
                                            isActive={isActionSelected(TicketEscalationAction.EscalateTicket)}
                                            readOnly={false}
                                            canBeSelected
                                            saveAction={addAction}
                                            deleteAction={removeAction}
                                        />
                                        <TicketEscalationActionChip 
                                            action={TicketEscalationAction.MailToSupervisor}
                                            canBeSelected={!isActionSelected(TicketEscalationAction.MailToClient)}
                                            isActive={isActionSelected(TicketEscalationAction.MailToSupervisor)}
                                            readOnly={false}
                                            saveAction={addAction}
                                            deleteAction={removeAction}
                                        />
                                        <TicketEscalationActionChip 
                                            canBeSelected={!isActionSelected(TicketEscalationAction.MailToSupervisor)}
                                            action={TicketEscalationAction.MailToClient}
                                            isActive={isActionSelected(TicketEscalationAction.MailToClient)}
                                            readOnly={false}
                                            saveAction={addAction}
                                            deleteAction={removeAction}
                                        />
                                        <TicketEscalationActionChip 
                                            canBeSelected
                                            action={TicketEscalationAction.CloseTicket}
                                            isActive={isActionSelected(TicketEscalationAction.CloseTicket)}
                                            readOnly={false}
                                            saveAction={addAction}
                                            deleteAction={removeAction}
                                        />
                                    </Flex>
                                </Flex>
                                {
                                    (isActionSelected(TicketEscalationAction.MailToClient) || isActionSelected(TicketEscalationAction.MailToSupervisor)) && (
                                        <Flex>
                                            <Typography bold>Mailvorlagen</Typography>
                                            {
                                                loadingMailTemplates 
                                                ? <LoadingSpinner />
                                                : (
                                                    availableMailTemplates && availableMailTemplates.length
                                                    ? <SearchableComboBox
                                                        label="Mailvorlage für die Benachrichtigung"
                                                        itemToId={item => item._id}
                                                        values={availableMailTemplates}
                                                        itemToString={item => getStringified(item.subject)}
                                                        onItemClick={item => formik.setFieldValue("mailTemplate", item?._id)}
                                                        value={formik.values.mailTemplate}
                                                    />
                                                    : <span>Keine angepasste Mailvorlagen für diesen Eskalationstyp hinterlegt. Es wird der globale Standard genutzt.</span>
                                                )
                                            }
                                        </Flex>

                                    )
                                }
                            </Flex>
                        )
                    }
                }
            </ModalForm>
        </WithPermissions>
    )
}