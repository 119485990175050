
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IUserStatisticsForTeam } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAnyTeamUserStatistics = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<IUserStatisticsForTeam>(ApiRoutes.Statistics.AnyTeam.getValue({ 
        param: RouteParam.TeamId, 
        value: id
    }), { refetchOnWindowFocus: false });

    return {
        teamUserStatistics: data,
        loadingTeamUserStatistics: isLoading,
        reloadTeamUserStatistics: mutate
    }
}