import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import { officeAccountsUpdateMail } from "../../../../api/Api";
import useInboxPermissions from "../../../../hooks/useInboxPermissions";
import Icon from "../../../icons/Icon";
import WithPermissions from "../../../permissions/WithPermissions";
import { IOfficeAccount } from "../../../../types/officeAccount.types";
import { useMailfolders } from "../../../../state/api/mailfolders/useMailfolders";

export interface IMarkMailAsReadButtonProps {
  mail: Message,
  account: IOfficeAccount,
  folderId?: string,
  isRead: boolean,
  saveIsRead: (val: boolean) => void
}

export default function MarkMailAsReadButton({ mail, account, saveIsRead, folderId, isRead }: IMarkMailAsReadButtonProps) {
  
  const [loading, setLoading] = React.useState<boolean>(false);
  
  const {
    getAccountPermissions
  } = useInboxPermissions();

  const markAsRead = async () => {
    setLoading(true);
    const res = await officeAccountsUpdateMail({ id: account._id, messageId: mail.id!, isRead: !isRead });

    if (res?.success) saveIsRead(!isRead);

    setLoading(false);
  }

  const permissions = getAccountPermissions("update", account);

  if (!mail || !mail.id) return null;

  return (
    <WithPermissions permissions={permissions}>
      <Icon icon={isRead ? "envelope-open" : "envelope"} size={isRead ? 14 : undefined} onClick={markAsRead} loading={loading} />
    </WithPermissions>
  )
}