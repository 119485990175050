import { IMongooseDocument } from "./ApiTypes";

export enum TaskAttendance {
  WillAttend = "willAttend",
  WillNotAttend = "willNotAttend",
  Maybe = "maybe",
  Open = "open"
}

export interface ITaskReminder {
  date?: Date,
  minutesBefore: number
}

export interface ITaskAssignment {
  user?: string,
  mailAddress?: string,
  attendance?: TaskAttendance
}

export enum TaskRecurrence {
  None = "none",
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Yearly = "yearly"
}

export interface ITaskRecurrenceOptions {
  repeatEvery: number,
  dayOfMonth?: number,
  weekDays?: number[],
  month?: number,
  end?: Date
}

export interface ITaskDocument {
  subject: string,
  description?: string,
  start: Date,
  end?: Date,
  recurrenceType: TaskRecurrence,
  recurrenceOptions: ITaskRecurrenceOptions,
  ticket: string,
  assignedTo: ITaskAssignment[],
  reminders: ITaskReminder[]
}

export interface ITask extends ITaskDocument, IMongooseDocument {
  createdBy: string,
  completed: boolean,
  baseTask?: string,
  lastStatusUpdateOn?: Date,
  lastStatusUpdateBy?: string
}

export const MinTaskInterval = 15;
