import React from "react";
import Flex from "../container/Flex";
import { useTicketEscalationWorkflow } from "../../state/api/escalation/useTicketEscalationWorkflows";
import LoadingSpinner from "../loader/LoadingSpinner";
import TicketEscalationWorkflowForm from "./TicketEscalationWorkflowForm";
import TicketEscalationWorkflowItem from "./TicketEscalationWorkflowItem";
import WithPermissions from "../permissions/WithPermissions";

export default function TicketEscalationList() {

    const { loadingTicketEscalationWorkflow, reloadTicketEscalationWorkflow, ticketEscalationWorkflow } = useTicketEscalationWorkflow();

    if (loadingTicketEscalationWorkflow) return <LoadingSpinner />

    return (
        <WithPermissions permissions={ [ "tenant.own.update" ]}>
            <Flex className="w-100">
                <Flex row justify="between" className="w-100">
                    <h6 className="fw-bold">Eskalations-Workflow</h6>
                    <TicketEscalationWorkflowForm lastItem={ticketEscalationWorkflow && ticketEscalationWorkflow.length ? ticketEscalationWorkflow[ticketEscalationWorkflow.length - 1] : undefined}/>
                </Flex>
                {
                    loadingTicketEscalationWorkflow 
                    ? <LoadingSpinner />
                    : (
                        <Flex className="w-100">
                            {
                                ticketEscalationWorkflow && ticketEscalationWorkflow.length
                                ? ticketEscalationWorkflow.map((workflow, index) => <TicketEscalationWorkflowItem key={workflow._id} workflow={workflow} lastItem={index > 0 ? ticketEscalationWorkflow[index - 1] : undefined} />)
                                : <p className="text-muted">Keine Eskalationen vorhanden</p>
                            }
                        </Flex>
                    )
                }
            </Flex>
        </WithPermissions>
    )
}