import React from "react";
import { generateClassName } from "../../hooks/useAttributes";
import useClientUtil from "../../hooks/useClientUtil";
import { useClients } from "../../state/api/clients/useClients";
import { getTextColorFromBackground } from "../../util/util";
import Flex from "../container/Flex";
import Banner from "../pills/Banner";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import { DbId } from "../../types/ApiTypes";
import { IClient } from "../../types/client.schema";
import { getModel } from "../../util/mongoUtil";
import { useNavigate } from "react-router-dom";
import { AppRouteParams, AppRoutes, getLink } from "../../config/AppRoutes";

export interface IClientTypeBannerProps {
  client?: DbId<IClient>,
  showClientName?: boolean,
  hideClientNumber?: boolean,
  textOnEmpty?: string,
  isLink?: boolean,
  alwaysShowClientType?: boolean,
  className?: string,
  onClick?: () => void
}

export default function ClientTypeBanner({ client, isLink, hideClientNumber, alwaysShowClientType, showClientName, onClick, textOnEmpty, className }: IClientTypeBannerProps) {
  const {
    getTypeNameOfClient,
    getClientTypeColor,
    getClientName,
    getClientIcon
  } = useClientUtil();

  const {
    clientsById,
    loadingClients
  } = useClients();

  const realClient = getModel<IClient>(client, clientsById);
  const navigate = useNavigate();

  if (!realClient) {
    if (textOnEmpty) return <Banner text={textOnEmpty} color="bright" />
    if (!showClientName) return null;
    return <span>-</span>
  }

  const clientName = getClientName(realClient);
  const clientType = getTypeNameOfClient(realClient);

  const pillClass = generateClassName(className, "gap-2");

  if (!loadingClients && !realClient) return null;
  if (showClientName && !clientName) return null;

  const color = getClientTypeColor(realClient);

  return (
    <Pill
      icon={getClientIcon(realClient)}
      className={pillClass}
      color={color}
      withDivider
      loading={loadingClients}
      onClick={onClick || isLink ? () => {
        if (onClick) onClick();
        else {
          const link = getLink(AppRouteParams.Client, AppRoutes.Client, realClient._id);
          navigate(link);
        }
      } : undefined}
    >
      {
        realClient && (
          <Flex gap="0" fullWidth>
            <Typography basedOnThisBackground={color} truncate>{showClientName ? clientName ?? "-" : clientType}</Typography>
            {realClient.number && !hideClientNumber && <Typography bold size="11" noLinePadding basedOnThisBackground={color}>{realClient.number}</Typography>}
          </Flex>
        )
      }
    </Pill>
  )
}