
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { ITicket, ITicketEscalationLog } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useTicketEscalationLog = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ITicketEscalationLog>>(ApiRoutes.Tickets.EscalationWorkflow.Log.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketEscalationLog: data,
        loadingTicketEscalationLog: isLoading,
        reloadTicketEscalationLog: mutate
    }
} 