import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../hooks/useTiggiQuery";
import { ITask } from "../../../types/task.schema";

export const useUpcomingTasks = (opts?: TiggiQueryOpts) => {
  const { data, isLoading, mutate } = useTiggiQuery<ITask[]>(ApiRoutes.Tasks.Upcoming, opts);

  return {
    upcomingTasks: data,
    loadingUpcomingTasks: isLoading,
    reloadUpcomingTasks: mutate
  }
} 