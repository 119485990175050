import React from "react";
import { useMailTemplates } from "../../state/api/templates/useMailTemplates";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Typography from "../text/Typography";
import MailTemplateListItem from "./MailTemplatesListItem";
import TenantMailTemplatesForTemplateType from "./TenantMailTemplatesForTemplateType";
import { MailTemplate } from "../../types/globalMailTemplates.schema";

export default function TenantMailTemplatesList() {

    const {
        loadingMailTemplates,
        mailTemplates
    } = useMailTemplates();

    const otherMailTemplates = mailTemplates?.filter(m => !m.baseTemplate && !m.templateType) ?? [];

    return (
        <WithPermissions permissions={["templates.mails.read"]}>
            <Flex fullWidth gap={2}>
                <Typography color="primary" size="18" bold >Basisfunktionen</Typography>
                {
                    Object.values(MailTemplate).map((t, index) => <TenantMailTemplatesForTemplateType key={t} type={t} isInitiallyExpanded={index === 0} />)
                }
            </Flex>
            <Flex fullWidth gap={2} className="pb-5">
                <Typography color="primary" size="18" bold >Sonstige Vorlagen</Typography>
                {
                    loadingMailTemplates 
                    ? <LoadingSpinner />
                    : (
                        otherMailTemplates.length
                        ? otherMailTemplates.map(t => <MailTemplateListItem key={t._id} template={t} />)
                        : <Typography>Keine sonstigen Vorlagen</Typography>
                    )
                }
            </Flex>
        </WithPermissions>
    )
}