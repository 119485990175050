
import { ICdpAgreement } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useCdp = () => {
    const { data, isLoading, mutate } = useTiggiQuery<ICdpAgreement>(ApiRoutes.ContractForDataPrivacy.Current);

    return {
        cdp: data,
        loadingCdp: isLoading,
        reloadCdp: mutate
    }
}