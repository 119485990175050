import React, { ReactNode, ReactElement } from "react";
import { generateClassName, generateStyle, generateStyleWithBase } from "../../hooks/useAttributes";
import IElementProps from "../../types/element.types";
import "./Card.css";
import Flex from "../container/Flex";
import { AppColor } from "../../app/AppStyles";
import { getTextColorFromBackground, hexWithOpacity } from "../../util/util";

export interface ICardProps extends IElementProps {
  noPadding?: boolean,
  noHeaderPadding?: boolean,
  header?: ReactElement | ReactNode | undefined,
  padding?: number | string,
  uniColor?: boolean,
  wrapperClass?: string,
  fitContent?: boolean,
  color?: AppColor | string,
  strongColor?: boolean,
  disableHoverStyles?: boolean,
  expanded?: boolean
}

export default function Card({ wrapperClass, uniColor, disableHoverStyles, style, noHeaderPadding, onMouseEnter, onMouseLeave, fitContent, onMouseOver, strongColor = false, color, expanded = true, children, onClick, noPadding, header }: ICardProps) {
  const actualClassName = generateClassName(wrapperClass, "card-container", {
    value: fitContent,
    onTrue: "card-fit-content",
    standard: wrapperClass && wrapperClass.includes("w-") ? undefined : "w-100"
  }, {
    value: !!onClick,
    onTrue: "card-clickable"
  }, {
    value: !disableHoverStyles && !!onClick,
    onTrue: "card-clickable-hover-styled"
  });

  const contentClass = generateClassName("card-content w-100 overflow-hidden", {
    value: noPadding,
    onTrue: "p-0",
    standard: "p-3"
  });

  const getColor = () => {
    if (!color) return strongColor ? "background" : "backgroundLighter";
    if (color === "bright") return "#f2f5f9";
    return color;
  }

  const cardColor = getColor();

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      className={actualClassName}
      style={generateStyleWithBase(style, [{
        name: "backgroundColor",
        value: "white"
      }])}
      onClick={onClick ? () => onClick() : undefined}
    >
      <div
        className="card-content-wrapper h-100 w-100"
        style={generateStyle({
          name: "backgroundColor",
          value: hexWithOpacity(cardColor, color && !uniColor ? (strongColor ? 0.8 : 0.35) : 1, true)
        })}
      >

        {
          header && (
            <div
              className={generateClassName("card-header", { value: noHeaderPadding, standard: "pe-3 ps-3 pt-2 pb-2"})}
              style={generateStyle({
                name: "backgroundColor",
                value: hexWithOpacity(cardColor, uniColor ? 1 : (color ? (strongColor ? 1 : 0.65) : 0.3), true)
              }, {
                name: "color",
                value: getTextColorFromBackground(cardColor),
                important: true
              })}
            >
              {header}
            </div>
          )
        }
        {
          expanded && children && (
            <Flex
              className={contentClass}
            >
              {children}
            </Flex>
          )
        }
      </div>
    </div>
  )
}
