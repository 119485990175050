import { Form, Formik } from "formik";
import React from "react";
import { IOpeningHours, ITenantOpeningHoursDocument } from "../../../types/ApiTypes";
import DayOpeningHoursField from "./DayOpeningHoursField";
import Button from "../../buttons/Button";
import Flex from "../../container/Flex";
import CheckBox from "../../forms/CheckBox";
import useApi from "../../../hooks/useApi";
import { openingHoursUpdate } from "../../../api/Api";
import { useOpeningHours } from "../../../state/api/openingHours/useOpeningHours";
import FieldWithLabel from "../../forms/FormikField";
import LoadingSpinner from "../../loader/LoadingSpinner";
import WithPermissions from "../../permissions/WithPermissions";

export default function OpeningHoursUpdateForm() {

  const { loadingOpeningHours, openingHours } = useOpeningHours();

  const callApi = useApi();

  if (loadingOpeningHours) return <LoadingSpinner />

  return (
    <WithPermissions permissions={["tenant.own.update"]}>
      {
        loadingOpeningHours
          ? <LoadingSpinner />
          : (
            <Formik
              enableReinitialize
              initialValues={{
                monday: openingHours?.monday || [],
                tuesday: openingHours?.tuesday || [],
                wednesday: openingHours?.wednesday || [],
                thursday: openingHours?.thursday || [],
                friday: openingHours?.friday || [],
                saturday: openingHours?.saturday || [],
                sunday: openingHours?.sunday || [],
                onVacation: openingHours?.onVacation || false,
                onVacationUntil: openingHours?.onVacationUntil || new Date(),
                sendOnVacationMail: openingHours?.sendOnVacationMail || false
              } as ITenantOpeningHoursDocument}
              onSubmit={async (values) => {
                const res = await callApi(openingHoursUpdate(values));
                if (!res || !res.success) return;
              }}
            >
              {
                formik => {
                  const applyForAll = (value: IOpeningHours[], workdaysOnly: boolean) => {
                    formik.setFieldValue("monday", value);
                    formik.setFieldValue("tuesday", value);
                    formik.setFieldValue("wednesday", value);
                    formik.setFieldValue("thursday", value);
                    formik.setFieldValue("friday", value);

                    if (workdaysOnly) return;

                    formik.setFieldValue("saturday", value);
                    formik.setFieldValue("sunday", value);
                  }

                  return (
                    <Form className="w-100">
                      <Flex>
                        <h6 className="fw-bold">Öffnungszeiten</h6>
                        <DayOpeningHoursField
                          applyToAll={applyForAll}
                          fieldName="monday"
                          label="Montag"
                          value={formik.values.monday}
                        />
                        <DayOpeningHoursField
                          applyToAll={applyForAll}
                          fieldName="tuesday"
                          label="Dienstag"
                          value={formik.values.tuesday}
                        />
                        <DayOpeningHoursField
                          applyToAll={applyForAll}
                          fieldName="wednesday"
                          label="Mittwoch"
                          value={formik.values.wednesday}
                        />
                        <DayOpeningHoursField
                          applyToAll={applyForAll}
                          fieldName="thursday"
                          label="Donnerstag"
                          value={formik.values.thursday}
                        />
                        <DayOpeningHoursField
                          applyToAll={applyForAll}
                          fieldName="friday"
                          label="Freitag"
                          value={formik.values.friday}
                        />
                        <DayOpeningHoursField
                          applyToAll={applyForAll}
                          fieldName="saturday"
                          label="Samstag"
                          value={formik.values.saturday}
                        />
                        <DayOpeningHoursField
                          applyToAll={applyForAll}
                          fieldName="sunday"
                          label="Sonntag"
                          value={formik.values.sunday}
                        />
                        <Flex>
                          <strong>Betriebsferien</strong>
                          <CheckBox name="onVacation" label="Urlaubsmodus aktivieren" />
                          {
                            formik.values.onVacation && (
                              <Flex>
                                <FieldWithLabel name="onVacationUntil" label="Bis zum" type="date" />
                                <CheckBox name="sendOnVacationMail" label="Out-Of-Office-E-Mail an Kunden senden (bei Ticketeingang)" />
                              </Flex>
                            )
                          }
                        </Flex>
                        <Button type="submit" text="Öffnungszeiten speichern" icon="save" loading={formik.isSubmitting} />
                      </Flex>
                    </Form>
                  )
                }
              }
            </Formik>
          )
      }
    </WithPermissions>
  )
}