import React from "react";
import { generateStyle } from "../../../hooks/useAttributes";
import { AppColor } from "../../../app/AppStyles";
import { hexWithOpacity } from "../../../util/util";

export interface IDividerProps {
    height?: number | `${number}`,
    width?: number | `${number}`,
    color?: AppColor,
    opacity?: number 
}

export default function Divider({height = 2, width, color = "bright", opacity = 1}: IDividerProps) {

    const style = generateStyle({
        name: "height",
        value: height !== undefined ? height : "100%",
        unit: height !== undefined ? "px" : undefined,
    }, {
        name: "width",
        value: width !== undefined ? width : "100%",
        unit: width !== undefined ? "px" : undefined,
    }, {
        name: "backgroundColor",
        value: hexWithOpacity(color, opacity, true)
    });

    return <div style={style} />
}