import { useRoles } from "../state/api/roles/useRoles";
import { useCurrentTenantRole } from "../state/api/user/useCurrentTenantRole";
import { UserRole } from "../types/ApiTypes";
import useAnyRoleUtil from "./useAnyRoleUtil";

export default function useRoleUtil() {
    const { role } = useCurrentTenantRole();
    const { roles } = useRoles();

    const {
        convertToUserRole,
        getRoleId,
        getFilteredRoles,
        isRole
    } = useAnyRoleUtil();

    return {
        isRole: (requiredRole?: UserRole): boolean => isRole(role, requiredRole),
        getRoleId: (r: UserRole) => getRoleId(r, roles),
        convertToUserRole,
        getRolesExcept: (rolesToExcept: Array<UserRole>) => getFilteredRoles(roles, r => !rolesToExcept.find(x => x != r.displayName))
    }
}