import { ApiRoutes } from "../../../api/ApiRoutes";
import useTiggiQuery, { TiggiQueryOpts } from "../../../hooks/useTiggiQuery";
import { Sorted } from "../../../types/collection.types";
import { ITask } from "../../../types/task.schema";

export const useTasks = (opts?: TiggiQueryOpts) => {
  const { data, isLoading, mutate } = useTiggiQuery<Sorted<ITask, "string", Array<ITask>>>(ApiRoutes.Tasks.All, opts);

  return {
    tasks: data?.items ?? [],
    tasksByDate: data?.start ?? {},
    tasksById: data?.byId ?? {},
    loadingTasks: isLoading,
    reloadTasks: mutate
  }
} 