import { useOpeningHours } from "../state/api/openingHours/useOpeningHours";
import { IWeekOpeningHours } from "../types/ApiTypes";

export default function useOpeningHoursUtil() {
  const {
    openingHours,
    loadingOpeningHours
  } = useOpeningHours();

  const getOpeningHoursForDay = (key: keyof IWeekOpeningHours): Set<number> => {
    const result = new Set<number>();
    if (!openingHours) return result;
    if (!openingHours[key]) return result;

    const items = openingHours[key];

    for (const item of items) {
      for (let i = item.fromHour; i < item.toHour; i++) {
        result.add(i);
      }
    }

    return result;
  }

  const getOpenHoursForWeek = () => {
    const result: { [key: number]: Set<number> } = {};

    if (!openingHours) return result;

    result[0] = getOpeningHoursForDay("sunday");
    result[1] = getOpeningHoursForDay("monday");
    result[2] = getOpeningHoursForDay("tuesday");
    result[3] = getOpeningHoursForDay("wednesday");
    result[4] = getOpeningHoursForDay("thursday");
    result[5] = getOpeningHoursForDay("friday");
    result[6] = getOpeningHoursForDay("saturday");
    result[7] = result[0];

    return result;
  }

  const weekOpeningHours = getOpenHoursForWeek();

  return {
    weekOpeningHours,
    loadingOpeningHours
  }
}