import React from "react";
import Flex from "../../components/container/Flex";
import PermissionManagement from "../../components/permissions/PermissionManagement";
import Page from "../../components/container/Page";
import Typography from "../../components/text/Typography";
import UpdateRoleForm from "../../components/permissions/UpdateRoleForm";

export default function RoleManagement() {
    return (
        <Page header="Rollenverwaltung" actionButtons={[<UpdateRoleForm />]}>
            <PermissionManagement />
        </Page>
    )
}