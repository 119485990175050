import { Field } from "formik";
import React from "react";
import { plansCreate } from "../../api/Api";
import { IPlanCreateRequest } from "../../api/requests/subscriptions/subscriptionRequests";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import useBillingInterval from "../../hooks/useBillingInterval";
import { Locale } from "../../locale/Locale";
import { useServices } from "../../state/api/subscriptions/useServices";
import { useUser } from "../../state/api/user/useUser";
import { IService } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import FieldWithLabel from "../forms/FormikField";
import ModalForm from "../modal/ModalForm";
import PlanFeaturesArray from "./PlanFeaturesArray";

export default function CreatePlanButton({service}: {service: IService}) {

    const {
        user
    } = useUser();

    const dispatch = useAppDispatch();
    const callApi = useApi();

    const { reloadServices } = useServices();

    const {
        billingIntervals
    } = useBillingInterval();

    if (!user || !user.isSuperAdmin) return null;
    
    return (
        <ModalForm 
            title={Locale.pages.services.addPlan} 
            button={
                <Button variant="subtle" text={Locale.pages.services.addPlan} icon="plus" color="success" />
            }
            initialValues={{
                billingInterval: "day",
                name: "",
                description: "",
                price: 100,
                serviceId: service._id,
                features: []
            } as IPlanCreateRequest}
            onSubmit={async (values) => {
                const res = await callApi(plansCreate(values));
                if (!res) return false;
                await reloadServices();
                return true;
            }}
            sidebar={formik => <Button type="submit" text="Plan hinzufügen" icon="plus" loading={formik.isSubmitting} />}
        >
            {
                (formik) => (
                    <Flex className="w-100">
                        <strong>Metadaten</strong>
                        <FieldWithLabel name="name" noMargin label="Name des Plans" placeholder="Premium, Basis, Eigene Domain, 100 Tickets pro Tag..." />
                        <FieldWithLabel name="description" noMargin label="Beschreibung" placeholder="Zugriff auf das Ticketsystem, erweiterte Features für professionelle Anwender..." />
                        <SearchableComboBox 
                            label="Zahlungsintervall"
                            values={billingIntervals}
                            itemToId={v => v.value}
                            itemToString={v => v.key}
                            onItemClick={v => formik.setFieldValue("billingInterval", v?.value)}
                        />
                        <div className="d-flex flex-column gap-1">
                            <label htmlFor="plan-price-field">Preis in Cent</label>
                            <Field id="plan-price-field" name="price" type="number" min="100" step="1" className="form-control" placeholder="0" />
                        </div>
                        <PlanFeaturesArray formik={formik} />
                    </Flex>
                )
            }
        </ModalForm>
    )
}