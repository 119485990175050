import { Message } from "@microsoft/microsoft-graph-types";
import DOMPurify from "dompurify";
import React from "react";
import { useMailAttachmentPreview } from "../../../state/api/office/useOfficeMailAttachmentPreview";
import { formatDateTiggiStyle, formatOfficeMailAddress } from "../../../util/formatter";
import Button from "../../buttons/Button";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import LoadingSpinner from "../../loader/LoadingSpinner";
import SidebarDivider from "../../menu/sidebar/Divider";
import Typography from "../../text/Typography";
import WithLabel from "../../text/WithLabel";
import "./OfficeInboxMessageView.css";
import { IOfficeAccount } from "../../../types/officeAccount.types";
import ConvertMailToTicketButton from "./mailItem/ConvertMailToTicketButton";
import AddMailAsMessageToTicketButton from "./mailItem/AddMailToTicketButton";
import DownloadAllAttachmentsButton from "./mailItem/DownloadAllAttachmentsButton";
import MailAttachment from "./mailItem/DownloadableAttachment";
import MailItemDebugInfoModal from "./mailItem/MailItemDebugInfoModal";

export interface IOfficeInboxMessageViewProps {
  item: Message,
  folderId?: string,
  respond: (all?: boolean) => void,
  closeMessage: () => void,
  account: IOfficeAccount
}

export default function OfficeInboxMessageView({ item, account, folderId, closeMessage, respond }: IOfficeInboxMessageViewProps) {

  const {
    loadingMailAttachmentPreview,
    mailAttachmentPreview
  } = useMailAttachmentPreview(account, item.id!);

  return (
    <Flex className="w-100 h-100" style={{ backgroundColor: "var(--grey)" }}>
      <Flex className="w-100 h-100" gap={3}>
        <Flex row wrap>
          {
            item.webLink && (
              <Button icon="chevron-right" iconPosition="end" variant="outline" onClick={async () => window.open((item.webLink) as string, "_blank")} text="In Outlook öffnen" />
            )
          }
          <ConvertMailToTicketButton account={account} mail={item} mailId={item.id} folderId={folderId} />
          <AddMailAsMessageToTicketButton account={account} mailId={item.id} />
        </Flex>
        <Flex row align="start" justify="between">
          <Typography bold maxWidth={500} truncate={false} wrap size={20} color="primary">{item.subject || "Kein Betreff"}</Typography>
          <Flex row justify="end" wrap >
            <span className="text-nowrap">{formatDateTiggiStyle(item.sentDateTime)}</span>
            <Flex row>
              <Icon size={24} icon={"reply"} onClick={respond} />
              {
                item && ((item.toRecipients && item.toRecipients.length > 1) || (item.ccRecipients && !!item.ccRecipients.length)) && <Icon size={24} icon={"reply-all"} onClick={() => respond(true)} />
              }
            </Flex>

          </Flex>
        </Flex>
        <SidebarDivider />
        <Flex gap={1}>
          <WithLabel horizontal label="Von:" color="secondary" >
            <Typography bold>{item.from ? formatOfficeMailAddress(item.from?.emailAddress) : "Kein Absender"}</Typography>
          </WithLabel>
          <WithLabel horizontal label="An:" color="secondary">
            <Typography>{item.toRecipients?.map(r => r.emailAddress?.name || r.emailAddress?.address).join(", ") || "Keine Empfänger"}</Typography>
          </WithLabel>
          <WithLabel horizontal label="CC:" color="secondary">
            <Typography>{item.ccRecipients?.map(r => r.emailAddress?.name || r.emailAddress?.address).join(", ") || "Keine CC Empfänger"}</Typography>
          </WithLabel>
          <WithLabel horizontal label="BCC:" color="secondary">
            <Typography>{item.bccRecipients?.map(r => r.emailAddress?.name || r.emailAddress?.address).join(", ") || "Keine BCC Empfänger"}</Typography>
          </WithLabel>
        </Flex>
        <div className="w-100 office-message-view-attachments" >
          <Flex row align="end" justify="end" className="w-100">
            <DownloadAllAttachmentsButton account={account} mailId={item.id!} />
          </Flex>
          <Flex row wrap className="w-100" style={{ maxHeight: "200px", overflowY: "auto" }}>
            {
              loadingMailAttachmentPreview
                ? <LoadingSpinner text="Anhänge werden geladen..." />
                : (
                  mailAttachmentPreview?.length
                    ? mailAttachmentPreview.map(a => <MailAttachment account={account} attachment={a} mailId={item.id!} key={a.id} />)
                    : <em>Keine Anhänge</em>
                )
            }
          </Flex>
        </div>
        <Flex className="w-100 h-100" style={{ overflow: "auto" }}>
          {
            item.body && item.body.content
              ? <div className="w-100 h-100" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.body!.content) }} />
              : <em>Kein Inhalt</em>
          }
        </Flex>
      </Flex>
      <Flex align="end" className="w-100">
        <MailItemDebugInfoModal message={item} />
      </Flex>
    </Flex>
  )
}