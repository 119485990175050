import { formatDate } from "./formatter";

export const ensureDate = (d: any) => {
  if (d instanceof Date) return d;
  if (typeof d === "string") return new Date(d);
  return new Date();
}

export const formatFromTo = (a: Date, b?: Date): string => {
  const start = new Date(a);
  const end = b ? new Date(b) : null;

  let result = formatDate(start, true, true, true);
  
  if (end) {
    result += " - " + formatDate(end, true, true, true);
  }

  return result;
}