import React from "react";
import { useTicketSidebarHistory } from "../../../../hooks/useTicketSidebarHistory";
import { useCurrentTenantRole } from "../../../../state/api/user/useCurrentTenantRole";
import "./TicketMessageHistory.css";
import { ITicketMessage } from "../../../../types/ticketMessage.schema";

export default function TicketMessageHistory({ message }: { message: ITicketMessage }) {

  const { role } = useCurrentTenantRole();

  const {
    showTicketMessageHistory
  } = useTicketSidebarHistory();

  if (!message || !message.content || !message.content.length || message.content.length <= 1) return null;

  if (role && role.isClient) return null;

  return (
    <div className="ticket-message-history d-flex flex-row align-items-center gap-2">
      <span className="fw-bold ticket-message-history-toggle" onClick={() => showTicketMessageHistory(message._id)}>Bearbeitet</span>
    </div>
  )
}