import React from "react";
import { IPlan, IService } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { plansDelete, servicesDelete } from "../../api/Api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { usePlans } from "../../state/api/subscriptions/usePlans";
import { useUser } from "../../state/api/user/useUser";

export default function DeletePlanButton({service, plan}: {service: IService, plan: IPlan}) {

    const {
        user
    } = useUser();

    const callApi = useApi();
    const dispatch = useAppDispatch();

    const { reloadPlans } = usePlans(service?._id || "");

    const clickHandler = async () => {
        const res = await callApi(plansDelete({serviceId: service._id, planId: plan._id}));
        if (!res || !res.success) return;
        await reloadPlans();
    }

    if (!user || !user.isSuperAdmin) return null;

    return <Button onClick={clickHandler} text="Löschen" icon="trash" loadingText="Wird gelöscht..." color="error" />
}