import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { usersRequestLogIn } from "../../api/Api";
import { IUserLogInRequest } from "../../api/ApiRequests";
import Button from "../../components/buttons/Button";
import Flex from "../../components/container/Flex";
import FieldWithLabel from "../../components/forms/FormikField";
import PasswordField from "../../components/forms/PasswordField";
import PageLoader from "../../components/loader/PageLoader";
import Logo from "../../components/logo/Logo";
import Typography from "../../components/text/Typography";
import { AppRoutes } from "../../config/AppRoutes";
import { ModalType } from "../../config/ModalTypes";
import useApi from "../../hooks/useApi";
import { mail, min, required } from "../../hooks/useLocale";
import useModal from "../../hooks/useModal";
import usePassword from "../../hooks/usePassword";
import Page from "../../components/container/Page";
import { useSession } from "../../state/api/session/useSession";
import { useUser } from "../../state/api/user/useUser";
import "./LogIn.css";

export default function LogIn() {

  const showModal = useModal();

  const {
    isSecurePassword
  } = usePassword();

  const { loading, reloadSession, setSessionToken } = useSession();
  const { reloadUser } = useUser();

  const navigate = useNavigate();
  const callApi = useApi();

  if (loading) return <PageLoader />

  return (
    <Page fullscreen centered colored>
      <Flex gap={3} align="center" justify="center">
        <Logo />
        <Typography basedOnThisBackground="primary" size={24} bold >Willkommen zurück!</Typography>
        <Formik
          initialValues={{
            mailAddress: "",
            password: ""
          } as IUserLogInRequest}
          validationSchema={yup.object().shape({
            mailAddress: yup.string().email(mail()).required(required("E-Mail")),
            password: yup.string().required(required("Password")).min(8, min(8))
          })}
          onSubmit={async (values, actions) => {
            if (!values) return;

            const isSecure = isSecurePassword(values.password);

            if (!isSecure) {
              showModal({
                text: "Ihr Passwort ist unsicher. Bitte klicken Sie auf Passwort zurücksetzen und erstellen Sie ein sicheres Passwort.",
                type: ModalType.Error
              });
              return;
            }

            const res = await callApi(usersRequestLogIn(values));

            if (!res) return;

            const {
              session
            } = res.data;

            if (!session) return;

            navigate(AppRoutes.Tickets.path);
            setSessionToken(session);

            await Promise.all([
              reloadSession(undefined, { revalidate: true }),
              reloadUser()
            ]);
          }}
        >
          {
            formik => {
              return (
                <Form className="d-flex flex-column gap-3 position-relative w-100">
                  <FieldWithLabel
                    noHover
                    rounded
                    labelColorBasedOnBackground="primary"
                    name="mailAddress"
                    bold
                    label="E-Mail"
                    placeholder="E-Mail"
                  />
                  <PasswordField
                    rounded
                    labelColorBasedOnBackground="primary"
                  />
                  <Flex row justify="between" fullWidth>
                    <Button
                      to={AppRoutes.RequestPasswordReset.path}
                      color="muted"
                      variant="subtle"
                      text="Passwort zurücksetzen"
                      icon="key-fill"
                    />
                    <Button
                      type="submit"
                      color="bright"
                      loading={formik.isSubmitting}
                      loadingText="Lädt..."
                      text="Einloggen"
                      icon="door-open"
                    />
                  </Flex>
                </Form>
              )
            }
          }
        </Formik>
      </Flex>
    </Page>
  )
}