import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Status } from "../../config/Status";
import PageLoader from "../loader/PageLoader";
import PaymentMethod from "./PaymentMethod";
import Pill from "../pills/Pill";
import { useTenant } from "../../state/api/tenant/useTenant";
import { usePaymentMethods } from "../../state/api/tenant/usePaymentMethods";
import WithPermissions from "../permissions/WithPermissions";

export default function PaymentMethods() {

    const { tenant, loadingTenant } = useTenant();
    const { paymentMethods, loadingPaymentMethods, reloadPaymentMethods } = usePaymentMethods(tenant?._id || "");
    
    if (!tenant) return null;
    if (loadingPaymentMethods || loadingTenant) return <PageLoader />

    if (!paymentMethods || !paymentMethods.length) return (
        <Pill rounded color="#F00000" text="Noch kein Zahlungsmittel hinterlegt. Bitte hinterlegen Sie ein Zahlungsmittel, um unseren Service zu nutzen." />
    )
    
    return (
        <WithPermissions permissions={[ "billing.tenant.read"]}>
            <div className="d-flex flex-column gap-2">
                {
                    paymentMethods.map(p => <PaymentMethod key={p.id} paymentMethod={p} />)
                }
            </div>
        </WithPermissions>
    )
}