import React from "react";
import useSalutationUtil from "../../hooks/useSalutationUtil";
import { useSalutations } from "../../state/api/salutations/useSalutations";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import Pill from "../pills/Pill";
import Table from "../tables/Table";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import TemplatePreview from "../templates/TemplatePreview";
import Typography from "../text/Typography";
import DeleteSalutationButton from "./DeleteSalutationDialog";
import UpdateSalutationDialog, { IWithSalutationProps } from "./UpdateSalutationDialog";

export interface ISalutationsListProps extends IWithSalutationProps { }

export default function SalutationsList(props: ISalutationsListProps) {

  const {
    isGlobal
  } = props;

  const {
    salutations,
    loadingSalutations
  } = useSalutations(isGlobal);

  const {
    getUsedTemplateKeys,
    getTemplateKeyLabel
  } = useSalutationUtil();

  if (loadingSalutations) return <LoadingSpinner />

  return (
    <Table
      items={salutations ?? []}
      headers={["Name", "Vorlagen", "Aktionen"]}
      renderItem={s => (
        <TableRow key={s._id}>
          <TableCell>
            <Flex gap="1">
              <Typography bold >{s.name}</Typography>
              {
                s.isGlobalTemplate && <Pill color="#45F" icon="globe" text="Systemvorlage" />
              }
              {
                s.isGlobalDefault && <Pill color="success" icon="check" text="Systemstandard" />
              }
            </Flex>
          </TableCell>
          <TableCell>
            <Flex fullWidth gap="1">
              {
                getUsedTemplateKeys(s.useSameTemplateForAllGenders).map((value, index) => (
                  <TemplatePreview
                    key={`salutation-preview-${index}`}
                    inline
                    content={s[value]}
                    label={getTemplateKeyLabel(value)}
                  />
                ))
              }
            </Flex>
          </TableCell>
          <TableCell>
            <Flex row >
              <UpdateSalutationDialog isGlobal={isGlobal} salutation={s} />
              <DeleteSalutationButton isGlobal={isGlobal} salutation={s} />
            </Flex>
          </TableCell>
        </TableRow>
      )}

    />
  )
}