import React from "react";
import { datevImportCancel, datevImportDelete, datevImportResume } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { useClientImportLog } from "../../../state/api/clients/useClientImportLog";
import { useDatevImports } from "../../../state/api/datevImport/useDatevImports";
import Button from "../../buttons/Button";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import LoadingSpinner from "../../loader/LoadingSpinner";
import ModalDialog from "../../modal/ModalDialog";
import Pill from "../../pills/Pill";
import ExternalServiceBanner from "../../services/ExternalServiceBanner";
import Table from "../../tables/Table";
import TableCell from "../../tables/TableCell";
import TableRow from "../../tables/TableRow";
import Typography from "../../text/Typography";
import RoleBanner from "../../user/RoleBanner";
import useClientImportUtil from "../../../hooks/useClientImportUtil";
import { formatDate } from "../../../util/formatter";
import DatevEndpointPill from "../DatevEndpointPill";

export default function CurrentClientImports() {

    const {
        datevImports,
        reloadDatevImports,
        loadingDatevImports
    } = useDatevImports();

    const {
        isImportDone
    } = useClientImportUtil();

    const callApi = useApi();

    if (loadingDatevImports) return <LoadingSpinner />

    return (
        <Table
            headers={[
                { label: "Start" },
                { label: "Quelle" },
                { label: "Status" },
                { label: "Abfrage" },
                { label: "Erstellt von" },
                { label: "Aktionen" }
            ]}
            items={datevImports ?? []}
            renderItem={d => (
                <TableRow>
                    <TableCell>
                        <Typography>{formatDate(d.createdAt, true, true)}</Typography>
                    </TableCell>
                    <TableCell>
                        <ExternalServiceBanner service={d.serviceSource} />
                    </TableCell>
                    <TableCell>
                        {
                            d.completed
                            ? <Pill text="Erfolgreich abgeschlossen!" color="success" />
                            : <ImportLogMessage id={d.importLog } />
                        }
                    </TableCell>
                    <TableCell>
                        <Flex row>
                            {
                                !!d.limitTo?.length
                                ? d.limitTo.map(l => <DatevEndpointPill key={l} endpoint={l} active />)
                                : <Typography>Alle Resourcen</Typography>
                            }
                        </Flex>
                    </TableCell>
                    <TableCell>
                        <Flex>
                            <RoleBanner user={d.importingUser} displayNameAsValue />
                        </Flex>
                    </TableCell>
                    <TableCell>
                        <Flex row>
                            <Button
                                icon={d.cancelled || d.completed ? "arrow-clockwise" : "x"}
                                text={d.cancelled || d.completed ? "Erneut starten" : "Abbrechen"}
                                color={d.cancelled || d.completed ? "success" : "error"}
                                onClick={async () => {
                                    const res = (
                                        d.cancelled || d.completed
                                        ? await callApi(datevImportResume({ id: d._id })) 
                                        : await callApi(datevImportCancel({ id: d._id }))
                                    );

                                    await reloadDatevImports();
                                }}
                            />
                            {
                                (d.cancelled || d.completed) && (
                                    <Button
                                        icon="trash"
                                        text="Löschen"
                                        color="error"
                                        secondStepQuestion="Sicher?"
                                        onClick={async () => {
                                            const res = await callApi(datevImportDelete({ id: d._id }));
                                            await reloadDatevImports();
                                        }}
                                    />
                                )
                            }
                        </Flex>
                    </TableCell>
                </TableRow>
            )}

        />
    )
}

function ImportLogMessage({ id }: { id: string}) {

    const interval = React.useRef<any>(null);

    const [lastText, setLastText] = React.useState<string>("-");
    
    const {
        clientImportLog,
        loadingClientImportLog,
        reloadClientImportLog
    } = useClientImportLog(id);

    const {
        getImportIcon,
        isImportRunning
    } = useClientImportUtil();

    React.useEffect(() => {
        if (!clientImportLog) return;
        setLastText(clientImportLog?.message ?? "-");
    }, [clientImportLog]);

    React.useEffect(() => {
        if (!isImportRunning(clientImportLog)) return () => clearInterval(interval.current);
        interval.current = setInterval(async () => await reloadClientImportLog(undefined, { revalidate: true }), 5000);
        return () => clearInterval(interval.current);
    }, [clientImportLog]);

    const icon = getImportIcon(clientImportLog);

    return (
        <Flex row>
            <Icon
                icon={icon || "arrow-clockwise"}
                loading={loadingClientImportLog}
                onClick={() => reloadClientImportLog(undefined, { revalidate: true })}
            />
            <Typography wrap>
                {lastText}
            </Typography>
            {
                !!(clientImportLog?.allErrors?.length) && (
                    <ModalDialog
                        button={<Pill color="#FF0000" text={`Fehler (${clientImportLog.allErrors.length})`} />}
                        title="Import-Fehler"
                    >
                        {
                            clientImportLog.allErrors.map((e, i) => (
                                <Typography key={i} wrap>
                                    {e.errorId ?? `00${i}`} - {e.errorMessage ?? "Unbekannter Fehler"}
                                </Typography>
                            ))
                        }
                    </ModalDialog>
                    
                )
            }
        </Flex>
    )
}