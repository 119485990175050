import React from "react";
import { tasksUpdateStatus } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useTasks } from "../../state/api/tasks/useTasks";
import { useTicketTasks } from "../../state/api/tickets/useTicketTasks";
import { ITask } from "../../types/task.schema";
import Button from "../buttons/Button";

export interface IUpdateTaskStatusButtonProps {
  task: ITask,
  afterClick?: (() => (any | Promise<any>)) | Function
}

export default function UpdateTaskStatusButton({ task, afterClick }: IUpdateTaskStatusButtonProps) {
  const {
    reloadTasks
  } = useTasks();

  const {
    reloadTicketTasks
  } = useTicketTasks(task?.ticket ?? "");

  const callApi = useApi();

  if (!task || task.completed) return null;

  const clickHandler = async () => {
    await callApi(tasksUpdateStatus(task._id));
    if (!!task?.ticket) await reloadTicketTasks();
    await reloadTasks();
    afterClick?.();
  }

  if (!!task.baseTask) return null;

  return (
    <Button 
      onClick={clickHandler} 
      text={task.completed ? "Als nicht erledigt markieren" : "Als Erledigt markieren"}
      color={task.completed ? "error" : "success"}
      variant="text" 
      icon={task.completed ? "dash" : "check"}
    />
  )
}