
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IGlobalMailTemplate } from "../../../types/globalMailTemplates.schema";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useGlobalMailTemplates = () => {
    const { data, isLoading, mutate } = useTiggiQuery<IGlobalMailTemplate[]>(ApiRoutes.MailTemplates.Global.All);

    return {
        globalMailTemplates: data,
        loadingGlobalMailTemplates: isLoading,
        reloadGlobalMailTemplates: mutate
    }
}