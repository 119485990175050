import React from "react";
import { ITask, ITaskAssignment, TaskAttendance } from "../../types/task.schema";
import { useUser } from "../../state/api/user/useUser";
import { getId } from "../../util/mongoUtil";
import useTaskUtil from "../../hooks/useTaskUtil";
import useApi from "../../hooks/useApi";
import Expandable from "../comboBox/Expandable";
import Pill from "../pills/Pill";
import { tasksUpdateAttendance } from "../../api/Api";
import { useTasks } from "../../state/api/tasks/useTasks";
import Flex from "../container/Flex";
import Typography from "../text/Typography";

export interface ITaskAttendanceStatusProps {
  task: ITask,
  assignment: ITaskAssignment,
  showAttendandName?: boolean,
}

export default function TaskAttendanceStatus(props: ITaskAttendanceStatusProps) {

  const {
    task,
    showAttendandName,
    assignment
  } = props;

  const [currentStatus, setCurrentStatus] = React.useState<TaskAttendance>(assignment.attendance ?? TaskAttendance.Open);

  const {
    reloadTasks
  } = useTasks({ enabled: false });

  const {
    user
  } = useUser();

  const {
    getAttendanceStatusColor,
    getAttendanceStatusIcon,
    getAttendanceStatusLabel,
    getAttendantName
  } = useTaskUtil();

  const callApi = useApi();

  const isOwn = getId(user) === getId(assignment.user) && assignment.user;
  const isReadOnly = !isOwn || task.completed;

  const getStatusPill = (s: TaskAttendance, clickable: boolean = false, canShowAttendantName: boolean = false) => {
    const color = getAttendanceStatusColor(s);
    const icon = getAttendanceStatusIcon(s);
    const attendantNameShown = showAttendandName && canShowAttendantName;

    return (
      <Pill
        key={`${task._id}_${s}`}
        onClick={clickable ? async () => {
          const res = await callApi(tasksUpdateAttendance(task._id, { attendance: s }));
          if (!res?.success) return;
          await reloadTasks();
          setCurrentStatus(s);
        } : undefined}
        color={color}
        icon={icon}
        withDivider
      >
        <Flex gap="0">
          {attendantNameShown && <Typography bold>{getAttendantName(assignment)}</Typography>}
          <Typography size={attendantNameShown ? "12" : undefined} basedOnThisBackground={color} >{getAttendanceStatusLabel(s)}</Typography>
        </Flex>
      </Pill>
    )
  }

  return (
    <Expandable
      disabled={isReadOnly}
      expander={getStatusPill(currentStatus, false, true)}
    >
      {
        isOwn
          ? (
            <Flex  gap="3">
              {
                Object.values(TaskAttendance).map((s, index) => getStatusPill(s, true))
              }
            </Flex>
          )
          : null
      }
    </Expandable>
  )
}