import React from "react";
import { useTasks } from "../../state/api/tasks/useTasks";
import { ITask } from "../../types/task.schema";
import Flex from "../container/Flex";
import UpdateTaskButton from "../tasks/UpdateTaskButton";
import Typography from "../text/Typography";
import "./Appointment.css";
import { DailyAgendaDayHeight } from "./DailyAgendaSkeleton";
import { generateClassName } from "../../hooks/useAttributes";
import { IMappedTasksForDay } from "../../hooks/useTaskUtil";
import { formatFromTo } from "../../util/date";

export interface IAppointmentProps {
  task: ITask,
  day: Date,
  mappedTasks: IMappedTasksForDay
}

export default function Appointment(props: IAppointmentProps) {

  const {
    task,
    day,
    mappedTasks
  } = props;

  const s = new Date(task.start);
  const e = task.end ? new Date(task.end) : s;
  const d = mappedTasks.durations.get(task._id);

  const {
    start,
    step,
    width
  } = mappedTasks.getTaskSize(task);

  if (!d) return null;

  const height = (d.minuteLength / 60) * DailyAgendaDayHeight;
  const top = (d.hourStart * DailyAgendaDayHeight) + ((d.minuteStart / 60) * DailyAgendaDayHeight);

  const isPast = e < new Date();

  return (
    <UpdateTaskButton
      button={(
        <div
          style={{
            width: `calc((100% / ${step}) * ${width || 1})`,
            top,
            left: `calc(${start} * (100% / ${step ?? 1}))`,
            minHeight: height
          }}
          className="appointment"
        >
          <div
            className={generateClassName("appointment-background", {
              value: isPast,
              onTrue: "appointment-background-past"
            }, {
              value: !isPast,
              onTrue: "appointment-background-upcoming"
            })}
          />
          <Flex className="p-2" fullHeight fullWidth>
            <Flex gap="0" fullWidth justify="between">
              <Typography wrap className="w-100" basedOnThisBackground="secondary" size="10" noLinePadding>
                {formatFromTo(s, task.end)}
              </Typography>
              <Typography wrap className="w-100" basedOnThisBackground="secondary" bold upper>{task.subject}</Typography>
            </Flex>
            {
              !!task.end && (
                <Typography
                  basedOnThisBackground="secondary"
                  size="12"
                  wrap
                  className="w-100"
                >
                  {task.description || "Keine Beschreibung"}
                </Typography>
              )
            }
          </Flex>
        </div>
      )}
      start={s}
      task={task}
    />
  )
}