import { Form, Formik } from "formik";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { clientsImportFromDatev } from "../../api/Api";
import { IClientsImportFromDatevRequest } from "../../api/ApiRequests";
import { QueryParams } from "../../config/AppRoutes";
import useApi from "../../hooks/useApi";
import { IClientImportLogEntry } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import FileSelect, { IFileSelectValidationResult } from "../files/FileSelect";
import CheckBox from "../forms/CheckBox";
import WithPermissions from "../permissions/WithPermissions";
import "./ImportClientsForm.css";

export default function ImportClientsForm({afterComplete}: {afterComplete?: () => void}) {

    const [searchParams, setSearchParams] = useSearchParams();

    const callApi = useApi();

    return (
        <WithPermissions permissions={[ "interfaces.datev.update" ]}>
            <Formik
                initialValues={{
                    datevExport: {
                        clients: [],
                    },
                    alsoImportInactiveData: false
                }}
                onSubmit={async (values) => {

                    const content: IClientsImportFromDatevRequest = {...values.datevExport, alsoImportInactiveData: values.alsoImportInactiveData};

                    const res = await callApi<IClientImportLogEntry>(clientsImportFromDatev(content));

                    if (!res) return;

                    setSearchParams(QueryParams.ImportId.createNewValue(res.data._id, searchParams));
                    if (afterComplete) afterComplete();
                }}
                >
                    {
                        (formik) => {

                            const resetSelectedExport = () => formik.setFieldValue("datevExport", {clients: []});

                            const validateContent = (data: any): IFileSelectValidationResult => {
                                if (!data) return {
                                    data: null,
                                    success: false,
                                    validationError: "Bitte wählen Sie eine Datei mit Inhalt aus."
                                };


                                if (typeof data !== "string") {
                                    return {
                                        success: false,
                                        data: null,
                                        validationError: "Bitte wählen Sie eine JSON-Datei mit validem JSON aus."
                                    }
                                }

                                let content;

                                try {
                                    content = JSON.parse(data);
                                    
                                    if (typeof content !== "object") throw new Error();
                                    if (!("clients" in content)) throw new Error();

                                    if (!content["clients"] || !content["clients"].length) return {
                                        data: null,
                                        success: false,
                                        validationError: "Ihr Export enthält keine Mandanten."
                                    };

                                    return {
                                        success: true, 
                                        data: content,
                                        validationError: ""
                                    }
                                }
                                catch {
                                    return {
                                        data: null,
                                        success: false,
                                        validationError: "Bitte wählen Sie einen valide JSON-Export aus DATEV aus. Dieser muss das 'clients'-Feld beinhalten."
                                    }
                                }
                            }

                            const saveValue = (data: any) => {
                                formik.setFieldValue("datevExport", data);
                            }

                            const hasValidInput = formik.values && formik.values.datevExport.clients && !!formik.values.datevExport.clients.length;
                            const clientsToImport = hasValidInput && formik.values.datevExport.clients.length;

                            return (
                                <Form className="d-flex flex-column gap-2 w-100">
                                    <FileSelect accept=".json" validateInput={validateContent} saveContent={saveValue} resetContent={resetSelectedExport} />
                                    <div className="d-flex flex-row align-items-center w-100 justify-content-between">
                                        <CheckBox name="alsoImportInactiveData" label="Auch inaktive Daten importieren" />
                                        <Button type="submit" loading={formik.isSubmitting} disabled={!hasValidInput} text={hasValidInput ? `${clientsToImport} Mandanten importieren` : "DATEV-Mandantenexport auswählen"} color="primary" />
                                    </div>
                                </Form>
                            )
                        }
                    }
            </Formik>
        </WithPermissions>
    )
}