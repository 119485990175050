import React from "react";
import { ITask } from "../../types/task.schema";
import useApi from "../../hooks/useApi";
import Button from "../buttons/Button";
import { tasksDelete } from "../../api/Api";
import { useTasks } from "../../state/api/tasks/useTasks";
import { useUpcomingTasks } from "../../state/api/tasks/useUpcomingTasks";

export interface IDeleteTaskButtonProps {
  task: ITask,
  afterDelete?: (() => (any | Promise<any>)) | Function
}

export default function DeleteTaskButton(props: IDeleteTaskButtonProps) {

  const {
    task,
    afterDelete
  } = props;

  const {
    reloadTasks
  } = useTasks({ enabled: false });

  const {
    reloadUpcomingTasks
  } = useUpcomingTasks({ enabled: false });

  const callApi = useApi();

  return (
    <Button
      onClick={async () => {
        await callApi(tasksDelete(task))

        Promise.all([
          reloadTasks(),
          reloadUpcomingTasks()
        ]);
        
        afterDelete?.();
      }}
      color="error"
      variant="subtle"
      text="Löschen"
      secondStepQuestion="Sicher?"
      icon="trash"
    />
  )
}