import React from "react";
import Typography from "../text/Typography";
import Flex from "../container/Flex";
import { To, useNavigate } from "react-router-dom";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";

interface IPageBackButtonProps {
  backLink?: To
}

export interface IPageHeaderProps extends IPageBackButtonProps {
  showBackButton?: boolean,
  loading?: boolean,
  text: string
}

export default function PageHeader(props: IPageHeaderProps) {

  const {
    text,
    loading,
    showBackButton,
    backLink
  } = props;

  if (!text) return null;

  const backButtonVisible = showBackButton ?? (backLink !== undefined && backLink !== null);

  return (
    <Flex row className="pt-3 pb-3">
      {
        loading 
        ? <LoadingSpinner size={30} color="primary" />
        : backButtonVisible && <PageBackButton backLink={backLink} />
      }
      <Typography color="primary" truncate={false} wrap size={34} lineHeight={38} bold>{text}</Typography>
    </Flex>
  )
}

function PageBackButton(props: IPageBackButtonProps) {

  const {
    backLink
  } = props;

  const navigate = useNavigate();

  return (
    <Icon icon="chevron-left" color="primary" size={30} onClick={() => navigate((backLink ?? -1) as To)} />
  )
}