import React from "react";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import { useTicket } from "../../../state/api/tickets/useTicket";
import { useTicketAssignee } from "../../../state/api/tickets/assignments/useTicketAssignee";
import { useUsers } from "../../../state/api/user/useUsers";
import { ITicket } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import Expandable from "../../comboBox/Expandable";
import WithPermissions from "../../permissions/WithPermissions";
import "./AssignTicketToOtherTeamButton.css";
import AssignTicketToOtherTeamMemberButton from "./AssignTicketToOtherTeamMemberButton";
import { ITeam } from "../../../types/team.schema";

interface IAssignTicketToOtherTeamButtonProps {
  ticket: ITicket,
  team: ITeam,
}

export default function AssignTicketToOtherTeamButton({ team, ticket }: IAssignTicketToOtherTeamButtonProps) {

  const permissions = useTicketPermission("update", ticket);

  const { reloadTicketAssignee } = useTicketAssignee(ticket);
  const { reloadTicket } = useTicket(ticket._id);
  const { usersById } = useUsers();

  const hasMembers = team && team.members && !!team.members.length;

  const getChildren = () => {
    const children = [];

    for (const m of team.members) {

      const user = usersById[m];

      if (!user) continue;

      children.push(
        <AssignTicketToOtherTeamMemberButton
          key={user._id}
          afterAssigningTicket={() => {
            reloadTicket()
            reloadTicketAssignee();
          }}
          team={team}
          ticket={ticket}
          userId={user._id}
        />
      );
    }

    return children;
  }

  return (
    <WithPermissions permissions={permissions}>
      <Expandable
        disabled={!hasMembers}
        expander={<Button disabled={!hasMembers} variant="text" icon="chevron-right" className="w-100" iconPosition="end">{team.name}</Button>}
      >
        {
          getChildren()
        }
      </Expandable>
    </WithPermissions>
  );
}