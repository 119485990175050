
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IDocumentClearanceReminder } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useDocumentClearanceReminders = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IDocumentClearanceReminder>>(ApiRoutes.DocumentClearance.All);

    return {
        documentClearanceReminders: data,
        loadingDocumentClearanceReminders: isLoading,
        reloadDocumentClearanceReminders: mutate
    }
}
