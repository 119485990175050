
import { ITicketStatistics } from "../../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../../api/ApiRoutes";
import useTiggiQuery from "../../../../hooks/useTiggiQuery";

export const useAnyTeamTicketStatistics = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<ITicketStatistics>(ApiRoutes.Tickets.Statistics.ForTeam.getValue({
        param: RouteParam.TeamId,
        value: id
    }), { refetchOnWindowFocus: false });

    return {
        teamTicketStatistics: data,
        loadingTeamTicketStatistics: isLoading,
        reloadTeamTicketStatistics: mutate
    }
}