import React from "react";
import Flex from "../../components/container/Flex";
import PageLoader from "../../components/loader/PageLoader";
import DeleteLogsManuallyButton from "../../components/log/api/DeleteLogsManuallyButton";
import LogEntryEventTypeBanner from "../../components/log/api/LogEntryEventTypeBanner";
import LogEntryForm from "../../components/log/api/LogEntryForm";
import Table from "../../components/tables/Table";
import TableCell from "../../components/tables/TableCell";
import TableRow from "../../components/tables/TableRow";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import Page from "../../components/container/Page";
import { useLogs } from "../../state/api/log/useLogs";
import { useTenant } from "../../state/api/tenant/useTenant";
import useLogUtil from "../../hooks/useLogUtil";
import LogEntryTypeBanner from "../../components/log/api/LogEntryTypeBanner";
import ExternalServiceBanner from "../../components/services/ExternalServiceBanner";
import { ILogEntry, LogEntryType } from "../../types/log.schema";
import { getId } from "../../util/mongoUtil";
import DateTimePicker from "../../components/forms/datetime/DateTimePicker";
import { getDateInXFrom, getDateInXFromNow } from "../../util/util";

export enum LogViewerTenant {
  All = "all",
  Current = "current"
}

export default function LogViewer() {
  const [selectedType, setSelectedType] = React.useState<LogEntryType | "all">(LogEntryType.Error);
  const [displayedTenant, setDisplayedTenant] = React.useState<LogViewerTenant>(LogViewerTenant.All);
  const [date, setDate] = React.useState<Date>(new Date());

  const [filteredItems, setFilteredItems] = React.useState<ILogEntry[]>([]);

  const { loadingLogs, logs, reloadLogs } = useLogs();

  const { tenant } = useTenant();

  const {
    getColorFromLogEntryType,
    getIconFromLogEntryType
  } = useLogUtil();

  React.useEffect(() => {
    if (!logs) return;
    setFilteredItems(logs.filter(l => {
      if (!l.createdAt) return false;
      if (new Date(l.createdAt).toLocaleDateString() !== date.toLocaleDateString()) return false;

      const matchesType = selectedType === "all" || l.type === selectedType;
      const matchesTenant = displayedTenant === LogViewerTenant.Current ? (getId(l.tenant) === getId(tenant)) : true;

      return matchesTenant && matchesType;
    }));
  }, [selectedType, date, logs, displayedTenant]);

  return (
    <Page header="Logbuch" actionButtons={<DeleteLogsManuallyButton />}>
      {
        loadingLogs
          ? <PageLoader />
          : (
            <Flex gap="3" fullHeight fullWidth>
              <Flex row fullWidth justify="between">
                <TabSwitcher
                  tabs={[{
                    data: "all",
                    label: "Alle",
                    icon: "eye"
                  }, {
                    data: LogEntryType.Error,
                    label: "Fehler",
                    icon: getIconFromLogEntryType(LogEntryType.Error),
                    backColor: getColorFromLogEntryType(LogEntryType.Error)
                  }, {
                    data: LogEntryType.Event,
                    label: "Events",
                    icon: getIconFromLogEntryType(LogEntryType.Event),
                    backColor: getColorFromLogEntryType(LogEntryType.Event)
                  }, {
                    data: LogEntryType.General,
                    label: "Informationen",
                    icon: getIconFromLogEntryType(LogEntryType.General),
                    backColor: getColorFromLogEntryType(LogEntryType.General)
                  }, {
                    data: LogEntryType.Schedule,
                    label: "Zeitplan",
                    icon: getIconFromLogEntryType(LogEntryType.Schedule),
                    backColor: getColorFromLogEntryType(LogEntryType.Schedule)
                  }]}
                  tabQueryParamKey="type"
                  saveActiveTab={t => setSelectedType(t as any)}
                />
                <DateTimePicker max={new Date()} date={date} dateOnly onChange={setDate} />
              </Flex>
              <TabSwitcher
                size="small"
                tabs={[{
                  data: LogViewerTenant.All,
                  label: "Alle Tenants",
                }, {
                  data: LogViewerTenant.Current,
                  label: "Aktueller Tenant",
                }]}
                tabQueryParamKey="tenant"
                saveActiveTab={t => setDisplayedTenant(t as LogViewerTenant)}
              />
              <Flex fullHeight overflowY="auto" fullWidth>
                <Table
                  border
                  headers={[{ label: "Datum" }, { label: "Inhalt / Nachricht", valueKey: "content" }, { label: "Metadaten" }, { label: "" }]}
                  items={filteredItems}
                  renderItem={l => (
                    <TableRow key={l._id}>
                      <TableCell date={l.createdAt} showTime />
                      <TableCell>
                        <Flex className="w-100" style={{ minWidth: "35vw" }}>
                          <textarea readOnly className="w-100 text-wrap" style={{ border: "none", background: "none" }} value={l.content} />
                        </Flex>
                      </TableCell>
                      <TableCell>
                        <Flex gap="2">
                          <LogEntryTypeBanner log={l} />
                          <LogEntryEventTypeBanner log={l} />
                          <ExternalServiceBanner service={l.service} />
                        </Flex>
                      </TableCell>
                      <TableCell>
                        <LogEntryForm log={l} />
                      </TableCell>
                    </TableRow>
                  )}
                />
              </Flex>
            </Flex>
          )
      }
    </Page>
  )
}