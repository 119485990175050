import React from "react";
import { ITicket } from "../../types/ApiTypes";
import ModalForm from "../modal/ModalForm";
import { ITicketEscalateManuallyRequest } from "../../api/ApiRequests";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { ticketsEscalateManually } from "../../api/Api";
import { useTicketEscalationLog } from "../../state/api/tickets/useTicketEscalationLog";
import Flex from "../container/Flex";
import { useTicketEscalationWorkflow } from "../../state/api/escalation/useTicketEscalationWorkflows";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";

export interface IEscalateTicketManuallyButtonProps {
    ticket: ITicket
}

export default function EscalateTicketManuallyButton({ticket}: IEscalateTicketManuallyButtonProps) {

    const { loadingTicketEscalationWorkflow, reloadTicketEscalationWorkflow, ticketEscalationWorkflow } = useTicketEscalationWorkflow();
    const { reloadTicketEscalationLog } = useTicketEscalationLog(ticket);

    const callApi = useApi();

    return (
        <WithPermissions permissions={[ "tickets.unassigned.update" ]}>
            <ModalForm
                title="Eskalationsworkflow manuell starten"
                initialValues={{
                    escalationWorkflowId: "",
                    ticketId: ticket._id
                } as ITicketEscalateManuallyRequest}
                button={<Button text="Workflow manuell starten" variant="text" />}
                onSubmit={async (values) => {
                    const res = await callApi(ticketsEscalateManually(values));
                    if (!res || !res.success) return false;
                    await reloadTicketEscalationLog();
                    return true;
                }}
            >
                {
                    formik => (
                        <Flex>
                            {
                                loadingTicketEscalationWorkflow
                                ? <LoadingSpinner />
                                : (
                                    ticketEscalationWorkflow && ticketEscalationWorkflow.length
                                    ? (
                                        <SearchableComboBox
                                            values={ticketEscalationWorkflow}
                                            value={formik.values.escalationWorkflowId as any}
                                            itemToId={item => item._id}
                                            itemToString={item => `Stufe ${item.level} nach Stunde: ${item.afterHoursOpen}`}
                                            onItemClick={item => formik.setFieldValue("escalationWorkflowId", item._id)}
                                            placeholder="Eskalationsworkflow auswählen"
                                        />
                                    )
                                    : <span>Keine Eskalations-Workflows hinterlegt</span>
                                )
                            }
                        </Flex>
                    )
                }
            </ModalForm>
        </WithPermissions>
    )
}