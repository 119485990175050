import { FieldArray, useFormikContext } from "formik";
import React from "react";
import { useRoles } from "../../state/api/roles/useRoles";
import { useTenant } from "../../state/api/tenant/useTenant";
import { useTenants } from "../../state/api/tenant/useTenants";
import { useCurrentTenantAuthority } from "../../state/api/user/useCurrentTenantAuthority";
import { useUser } from "../../state/api/user/useUser";
import { IRole, ITenant, ITenantAuthority, IUser } from "../../types/ApiTypes";
import { getId } from "../../util/mongoUtil";
import Button from "../buttons/Button";
import Card from "../card/Card";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import CheckBox from "../forms/CheckBox";
import Typography from "../text/Typography";
import RoleBanner from "./RoleBanner";
import "./UserAuthorityFieldArray.css";
import UserSelect from "./UserSelect";

export interface IUserAuthorityFieldArrayProps {
    isClientContact: boolean,
    isCreate: boolean
}

export default function UserAuthorityFieldArray({isClientContact, isCreate}: IUserAuthorityFieldArrayProps) {

    const { roles, loadingRoles } = useRoles();

    const {
        tenant
    } = useTenant();

    const {
        user
    } = useUser();

    const {
        authority: currentAuthority
    } = useCurrentTenantAuthority();

    const {
        values,
        setFieldValue
    } = useFormikContext<IUser>();

    const { tenants, loadingTenants } = useTenants();
    
    const authority = values["authority"] ?? [];

    if (!user) return null;
    if (!roles) return null;

    const getAvailableRoles = () => {
        if (!roles) return [];
        return roles.filter(r => r.isClient === isClientContact);
    }

    const getAvailableTenants = () => {
        if (!tenants) return [];
        return tenants.filter(t => {
            if (!t.name) return false;
            return !authority.find(a => getId(a.tenant) === getId(t));
        });
    }

    const availableRoles = getAvailableRoles();
    const availableTenants = getAvailableTenants();

    const canBeAssignedToMoreTenants = !!availableTenants.length;

    const isSuperAdmin = user.isSuperAdmin;
    const isTenantAdmin = !!currentAuthority?.isTenantAdmin;

    return (
        <FieldArray name="authority">
            {
                (arrayHelpers) => {
                    return (
                        <div className="user-authority-field-array-container d-flex flex-column align-items-start w-100 gap-2">
                            <Flex row justify="between" fullWidth>
                                <Typography color="primary" bold>{ (isSuperAdmin && !isCreate) ? "Zugriffsverwaltung" : "Rolle" }</Typography>
                                {
                                    isSuperAdmin && !isCreate && (
                                        <SearchableComboBox
                                            expander={
                                                <Button  
                                                    loading={loadingTenants}
                                                    text={canBeAssignedToMoreTenants ? "Weitere Kanzlei" : "Allen Kanzleien zugewiesen"} 
                                                    icon="plus" 
                                                    disabled={!canBeAssignedToMoreTenants} 
                                                />
                                            }
                                            loading={loadingTenants}
                                            values={availableTenants}
                                            disabled={!canBeAssignedToMoreTenants}
                                            placeholder="Nach Kanzlei suchen..."
                                            itemToId={(tenant: ITenant) => tenant._id}
                                            itemToString={(tenant: ITenant) => tenant.name}
                                            onItemClick={t => arrayHelpers.push({
                                                tenant: t,
                                                role: roles && !!roles.length && roles[0]
                                            } as ITenantAuthority)} 
                                        />
                                    )
                                }
                            </Flex>
                            <div className="user-authority-field-array d-flex flex-column gap-2 w-100">
                                {
                                    values.authority && !!values.authority.length 
                                    ? values.authority.map((a, index) => {
                                        if (!a.tenant) return null;
                                        if (!isSuperAdmin && a.tenant._id !== tenant._id) return null;

                                        return (
                                            <Card
                                                key={a._id || `user-authority-field-array-item-${index}`}
                                                color="bright"
                                                header={(
                                                    <Flex fullWidth row justify="between" align="start">
                                                        <Flex gap={0}>
                                                            <Typography bold color="primary">{a.tenant.name}</Typography>
                                                            { (isTenantAdmin || isSuperAdmin) && <CheckBox labelClass="text-nowrap" label="Kanzlei-Administrator" name={`authority[${index}].isTenantAdmin`} /> }
                                                        </Flex>
                                                        { 
                                                            (isSuperAdmin && !isCreate) && (
                                                                <Button 
                                                                    icon="trash" 
                                                                    onClick={() => arrayHelpers.remove(index)} 
                                                                    text="Zugriff entfernen" 
                                                                    color="error" 
                                                                    variant="text" 
                                                                />
                                                            )
                                                        }
                                                    </Flex>
                                                )}
                                            >
                                                <Flex fullWidth row>
                                                    <SearchableComboBox 
                                                        label="Rolle"
                                                        loading={loadingRoles}
                                                        renderItem={r => <RoleBanner role={r} />}
                                                        placeholder="Rolle..."
                                                        className="w-100"
                                                        values={availableRoles || roles || []} 
                                                        onItemClick={(r) => setFieldValue(`authority[${index}].role`, r)}
                                                        value={a.role} 
                                                        itemToId={(role: IRole) => role._id} 
                                                        itemToString={(role: IRole) => role.displayName} 
                                                    />
                                                    {
                                                        (a.role && !a.role.isClient) && (
                                                            <UserSelect
                                                                label="Vorgesetzter"
                                                                onChange={(u) => setFieldValue(`authority[${index}].supervisor`, u)}
                                                                value={a.supervisor}
                                                                displayed="employees"
                                                            />
                                                        )
                                                    }
                                                </Flex>
                                            </Card>
                                        );
                                    })
                                    : <em>Kein Zugriff zugewiesen.</em>
                                }
                            </div>
                        </div>
                    )
                }
            }
        </FieldArray>
    )
}