import React from "react";
import { useSession } from "../../state/api/session/useSession";
import { Cookies, deleteCookie } from "../../util/cookies";
import Button from "../buttons/Button";

export default function LogInWithOtherUserButton() {

  const { reloadSession } = useSession();

  return (
    <Button
      text="Mit anderem Nutzer anmelden"
      icon="person-x"
      variant="subtle"
      color="secondary"
      onClick={async () => {
        deleteCookie(Cookies.Session);
        await reloadSession(undefined);
      }}
    />
  );
}