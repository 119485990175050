import React from "react";
import { servicesUpdate } from "../../api/Api";
import { IServiceUpdateRequest } from "../../api/requests/subscriptions/subscriptionRequests";
import useApi from "../../hooks/useApi";
import { Locale } from "../../locale/Locale";
import { useServices } from "../../state/api/subscriptions/useServices";
import { useUser } from "../../state/api/user/useUser";
import { IService } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import CheckBox from "../forms/CheckBox";
import FieldWithLabel from "../forms/FormikField";
import ModalForm from "../modal/ModalForm";

export default function UpdateServiceButton({service}: {service: IService}) {

    const {
        user
    } = useUser();

    const callApi = useApi();

    const { reloadServices } = useServices();
    
    if (!user || !user.isSuperAdmin) return null;

    return (
        <ModalForm 
            title={Locale.pages.services.updateService} 
            button={<Button text={Locale.pages.services.updateService} icon="pen" />}
            initialValues={{
                name: service.name,
                description: service.description,
                id: service._id,
                isGlobalBaseSubscription: service.isGlobalBaseSubscription
            } as IServiceUpdateRequest}
            onSubmit={async (values) => {
                const res = await callApi(servicesUpdate(values));
                if (!res) return false;
                await reloadServices();
                return true;
            }}
            sidebar={formik => <Button type="submit" text="Service aktualisieren" icon="save" loading={formik.isSubmitting} />}
        >
            {
                (formik) => (
                    <Flex className="w-100">
                        <FieldWithLabel name="name" label="Name der Plangruppe *" placeholder="z.B. tiggi.io" />
                        <FieldWithLabel name="description" label="Beschreibung" placeholder="Beschreibung des Abos, z.B. Zugriff auf das tiggi.io Ticketsystem" />
                        <CheckBox name="isGlobalBaseSubscription" label="Ist globales Grundlagen-Abo für den Zugriff auf das System" />
                    </Flex>
                )
            }
        </ModalForm>
    )
}