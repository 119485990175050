import { useTenant } from "../state/api/tenant/useTenant";
import { useUser } from "../state/api/user/useUser";
import { useUsers } from "../state/api/user/useUsers";
import { DbId, IRole, ITenant, ITenantAuthority, IUser, UserRole } from "../types/ApiTypes";
import { Gender } from "../types/salutation.schema";
import { getId } from "../util/mongoUtil";

export interface ISortedUser {
    user: IUser,
    originalCollectionIndex: number
}

export interface ISortedUsers {
    roleName: string,
    members: ISortedUser[]
}

export default function useUserUtil() {

    const { user: currentUser } = useUser();
    const { usersById } = useUsers();

    const { tenant } = useTenant();

    const getUserById = (u: DbId<IUser> | null | undefined): IUser | null => {
        if (!u) return null;
        if (typeof u !== "string") return u;
        const id = getId(u);
        if (!id) return null;
        return usersById[id] ?? null;
    }

    const normalizeUser = (user?: Partial<IUser> | null, withCurrentTenantAuthority: boolean = false): IUser => ({
        _id: user?._id || "",
        firstName: user?.firstName || "",
        phoneNumber: user?.phoneNumber || "",
        isTestingUser: user?.isTestingUser || false,
        isPhoneNumberVerified: user?.isPhoneNumberVerified || false,
        lastSeen: user?.lastSeen || new Date(),
        updatedBy: user?.updatedBy || {} as IUser,
        lastName: user?.lastName || "",
        mailNamePrefix: user?.mailNamePrefix || "",
        title: user?.title || "",
        mailAddress: user?.mailAddress || "",
        gender: user?.gender ?? Gender.Unknown,
        isSuperAdmin: user?.isSuperAdmin || false,
        isDeveloper: user?.isDeveloper || false,
        isBlocked: user?.isBlocked || false,
        isVerified: user?.isVerified || false,
        authority: user?.authority || [withCurrentTenantAuthority ? { tenant: tenant, supervisor: "", isTenantAdmin: false, vacations: []}: {} as ITenantAuthority],
        settings: {
            mails: {
                defaultSalutation: user?.settings?.mails?.defaultSalutation ?? "",
                receivesAnyMail: user?.settings?.mails?.receivesAnyMail ?? true,
                receiveSystemNotifications: user?.settings?.mails?.receiveSystemNotifications ?? true
            },
            absence: {
                absenceNotificationMail: user?.settings?.absence?.absenceNotificationMail || "",
                assignTicketsToDeputy: user?.settings?.absence?.assignTicketsToDeputy ?? true,
                sendAbsenceNotifications: user?.settings?.absence?.sendAbsenceNotifications ?? false,
            }
        }
    });

    const getInitials = (user: DbId<IUser>, mailAddress: string): {firstInitial: string, lastInitial: string} => {

        const u = getUserById(user);
        
        if (!u) return {
            firstInitial: mailAddress.split("@")[0][0].toUpperCase(),
            lastInitial: mailAddress.split("@")[1][0].toUpperCase()
        }
        
        if (u.firstName && u.lastName) return {
            firstInitial: u.firstName[0].toUpperCase(),
            lastInitial: u.lastName[0].toUpperCase()
        };

        return {
            firstInitial: u.mailAddress.split("@")[0][0].toUpperCase(),
            lastInitial: u.mailAddress.split("@")[1][0].toUpperCase()
        }
    }

    const getMail = (user?: DbId<IUser> | null) => {
        const u = getUserById(user);
        return u?.mailAddress ?? "";
    }

    const getName = (user?: DbId<IUser> | null, withMail: boolean = false, short: boolean = false) => {
        const u = getUserById(user);

        if (!u) return "";
        if (!u.firstName && !u.lastName) return u.mailAddress || "";

        if (short) {
            if (!u.firstName) return u.lastName;
            return [u.firstName[0], ".", " ", u.lastName].join("");
        }
        
        if (!withMail) return [u.firstName, u.lastName].join(" ").trim();

        return [u.firstName, u.lastName, `(${u.mailAddress})`].join(" ").trim();
    }

    const getAuthority = (user?: DbId<IUser> | null, matchThisTenant?: ITenant | null): {userAuthority: ITenantAuthority, userAuthorityIndex: number} | undefined => { 
        const u = getUserById(user);

        if (!u || !u.authority || !u.authority.length) return;
        if (!matchThisTenant) matchThisTenant = tenant;
        
        const authorityIndex = u.authority.findIndex(a => {
            if (!a?.tenant) return false;
            if (!matchThisTenant) return false;
            return getId(a.tenant) === getId(matchThisTenant);
        });

        if (authorityIndex < 0) return undefined;

        return {
            userAuthority: u.authority[authorityIndex],
            userAuthorityIndex: authorityIndex
        }
    }

    const getCurrentTenantAuthority = (user: DbId<IUser>): {userAuthority: ITenantAuthority, userAuthorityIndex: number} | undefined => getAuthority(user, tenant);

    const getRole = (user?: DbId<IUser>, matchThisTenant?: ITenant | null): IRole | undefined => { 
        const authority = getAuthority(user, matchThisTenant);
        if (!authority || !authority.userAuthority) return undefined;
        return authority.userAuthority.role;
    }

    const getCurrentTenantRole = (user?: DbId<IUser>): IRole | undefined => getRole(user, tenant);
    
    const getSortedUsersElementTemplate = (role: UserRole): ISortedUsers => ({
        roleName: role,
        members: [] as ISortedUser[]
    })

    const getSortedUser = (user: IUser, index: number): ISortedUser => ({
        originalCollectionIndex: index,
        user: user
    })

    const getTenantManagers = (users: IUser[] | null, excludedUserIds?: string[], tenant?: ITenant ) => {
        if (!users || !users.length) return [];

        const filteredUsers = users.filter(u => {
            if (excludedUserIds && !!excludedUserIds.length && excludedUserIds.includes(u._id)) return false;

            const r = tenant ? getAuthority(u, tenant) : getCurrentTenantAuthority(u);

            if (!r || !r.userAuthority || !r.userAuthority.role) return false;
            return r.userAuthority.isTenantAdmin;
        })

        return filteredUsers;
    }

    const getUsersForRole = (users: (IUser | string)[] | null, roles: UserRole[], excludedUserIds?: string[], tenant?: ITenant): IUser[] => {
        if (!users || !users.length) return [];

        const result: Array<IUser> = [];
        
        for (const userId of users) {

            const u = getUserById(userId);

            if (!u) continue;
            if (excludedUserIds && !!excludedUserIds.length && excludedUserIds.includes(u._id)) continue;

            const r = tenant ? getAuthority(u, tenant) : getCurrentTenantAuthority(u);

            if (!r || !r.userAuthority) continue;

            if (!r.userAuthority.role) continue;

            const matchingRoles = roles.find(role => {
                if (!r.userAuthority.role) return false;
                return role === r.userAuthority.role.displayName
            });

            if (!!matchingRoles && !!matchingRoles.length) result.push(u);
        }

        return result;
    }

    const getSortedUsers = (users: IUser[] | undefined): ISortedUsers[] => {
        const template: ISortedUsers[] = [
            getSortedUsersElementTemplate(UserRole.Partner),
            getSortedUsersElementTemplate(UserRole.TeamLead),
            getSortedUsersElementTemplate(UserRole.Clerk),
            getSortedUsersElementTemplate(UserRole.Secretary),
            getSortedUsersElementTemplate(UserRole.Client),
            {
                roleName: "Keine Rollenzuweisung",
                members: [] as ISortedUser[]
            }
        ]
        
        if (!users || !users.length) return template;

        const result = users.reduce((prev: ISortedUsers[], curr: IUser, currentIndex: number) => {
            const authority = getCurrentTenantAuthority(curr);

            if (!authority || !authority.userAuthority.role || !authority.userAuthority.role.displayName) {
                prev[prev.length - 1].members.push(getSortedUser(curr, currentIndex));
                return prev;
            }

            const index = prev.findIndex(v => v.roleName === authority.userAuthority.role?.displayName);

            if (index >= 0) prev[index].members.push(getSortedUser(curr, currentIndex));
            else prev.push({
                roleName: authority.userAuthority.role.displayName,
                members: [ getSortedUser(curr, currentIndex) ]
            });

            return prev;

        }, template);

        return result;
    }

    const canManageTenant = (user: IUser | null | undefined = currentUser): boolean => {
        if (!user) return false;
        if (user.isSuperAdmin) return true;

        const authority = getCurrentTenantAuthority(user);

        if (!authority || !authority.userAuthority) return false;

        return authority.userAuthority.isTenantAdmin;
    }

    return {
        getCurrentTenantAuthority,
        getAuthority,
        getCurrentTenantRole,
        getRole,
        normalizeUser,
        getSortedUsers,
        getInitials,
        getUsersForRole,
        canManageTenant,
        getTenantManagers,
        getName,
        getMail
    }
}