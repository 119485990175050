import React from "react";
import useUserUtil from "../../hooks/useUserUtil";
import { useClientEmployeeResponsibilities } from "../../state/api/clients/useClientEmployeeResponsibilities";
import { useUsers } from "../../state/api/user/useUsers";
import { IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import UserRoleIndicator from "../user/RoleBanner";
import { IDatevEmployee } from "../../types/employee.schema";
import { useDatevEmployees } from "../../state/api/employees/useDatevEmployees";
import { IWithClientProps } from "./ClientDocumentClearanceReminderForm";
import { getModeForFileReference } from "typescript";
import { getModel } from "../../util/mongoUtil";
import Typography, { ITypographyProps } from "../text/Typography";
import Flex from "../container/Flex";

export interface ISortedClientResponsibility {
  areaOfResponsibility: {
    _id: string,
    id: string,
    name: string
  },
  employees: IUser[]
}

export interface IClientEmployeeResponsibilityListProps extends IWithClientProps {
  onUserClick?: (user: IUser) => Promise<any>,
  row?: boolean,
  labelStyle?: ITypographyProps,
  messageOnEmpty?: string
}

export default function ClientEmployeeResponsibilityList({ row, client, labelStyle, messageOnEmpty = "-", onUserClick }: IClientEmployeeResponsibilityListProps) {

  const [sortedResponsibilities, setSortedResponsibilities] = React.useState<Array<ISortedClientResponsibility>>([]);

  const {
    datevEmployeesById,
    loadingDatevEmployees
  } = useDatevEmployees();

  const {
    clientEmployeeResponsibilities,
    loadingClientEmployeeResponsibilities,
  } = useClientEmployeeResponsibilities(client._id);

  const {
    getCurrentTenantAuthority
  } = useUserUtil();

  const {
    usersById,
    users,
    loadingUsers,
  } = useUsers();

  const findUserForEmployee = (employee: IDatevEmployee): IUser | null | undefined => {
    if (loadingUsers) return null;

    const existingUser = usersById[employee.user];

    if (existingUser) return existingUser;
    if (!users || !users.length) return null;

    return users.find(u => {
      if (!u.mailAddress) return false;
      if (!employee.email) return false;
      const auth = getCurrentTenantAuthority(u);
      if (!auth) return null;
      return u.mailAddress.toUpperCase().trim() === employee.email.toUpperCase().trim();
    });
  }

  React.useEffect(() => {
    if (loadingClientEmployeeResponsibilities) return;
    if (loadingDatevEmployees) return;
    if (loadingUsers) return;

    const result: { [key: string]: ISortedClientResponsibility } = {};

    if (!clientEmployeeResponsibilities || !clientEmployeeResponsibilities.length) return setSortedResponsibilities([]);

    for (const r of clientEmployeeResponsibilities) {
      if (!r.employee) continue;

      const employee = getModel(r.employee, datevEmployeesById);

      if (!employee) continue;

      const user = findUserForEmployee(employee);

      if (!user) continue;

      if (!result[r.area_of_responsibility_name]) result[r.area_of_responsibility_name] = {
        areaOfResponsibility: {
          _id: r._id,
          id: r.area_of_responsibility_id,
          name: r.area_of_responsibility_name
        },
        employees: []
      }

      result[r.area_of_responsibility_name].employees.push(user);
    }

    setSortedResponsibilities(Object.values(result));
  }, [clientEmployeeResponsibilities, datevEmployeesById, loadingUsers, usersById, loadingClientEmployeeResponsibilities]);

  return (
    <WithPermissions permissions={["employeeResponsibilities.all.read"]}>
      <Flex fullWidth row={row} wrap={row} gap={row ? 4 : undefined}>
        {
          loadingClientEmployeeResponsibilities || loadingUsers
            ? <LoadingSpinner text="Verantwortung wird geladen..." />
            : (
              !!sortedResponsibilities?.length
                ? sortedResponsibilities.map(e => {
                  if (!e.employees?.length) return null;

                  return (
                    <Flex key={e.areaOfResponsibility._id}>
                      <Typography {...labelStyle}>{e.areaOfResponsibility.name}</Typography>
                      <Flex row wrap>
                        {
                          e.employees.map(u => (
                            <UserRoleIndicator
                              key={u._id}
                              user={u}
                              onClick={onUserClick ? () => onUserClick(u) : undefined}
                              displayNameAsValue
                              showMail
                            />
                          ))
                        }
                      </Flex>
                    </Flex>
                  )
                })
                : <span>{messageOnEmpty}</span>
            )
        }
      </Flex>
    </WithPermissions>
  )
}