import React from "react";
import { useNavigate } from "react-router-dom";
import { AppRouteParams, AppRoutes, getLink } from "../../config/AppRoutes";
import { useAddressees } from "../../state/api/addressees/useAddressees";
import { IClient } from "../../types/client.schema";
import AddresseeBanner from "../addressee/AddresseeBanner";
import Flex from "../container/Flex";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import UserRoleIndicator from "../user/RoleBanner";
import ClientTypeBanner from "./ClientTypeBanner";

export default function ClientListItem({ client }: { client: IClient }) {

  const { loadingAddressees } = useAddressees();
  const navigate = useNavigate();
  const link = getLink(AppRouteParams.Client, AppRoutes.Client, client._id);

  return (
    <TableRow onClick={() => navigate(link)}>
      <TableCell content={client.number} />
      <TableCell content={client.name} />
      <TableCell>
        {
          loadingAddressees
            ? <ClientTypeBanner client={client} />
            : (
              <Flex>
                <AddresseeBanner addressee={client.naturalPerson} />
                <AddresseeBanner addressee={client.legalPerson} />
              </Flex>
            )
        }
      </TableCell>
      <TableCell>
        {
          client.clientManager && <UserRoleIndicator user={client.clientManager} displayNameAsValue />
        }
      </TableCell>
    </TableRow>
  )
}