import React from "react";
import VerifyUserForm from "../../components/user/VerifyUserForm";
import Page from "../../components/container/Page";
import { Locale } from "../../locale/Locale";

export default function VerifyUser() {
    return (
        <Page fullscreen centered>
            <VerifyUserForm />
        </Page>
    )
}