import React from "react";
import useUserUtil from "../../../hooks/useUserUtil";
import { IUser } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import UserRoleBanner from "../../user/RoleBanner";

export interface IAssignmentUserItemProps {
  user: IUser,
  onClick: () => Promise<any>
}

export default function AssignmentUserItem({ onClick, user }: IAssignmentUserItemProps) {

  const item = <UserRoleBanner user={user} displayNameAsValue displayRoleAtAllTimes onClick={onClick} />

  if (!item) return null;

  return (
    <Button variant="text" className="w-100" align="start">
      {item}
    </Button>
  )
}