import React from "react";
import { teamsUpdateMembersFromAreaOfResponsibility } from "../../api/Api";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useApi from "../../hooks/useApi";
import { useAreasOfResponsibility } from "../../state/api/areasOfResponsibilities/useAreasOfResponsibility";
import { useTeams } from "../../state/api/teams/useTeams";
import Button from "../buttons/Button";
import WithPermissions from "../permissions/WithPermissions";
import { ITeam } from "../../types/team.schema";

export default function TeamUpdateMembersFromResponsibilityButton({ team }: { team: ITeam }) {
  const callApi = useApi();

  const { areasOfResponsibility, loadingAreasOfResponsibility } = useAreasOfResponsibility();
  const { reloadTeams } = useTeams();

  const clickHandler = async () => {
    const res = await callApi(teamsUpdateMembersFromAreaOfResponsibility({ id: team._id }));
    if (!res || !res.success) return;
    await reloadTeams();
  }

  return (
    <WithPermissions permissions={["interfaces.datev.update"]}>
      <Button
        text="Team aus DATEV abrufen"
        loading={loadingAreasOfResponsibility}
        loadingText="Zuständigkeiten werden abgerufen"
        disabled={!areasOfResponsibility || !areasOfResponsibility.length || !team.areaOfResponsibility}
        disabledText={!areasOfResponsibility || !areasOfResponsibility.length ? "Keine Zuständigkeiten importiert" : "Keine Zuständigkeit zugewiesen"}
        onClick={clickHandler}
        disabledIcon="x"
        icon="person-plus-fill"
        color="success"
      />
    </WithPermissions>
  )
}