import React from "react";
import { ticketUpdateAccessHistory } from "../../api/Api";
import Button from "../../components/buttons/Button";
import Flex from "../../components/container/Flex";
import Page from "../../components/container/Page";
import PageHeader from "../../components/label/PageHeader";
import LoadingSpinner from "../../components/loader/LoadingSpinner";
import Typography from "../../components/text/Typography";
import TicketFriendlyIdBanner from "../../components/ticketing/banner/TicketFriendlyIdBanner";
import NextTicketButton from "../../components/ticketing/lists/NextTicketButton";
import TicketChatSidebar from "../../components/ticketing/sidebar/TicketChatSidebar";
import TicketChat from "../../components/ticketing/ticketChat/TicketChat";
import { AppRouteParams } from "../../config/AppRoutes";
import useFilteredTickets from "../../hooks/useFilteredTickets";
import useRouteParam from "../../hooks/useRouteParam";
import useTicketUtil from "../../hooks/useTicketUtil";
import { useTicket } from "../../state/api/tickets/useTicket";
import { useCurrentTenantRole } from "../../state/api/user/useCurrentTenantRole";

export default function Ticket() {

  const ticketId = useRouteParam(AppRouteParams.TicketId);

  const ticketAccessHistoryInterval = React.useRef<any>();

  const { role } = useCurrentTenantRole();
  const { query } = useFilteredTickets();
  const {
    getSubject
  } = useTicketUtil();

  const { ticket, loadingTicket } = useTicket(ticketId || "");

  const backLink = `/?${query.toString()}`;

  React.useEffect(() => {
    clearInterval(ticketAccessHistoryInterval.current);

    if (!ticketId) return;
    if (!ticket) return;

    ticketAccessHistoryInterval.current = setInterval(() => ticketUpdateAccessHistory({ ticketId: ticket._id }).catch(err => { }), 1000 * 60);

    return () => clearInterval(ticketAccessHistoryInterval.current);
  }, [ticket, ticketId]);


  return (
    <Page
      rightSidebar={(role && role.isClient) || !ticket
        ? undefined
        : <TicketChatSidebar ticket={ticket.ticket} />
      }
      header={
        <Flex row className="w-100" justify="between">
          <PageHeader
            loading={loadingTicket}
            backLink={backLink}
            text={loadingTicket ? "Ticket" : getSubject(ticket?.ticket, role && !role.isClient)}
          />
          {
            ticket && (
              <Flex row>
                <TicketFriendlyIdBanner ticket={ticket.ticket} size={14} bold />
                <NextTicketButton ticket={ticket.ticket} />
              </Flex>
            )
          }
        </Flex>
      }
    >
      {
        loadingTicket
          ? <LoadingSpinner color="primary" text="Lädt Nachrichten..." />
          : (
            ticket
              ? <TicketChat hydratedTicket={ticket} />
              : (
                <Flex fullWidth fullHeight align="center">
                  <Typography color="primary" size={24} bold>Ticket nicht verfügbar</Typography>
                  <Typography wrap size={12}>Es ist ein Fehler beim Laden des Tickets aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.</Typography>
                  <Button to={backLink} color="primary" className="mt-3">Zurück zur Übersicht</Button>
                </Flex>
              )
          )
      }
    </Page>
  )
}