import React from "react";
import { salutationsDelete, salutationsDeleteGlobal } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useSalutations } from "../../state/api/salutations/useSalutations";
import { ISalutation } from "../../types/salutation.schema";
import Button from "../buttons/Button";

export interface IDeleteSalutationButtonProps {
    salutation: ISalutation,
    isGlobal: boolean
}

export default function DeleteSalutationButton(props: IDeleteSalutationButtonProps) {

    const {
        isGlobal,
        salutation
    } = props;
    
    const { reloadSalutations } = useSalutations(isGlobal);

    const callApi = useApi();

    if (isGlobal !== salutation.isGlobalTemplate) return null;

    return (
        <Button
            text="Löschen"
            icon="trash"
            color="error"
            secondStepQuestion="Sicher?"
            disabled={salutation.isGlobalDefault}
            onClick={async () => {
                const res = await callApi(
                    salutation.isGlobalTemplate
                    ? salutationsDeleteGlobal({ id: salutation._id })
                    : salutationsDelete({ id: salutation._id })
                );

                if (!res?.success) return;

                await reloadSalutations();
            }}
        />
    );
}