import React from "react";
import useTaskUtil from "../../hooks/useTaskUtil";
import { ITask } from "../../types/task.schema";
import { getDateInXFrom, getWeekOfYear } from "../../util/util";
import Card from "../card/Card";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import "./MonthlyCalendar.css";

export interface IMonthlyCalendarProps {
  selectedDay: Date;
  uniColor?: boolean;
  color?: string;
  min?: Date;
  max?: Date;
  appointments?: { [key: string]: ITask[] };
  recurringAppointments?: { [key: string]: ITask[] };
  onChange: (d: Date) => void;
}

export default function MonthlyCalendar(props: IMonthlyCalendarProps) {

  const {
    onChange,
    recurringAppointments,
    color = "background",
    appointments,
    uniColor,
    max,
    min,
    selectedDay
  } = props;

  const {
    getDateId
  } = useTaskUtil();

  const [day, setDay] = React.useState<number>(selectedDay.getDate());
  const [month, setMonth] = React.useState<number>(selectedDay.getMonth());
  const [year, setYear] = React.useState<number>(selectedDay.getFullYear());

  React.useEffect(() => {
    if (min && min > selectedDay) onChange(min);
    if (max && max < selectedDay) onChange(max);
  }, []);

  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);

  const getDayOfWeek = (d: Date) => {
    const dayOfWeek = d.getDay();
    return dayOfWeek === 0 ? 7 : dayOfWeek;
  }

  const daysFromPrevMonth = getDayOfWeek(firstDay) - 1;
  const daysFromNextMonth = 7 - getDayOfWeek(lastDay);

  const dayCount = lastDay.getDate() + daysFromPrevMonth + daysFromNextMonth;
  const start = getDateInXFrom({ days: -daysFromPrevMonth }, firstDay);
  const today = new Date();

  const checkInactive = (d: Date) => {
    if (min && getDateInXFrom({ minutes: -1 }, min) > d) return true;
    if (max && getDateInXFrom({ minutes: 1 }, max) < d) return true;
    return false;
  }

  const changeHandler = (date: Date) => {
    if (checkInactive(date)) return;
    updateView(date.getFullYear(), date.getMonth(), date.getDate());
    onChange(date);
  }

  const updateView = (year: number, month: number, day: number) => {
    let newMonth = month;
    let newYear = year;
    let newDay = day;

    if (month < 0) {
      newMonth = 11;
      newYear = year - 1;
    }

    if (month > 11) {
      newMonth = 0;
      newYear = year + 1;
    }

    if (day < 1) {
      newDay = new Date(newYear, newMonth, 0).getDate();
      newMonth = newMonth - 1;
    }

    if (day > new Date(newYear, newMonth + 1, 0).getDate()) {
      newDay = 1;
      newMonth = newMonth + 1;
    }

    setYear(newYear);
    setMonth(newMonth);
    setDay(newDay);
  }

  const createNavIcon = (icon: string, year: number, month: number, day: number) => {

    if (checkInactive(new Date(year, month, day))) return (
      <Icon
        size={12}
        icon={icon}
        color="muted"
      />
    )

    return (
      <Icon
        size={12}
        icon={icon}
        color="primary"
        onClick={() => updateView(year, month, day)}
      />
    )
  }

  return (
    <Card
      color={color}
      uniColor={uniColor}
      header={(
        <Flex fullWidth row justify="between">
          <Flex row gap="1">
            <Typography color="primary" bold>
              {firstDay.toLocaleString("default", { month: "long" })}
            </Typography>
            <Typography color="secondary">
              {year}
            </Typography>
          </Flex>
          <Flex row gap="1">
            <Flex row gap="0">
              {createNavIcon("chevron-double-left", year - 1, month, day)}
              {createNavIcon("chevron-left", year, month - 1, day)}
            </Flex>
            <Flex onClick={() => changeHandler(today)}>
              <Typography
                color="secondary"
                upper
                size="10"
                userSelect="none"
              >
                Heute
              </Typography>
            </Flex>
            <Flex row gap="0">
              {createNavIcon("chevron-right", year, month + 1, day)}
              {createNavIcon("chevron-double-right", year + 1, month, day)}
            </Flex>
          </Flex>
        </Flex>
      )}

    >
      <div className="month-overview">
        <div />
        {
          new Array(7).fill(0).map((_, index) => {
            const date = getDateInXFrom({ days: index }, start);
            return (
              <Typography
                size="12"
                color="muted"
                align="center"
                key={`weekday-monthly-calendar-${index}`}
              >
                {date.toLocaleString("default", { weekday: "short" })}
              </Typography>
            )
          })
        }
        {
          new Array(dayCount).fill(0).map((_, index) => {

            const x = getDateInXFrom({ days: index }, start);
            const y = x.toDateString();

            const isInactive = checkInactive(x);

            const isCurrentMonth = x.getMonth() === month;
            const isNewWeek = x.getDay() === 1;

            const isSelected = y === selectedDay.toDateString();
            const isToday = y === today.toDateString();

            const getColor = () => {
              if (isSelected) return "secondary";
              if (isToday) return "error";
              if (!isCurrentMonth || isInactive) return "#FFFFFF00";
              return "bright";
            }

            const getHoverColor = () => {
              if (!isSelected && !isToday) return "backgroundDarker";
              return undefined;
            }

            const getTextColor = () => {
              if (isToday) return "#FFF";
              if (isInactive) return "muted";
              if (!isCurrentMonth) return "backgroundDarkest";
              return undefined;
            }

            const getAppointmentColor = () => {
              if (isSelected) return "primary";
              else if (isToday) return "bright";
              else return "muted"
            }

            const textColor = getTextColor();
            const color = getColor();

            const hasAppointment = !!appointments?.[getDateId(x)] || !!recurringAppointments?.[getDateId(x)];

            return (
              <React.Fragment key={`day-picker-${index}`}>
                {
                  isNewWeek && (
                    <Flex fullHeight fullWidth justify="center" key={`day-picker-week-${index}`}>
                      <Typography color="muted" size="12">
                        {getWeekOfYear(x)}
                      </Typography>
                    </Flex>
                  )
                }
                <Pill
                  key={`month-select-day-${index}`}
                  variant="badge"
                  hoverColor={getHoverColor()}
                  justify="center"
                  align="center"
                  onClick={() => changeHandler(x)}
                  color={color}
                >
                  <Flex align="center" justify="center" gap="0">
                    <Typography
                      size="11"
                      color={textColor}
                      noLinePadding
                      basedOnThisBackground={textColor ? undefined : color}
                    >
                      {x.getDate()}
                    </Typography>
                    {
                      hasAppointment && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "5px",
                            height: "3px",
                            width: "3px",
                            borderRadius: "50px",
                            backgroundColor: `var(--${getAppointmentColor()})`
                          }}
                        />
                      )
                    }
                  </Flex>
                </Pill>
              </React.Fragment>
            )
          })
        }
      </div>
    </Card>
  )
}