
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IAlias } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAnyUserAlias = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IAlias>>(ApiRoutes.User.Alias.getValue({ param: RouteParam.UserId, value: id}));

    return {
        userAlias: data,
        loadingUserAlias: isLoading,
        reloadUserAlias: mutate
    }
}