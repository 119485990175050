import React from "react";
import { formatDate } from "../../../util/formatter";
import Button from "../../buttons/Button";
import CopyToClipboardButton from "../../buttons/CopyToClipboardButton";
import Flex from "../../container/Flex";
import ModalDialog from "../../modal/ModalDialog";
import LogEntryEventTypeBanner from "./LogEntryEventTypeBanner";
import LogEntrySystemBanner from "./LogEntrySystemBanner";
import LogEntryTypeBanner from "./LogEntryTypeBanner";
import { ExternalService, ILogEntry } from "../../../types/log.schema";
import { useTenants } from "../../../state/api/tenant/useTenants";
import { getModel } from "../../../util/mongoUtil";
import ExternalServiceBanner from "../../services/ExternalServiceBanner";
import { AppRouteParams, AppRoutes } from "../../../config/AppRoutes";
import { useOfficeAccounts } from "../../../state/api/office/useOfficeAccounts";
import Pill from "../../pills/Pill";
import Typography from "../../text/Typography";
import useOfficeAccountUtil from "../../../hooks/useOfficeAccountUtil";

export default function LogEntryForm({ log }: { log: ILogEntry }) {

  const [raw, setRaw] = React.useState<boolean>(false);

  const {
    tenantsById
  } = useTenants();

  const tenant = getModel(log.tenant, tenantsById);

  return (
    <ModalDialog
      title={`Log-Eintrag vom ${formatDate(log.updatedAt, true)}`}
      button={<Button variant="icon" icon="eye" />}
    >
      {
        close => (
          <Flex gap={3} className="w-100">
            <Flex row justify="between" fullWidth>
              <Flex row>
                <LogEntrySystemBanner log={log} />
                <LogEntryTypeBanner log={log} />
                <LogEntryEventTypeBanner log={log} />
                <ExternalServiceBanner service={log.service} />
              </Flex>
              <Button onClick={() => setRaw(!raw)} text={raw ? "Formatiert" : "Rohdaten"} />
            </Flex>
            {
              raw
              ? (
                <Flex fullWidth fullHeight style={{wordBreak: "break-word"}}>
                  {JSON.stringify(log)}
                </Flex>
              )
              : (
                  <>
                    <Flex gap={1} className="w-100">
                      <strong>ID</strong>
                      <span>{log._id}</span>
                    </Flex>
                    {
                      tenant && (
                        <Flex gap={1} className="w-100">
                          <strong>Tenant</strong>
                          <span>{tenant.name}</span>
                        </Flex>
                      )
                    }
                    <Flex gap={1} className="w-100">
                      <Flex row justify="between" fullWidth fullHeight>
                        <strong>Inhalt</strong>
                        <CopyToClipboardButton value={log.content} />
                      </Flex>
                      <span className="w-100 h-100 text-wrap text-break">{log.content}</span>
                    </Flex>
                    {
                      log.additionalInfo && (
                        <Flex gap={1} className="w-100">
                          <Flex row justify="between" className="w-100">
                            <strong>Weitere Informationen</strong>
                            <CopyToClipboardButton value={log.additionalInfo} />
                          </Flex>
                          <span className="w-100 text-wrap text-break">{log.additionalInfo}</span>
                        </Flex>
                      )
                    }
                    {
                      log.eventId && (
                        <Flex gap={1} className="w-100">
                          <Flex row justify="between" className="w-100">
                            <strong>Event-ID</strong>
                            <CopyToClipboardButton value={log.eventId} />
                          </Flex>
                          <span className="w-100 text-wrap text-break">{log.eventId}</span>
                        </Flex>
                      )
                    }
                    {
                      log.methodName && (
                        <Flex gap={1} className="w-100">
                          <strong>Aufruf</strong>
                          <span className="w-100 text-wrap text-break">{log.methodName}</span>
                        </Flex>
                      )
                    }
                    {
                      log.affectedEntities && (
                        <Flex fullWidth row>
                          <AffectedTicket log={log} />
                          <AffectedOfficeAccount log={log} />
                        </Flex>
                      )
                    }
                  </>
                )
            }
            <Button className="align-self-end" onClick={async () => close()} text="Schließen" icon="x" />
          </Flex>
        )
      }
    </ModalDialog>
  )
}

function AffectedTicket({ log }: { log: ILogEntry }) {
  if (!log?.affectedEntities?.ticket) return null;
  return <Button to={AppRouteParams.TicketId.getPath(AppRoutes.Ticket, log.affectedEntities.ticket)} text="Zum Ticket" />
}

function AffectedOfficeAccount({ log }: { log: ILogEntry }) {
  const {
    officeAccountsById
  } = useOfficeAccounts();

  const {
    getColor,
    getIcon
  } = useOfficeAccountUtil();

  if (!log?.affectedEntities?.officeAccount) return null;

  const officeAccount = officeAccountsById[log.affectedEntities.officeAccount];

  if (!officeAccount) return null;

  const color = getColor(officeAccount.type);

  return (
    <Flex gap="1">
      <Typography bold>Office-Konto</Typography>
      <Pill
        color={color}
        icon={getIcon(officeAccount.type)}
      >
        <Flex gap="0">
          <Typography basedOnThisBackground={color}>{officeAccount.accountName}</Typography>
          <Typography basedOnThisBackground={color} size="10">{officeAccount.accountMail}</Typography>
        </Flex>
      </Pill>
    </Flex>
  )
}