import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import { officeAccountsMoveMailToTicketFolder } from "../../../../api/Api";
import { useOfficeAcountFolderMails } from "../../../../state/api/office/useOfficeAccountFolderMails";
import { IOfficeAccount } from "../../../../types/officeAccount.types";
import { getMailAddressFromOfficeMail } from "../../../../util/formatter";
import CreateTicketForm from "../../../ticketing/CreateTicketModal";

export interface IConvertMailToTicketButtonProps {
  account: IOfficeAccount,
  mail: Message,
  mailId?: string,
  folderId?: string
}

export default function ConvertMailToTicketButton({ account, folderId, mailId, mail }: IConvertMailToTicketButtonProps) {

  const { reloadFolderMails } = useOfficeAcountFolderMails(account._id, folderId || "");

  if (!account?._id) return null;
  if (!mailId || !folderId) return null;

  const reloadHandler = async () => {
    await officeAccountsMoveMailToTicketFolder({ id: account._id, messageId: mailId });
    await reloadFolderMails();
  }

  return (
    <CreateTicketForm
      createFromMailId={mailId}
      firstMessageIsFromClient
      createFromMailAccountId={account._id}
      afterCreate={reloadHandler}
      variant="outline"
      buttonText="In Ticket umwandeln"
      initialMailAddress={getMailAddressFromOfficeMail(mail.from)}
      initialMessage={mail.body?.content?.toString()}
      initialSubject={mail.subject?.toString()}
      title="Ticket aus Mail erstellen"
    />
  );
}