import React from "react";
import { clientsCreateAlias, IApiResponse } from "../../api/Api";
import { ICreateAliasRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import { useClient } from "../../state/api/clients/useClient";
import { useAnyClientAlias } from "../../state/api/clients/useAnyClientAlias";
import { useClients } from "../../state/api/clients/useClients";
import { IWithClient } from "../../types/client.element.schema";
import AliasForm from "../alias/AliasForm";
import Button from "../buttons/Button";
import ModalForm, { IModalFormBase } from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import "./ImportClientsForm.css";

export interface IUpdateClientAliasDialogProps extends IWithClient, IModalFormBase { }

export default function UpdateClientAliasDialog(props: IUpdateClientAliasDialogProps) {

  const {
    client,
  } = props;

  const callApi = useApi();

  const { reloadClients } = useClients();
  const { reloadClient } = useClient(client._id);
  const { clientAlias, loadingClientAlias, reloadClientAlias } = useAnyClientAlias(client._id);

  return (
    <WithPermissions permissions={["clients.all.update"]} >
      <ModalForm
        button={props.button ?? <Button size="small" variant="subtle" text="Bearbeiten" icon="pen" />}
        title="Alias bearbeiten"
        initialValues={{
          aliasMailAddress: "",
          id: client._id
        } as ICreateAliasRequest}
        enableReinitialize
        onSubmit={async (values) => {
          const res: IApiResponse | null = await callApi(clientsCreateAlias(values));

          if (!res || !res.success) return false;

          await reloadClients();
          await reloadClientAlias();
          await reloadClient();

          return true;
        }}
        submitText="Alias hinzufügen"
      >
        {
          formik => (
            <AliasForm
              data={clientAlias}
              isLoading={loadingClientAlias}
              mutate={reloadClientAlias}
            />
          )
        }
      </ModalForm>
    </WithPermissions>
  )
}