import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITask } from "../../../types/task.schema";

export const useTicketTasks = (ticketId: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<ITask[]>(ApiRoutes.Tickets.Tasks.getValue({ param: RouteParam.TicketId, value: ticketId }));

    return {
        ticketTasks: data,
        loadingTicketTasks: isLoading,
        reloadTicketTasks: mutate
    }
} 