
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IFeature } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useFeatures = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IFeature>>(ApiRoutes.Subscriptions.FeaturesGetAll);

    return {
        features: data,
        loadingFeatures: isLoading,
        reloadFeatures: mutate
    }
}