import React from "react";
import { DatevAddresseeType, IAddressee } from "../../types/ApiTypes";
import useAddresseeUtil from "../../hooks/useAddresseeUtil";
import Pill from "../pills/Pill";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import AddresseeAddress from "./AddresseeAddress";
import IElementProps from "../../types/element.types";
import { useLetterColors } from "../../hooks/useLetterColors";
import { useAddressees } from "../../state/api/addressees/useAddressees";
import { getModel } from "../../util/mongoUtil";
import { useLegalForms } from "../../state/api/legalForms/useLegalForms";
import { useNavigate } from "react-router-dom";
import { AppRouteParams, AppRoutes } from "../../config/AppRoutes";

export interface IAddresseePillProps extends IElementProps {
  addressee: IAddressee | string,
  showName?: boolean,
  showAddress?: boolean,
  isLink?: boolean,
  hideLegalForm?: boolean
}

export default function AddresseeBanner(props: IAddresseePillProps) {

  const {
    addressee,
    showName,
    isLink,
    showAddress,
    hideLegalForm
  } = props;

  const navigate = useNavigate();

  const {
    addresseesById
  } = useAddressees({ enabled: typeof addressee === "string" });

  const {
    getFirstLetterColor
  } = useLetterColors();

  const { loadingLegalForms } = useLegalForms();

  const {
    getAddresseeLegalForm,
    getAddresseeTypeColor,
    getAddresseeTypeIcon
  } = useAddresseeUtil();

  const usableAddressee = getModel<IAddressee>(addressee, addresseesById);

  if (!usableAddressee) return null;

  const color = getAddresseeTypeColor(usableAddressee);
  const icon = getAddresseeTypeIcon(usableAddressee);
  const text = getAddresseeLegalForm(usableAddressee);

  const legalForm = getAddresseeLegalForm(usableAddressee);

  ;

  return (
    <Pill
      onClick={isLink ? () => navigate(AppRouteParams.Addressee.getPath(AppRoutes.Addressee, usableAddressee._id)) : undefined}
      color={color}
      icon={icon}
      iconColor={usableAddressee.type === DatevAddresseeType.LegalPerson && usableAddressee.legalForm && legalForm ? getFirstLetterColor(legalForm) : undefined}
      withDivider
      {...props}
    >
      <Flex gap="3">
        <Flex gap="0">
          {showName && <Typography basedOnThisBackground={color} bold >{usableAddressee.actualName}</Typography>}
          {!hideLegalForm && <Typography size={showName ? "10" : undefined} basedOnThisBackground={color}>{text}</Typography>}
        </Flex>
        {showAddress && <AddresseeAddress basedOnThisBackground={color} addressee={usableAddressee} />}
      </Flex>
    </Pill>
  )

}

