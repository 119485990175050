import React from "react";
import { generateClassName, generateStyle } from "../../../../hooks/useAttributes";
import { useMailfolders } from "../../../../state/api/mailfolders/useMailfolders";
import { IMailfolder } from "../../../../types/mailfolder.schema";
import { IOfficeAccount } from "../../../../types/officeAccount.types";
import { formatNumber } from "../../../../util/formatter";
import Flex from "../../../container/Flex";
import Icon from "../../../icons/Icon";
import Typography from "../../../text/Typography";
import "./OfficeFolder.css";
import OfficeFolderIcon from "./OfficeFolderIcon";

export interface IOfficeMailFolder {
  folder: IMailfolder,
  activeFolder?: IMailfolder,
  hierarchyLevel: number,
  account: IOfficeAccount,
  onChange: (folder: IMailfolder) => void,
}

export default function OfficeFolder(props: IOfficeMailFolder) {

  const {
    folder,
    activeFolder,
    onChange,
    hierarchyLevel = 0,
    account
  } = props;

  const [expanded, setExpanded] = React.useState<boolean>(false);

  const {
    mailfoldersByParentFolderId
  } = useMailfolders(account._id);

  const childFolders = mailfoldersByParentFolderId[folder.folderId ?? ""];
  const isActive = activeFolder?._id === folder._id;

  const clickHandler = () => {
    onChange(folder);
    if (!childFolders?.length) return;
    setExpanded(!expanded);
  }

  const hierarchyPadding = 25;

  const parentStyle = generateStyle({
    value: (hierarchyLevel + 1) * hierarchyPadding,
    name: "paddingLeft",
    unit: "px"
  })

  if (!folder?.displayName) return null;
  if (hierarchyLevel === 0 && !folder.isRoot) return null;

  return (
    <Flex className="w-100" gap={1}>
      <Flex
        row
        gap="0"
        onClick={clickHandler}
        className={generateClassName("office-folder w-100 position-relative", { value: isActive, onTrue: "office-folder-active" })}
      >
        <div className={generateClassName("office-folder-background position-absolute", { value: isActive, onTrue: "office-folder-background-active" })} />
        <Flex row style={{ width: hierarchyPadding }} justify="center">
          {
            !!childFolders?.length && <Icon color="secondary" icon={expanded ? "chevron-down" : "chevron-right"} />
          }
        </Flex>
        <Flex row className="pe-3 p-2 h-100 w-100" justify="between">
          <Flex row>
            <Flex style={{ width: hierarchyPadding }}>
              <OfficeFolderIcon folder={folder} />
            </Flex>
            <Typography size={12} color="primary">{folder.displayName}</Typography>
          </Flex>
          {
            !!folder.unreadItemCount && (
              <Flex align="center" justify="center" style={{ backgroundColor: `var(--${isActive ? "primary" : "secondary"})`, height: "20px", minWidth: "20px", borderRadius: "50px" }}>
                <Typography basedOnThisBackground="primary" size="9" noLinePadding className="ps-1 pe-1">{formatNumber(folder.unreadItemCount)}</Typography>
              </Flex>
            )
          }
        </Flex>
      </Flex>
      {
        !!childFolders?.length && (
          <Flex style={parentStyle} className="w-100" gap={0}>
            {
              expanded && childFolders.map((f: IMailfolder) => (
                <OfficeFolder
                  {...props}
                  folder={f}
                  key={f._id}
                  hierarchyLevel={hierarchyLevel + 1}
                />
              ))
            }
          </Flex>
        )
      }
    </Flex>
  )
}