
import { IUser } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { Sorted } from "../../../types/collection.types";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useClientContacts = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<IUser>>(ApiRoutes.User.ClientContacts);

    return {
        clientContacts: data?.items ?? [],
        clientContactsById: data?.byId ?? {},
        loadingClientContacts: isLoading,
        reloadClientContacts: mutate
    }
}