import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import { useTicketSidebarHistory } from "../../../hooks/useTicketSidebarHistory";
import { ITicket } from "../../../types/ApiTypes";
import { formatDate } from "../../../util/formatter";
import SidebarHeader from "../../menu/sidebar/SidebarHeader";
import TicketMessageText from "../ticketChat/messages/TicketMessageText";
import "./TicketMessageHistoryTab.css";
import { ITicketMessage, ITicketMessageText } from "../../../types/ticketMessage.schema";
import { getId } from "../../../util/mongoUtil";
import { useTicketMessage } from "../../../state/api/tickets/messages/useTicketMessage";
import LoadingSpinner from "../../loader/LoadingSpinner";

export default function TicketMessageHistoryTab({ticket}: {ticket: ITicket}) {

    const {
        messageId
    } = useTicketSidebarHistory();

    const {
      loadingMessage,
      message
    } = useTicketMessage(messageId);

    if (loadingMessage) return <LoadingSpinner />
    if (!message) return <span>Kein Verlauf</span>

    return (
        <>
            <SidebarHeader text="Bearbeitungsverlauf" />
            <div className="d-flex flex-column gap-2 h-100" style={{overflowY: "auto"}}>
                {
                    message.content.map((v: ITicketMessageText, index: number) => {

                        const className = generateClassName("d-flex flex-column message-history-item p-2 ps-3 pe-3", {
                            value: index === message.content.length - 1,
                            onTrue: "message-history-item-current"
                        })

                        return (
                            <div className={className} key={`ticket-chat-sidebar-history-item-${index}`}>
                                <strong>{formatDate(v.createdAt, true, true)}</strong>
                                <TicketMessageText message={message} ticketId={ticket._id} text={v.text} />
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}