import React from "react";
import Button from "../buttons/Button";
import OtpField from "./OtpField";

export default function OtpInput({ saveOtp, readOnly = false }: { readOnly?: boolean, text?: string, saveOtp: (otp: string) => void }) {
  const [isVerificationError, setIsVerificationError] = React.useState<boolean>(false);
  const [parts, setParts] = React.useState<Array<string>>([]);

  const setOtpValue = (index: number, value: string | null) => {
    setIsVerificationError(false);

    const newValue: Array<string> = [...parts];

    if (value) newValue[index] = value;
    else newValue[index] = "";

    setParts(newValue);

    const otp = newValue.join("").trim();

    saveOtp(otp);
  }

  const handlePaste = (val: Array<string>) => {
    if (!val || !val.length) return;

    setParts(val);
  }

  const getValueForField = (index: number) => {
    if (!parts || !parts.length || index >= parts.length) return "";

    const value = parts[index];

    if (!value) return "";

    return value;
  }

  return (
    <>
      {
        (parts && !!parts.length) && <Button variant="icon" icon="x" iconSize={48} onClick={async () => setParts([])} color="error" />
      }
      <OtpField savePastedValue={handlePaste} value={getValueForField(0)} hasFocusAtStart error={isVerificationError} index={0} saveValue={setOtpValue} readOnly={readOnly} />
      <OtpField savePastedValue={handlePaste} value={getValueForField(1)} error={isVerificationError} index={1} saveValue={setOtpValue} readOnly={readOnly} />
      <OtpField savePastedValue={handlePaste} value={getValueForField(2)} error={isVerificationError} index={2} saveValue={setOtpValue} readOnly={readOnly} />
      <OtpField savePastedValue={handlePaste} value={getValueForField(3)} error={isVerificationError} index={3} saveValue={setOtpValue} readOnly={readOnly} />
      <OtpField savePastedValue={handlePaste} value={getValueForField(4)} error={isVerificationError} index={4} saveValue={setOtpValue} readOnly={readOnly} />
      <OtpField savePastedValue={handlePaste} value={getValueForField(5)} error={isVerificationError} index={5} saveValue={setOtpValue} readOnly={readOnly} />
    </>
  )
}