import React from "react";
import useOpeningHoursUtil from "../../hooks/useOpeningHoursUtil";
import { ITask } from "../../types/task.schema";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import UpdateTaskButton from "../tasks/UpdateTaskButton";
import { IAgendaProps } from "./CalendarOverview";
import "./DailyAgenda.css";
import DailyAgendaSkeleton from "./DailyAgendaSkeleton";
import Appointment from "./Appointment";
import useTaskUtil from "../../hooks/useTaskUtil";

export interface IDailyAgendaProps extends IAgendaProps {
  day: Date,
  appointments?: Array<ITask>
}

export default function DailyAgenda(props: IDailyAgendaProps) {

  const {
    day,
    selectedDate,
    appointments,
    displayedDays
  } = props;

  const {
    mapTasksForDay
  } = useTaskUtil();

  const isSelected = day.toDateString() === selectedDate.toDateString();

  const map = mapTasksForDay(day, appointments);

  return (
    <DailyAgendaSkeleton
      id={`daily-agenda-${day.getDay()}`}
      renderItem={(index) => (
        <CalendarDayHour
          key={`daily-agenda-${day.getDay()}-${index}`}
          hour={index}
          date={day}
          isSelected={isSelected}
        />
      )}
      style={{ width: `calc(100% / ${displayedDays})` }}
    >
      {
        appointments?.map((t, i) => (
          <Appointment
            key={`${t._id}_${i}`}
            mappedTasks={map}
            day={day}
            task={t}
          />
        ))
      }
    </DailyAgendaSkeleton>
  )
}

function CalendarDayHour(props: { date: Date, hour: number, isSelected: boolean }) {

  const {
    hour,
    date,
    isSelected
  } = props;

  const {
    weekOpeningHours
  } = useOpeningHoursUtil();

  const isTenantOpenAtHour = () => {
    const dayOfWeek = date.getDay();
    const openingHours = weekOpeningHours[dayOfWeek];
    if (!openingHours) return dayOfWeek > 0 && dayOfWeek <= 6 && hour >= 8 && hour <= 17;
    return openingHours.has(hour);
  }

  const isOpen = isTenantOpenAtHour();

  const closedColor = isSelected ? "var(--background)" : "var(--backgroundLighter)";
  const openColor = isSelected ? "#FFF" : "var(--backgroundLightest)";

  const dateForTask = new Date(date);
  dateForTask.setHours(hour);
  dateForTask.setMinutes(0);

  return (
    <Flex
      fullWidth
      fullHeight
      style={{
        border: `1px solid var(--backgroundLightest)`,
        backgroundColor: isOpen ? openColor : closedColor,
      }}
    >
      <UpdateTaskButton
        start={dateForTask}
        button={(
          <Flex
            fullWidth
            fullHeight
            align="center"
            justify="center"
            className="calendar-day-hour"
          >
            <Icon className="calendar-day-hour-add-task-icon" icon="plus" size={50} color={isOpen ? "primary" : "error"} />
          </Flex>
        )}
      />
    </Flex>
  )
}