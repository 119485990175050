import React from "react";
import { sessionCreateImposter } from "../../api/Api";
import { ISessionCreateImposterRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import { useSession } from "../../state/api/session/useSession";
import { useUser } from "../../state/api/user/useUser";
import { useUsers } from "../../state/api/user/useUsers";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import CheckBox from "../forms/CheckBox";
import FormikField from "../forms/FormikField";
import ModalForm from "../modal/ModalForm";
import UserSelect from "../user/UserSelect";

export default function CreateImposterSessionButton() {
    const { setSessionToken, reloadSession } = useSession();

    const { user } = useUser();
    const { users } = useUsers();

    const callApi = useApi();

    if (!user || !user.isSuperAdmin) return null;
    if (!users || !users.length) return null;

    return (
        <ModalForm
            button={<Button color="error" text="REMOTE" icon="bug" />}
            title="Debug-Session starten"
            initialValues={{
                minutes: 30,
                user: "",
                employeesOnly: true
            } as ISessionCreateImposterRequest & { employeesOnly: boolean }}
            onSubmit={async (values) => {
                const session = await callApi(sessionCreateImposter(values));

                if (!session || !session.success) return false;

                setSessionToken(session.data.token);
                await reloadSession();
                return true;
            }}
        >
            {
                formik => (
                    <Flex fullWidth>
                        <UserSelect 
                            displayed={formik.values.employeesOnly ? "employees" : "all"}
                            onChange={u => formik.setFieldValue("user", u ? u._id : "")}
                            value={formik.values.user}
                            label="Nutzer"
                        />
                        <CheckBox name="employeesOnly" label="Nur Mitarbeiter anzeigen" /> 
                        <FormikField className="w-100" name="duration" type="number" min="0" max="90" label="Dauer in Minuten"/>
                    </Flex>
                )
            }
        </ModalForm>
    )
}