import React from "react";
import { AppRoutes } from "../../../config/AppRoutes";
import { generateClassName } from "../../../hooks/useAttributes";
import useTenantSettings from "../../../hooks/useTenantSettings";
import { useTenant } from "../../../state/api/tenant/useTenant";
import { TenantLogoStyle } from "../../../types/ApiTypes";
import Hyperlink from "../../links/Hyperlink";
import Typography from "../../text/Typography";
import "./SideNavLogo.css";

export default function SideNavLogo() {
  const { tenant } = useTenant();

  const {
    theme,
    ui,
    titles
  } = useTenantSettings();

  const {
    sidebar
  } = ui;

  const {
    logoStyle
  } = sidebar;

  const logoClass = generateClassName("side-nav-logo", {
    value: logoStyle === TenantLogoStyle.LogoOnly,
    onTrue: "side-nav-logo-logo-only"
  })

  return (
    <Hyperlink to={AppRoutes.Home.path} className="navbar-brand pt-3 d-flex flex-row flex-wrap align-items-center w-100 gap-2">
      {
        logoStyle !== TenantLogoStyle.TextOnly && theme.logo && <img className={logoClass} src={theme.logo} />
      }
      {
        logoStyle !== TenantLogoStyle.LogoOnly && (
          <Typography bold color="bright" upper size="34" noLinePadding wrap>
            {titles.siteHeader || tenant?.name || "Ticketsystem"}
          </Typography>
        )
      }
    </Hyperlink>
  );

}