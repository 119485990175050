import React from "react";
import { ModalType } from "../../../config/ModalTypes";
import useFilteredTickets, { TicketFilter, TicketSubcategory } from "../../../hooks/useFilteredTickets";
import useFilterQuery from "../../../hooks/useFilterQuery";
import useModalNotifications from "../../../hooks/useModalNotifications";
import { useTicketsWithFilter } from "../../../state/api/tickets/useTicketsWithFilter";
import { AppRoutes } from "../../../config/AppRoutes";

export default function TicketEscalatedAlert() {
  const {
    tickets
  } = useTicketsWithFilter({
    show: TicketFilter.ForMe,
    subcategory: TicketSubcategory.Escalated,
    limit: 1
  })

  const showNotification = useModalNotifications();

  const {
    getFilterForRoute,
  } = useFilteredTickets();

  const {
    getUrlWithFilter
  } = useFilterQuery();

  React.useEffect(() => {
    if (!tickets?.length) return;

    const link = getFilterForRoute({
      subcategory: TicketSubcategory.Escalated,
      show: TicketFilter.ForMe
    });

    showNotification({
      id: tickets[0]._id,
      text: "Es wurde ein Ticket an Sie eskaliert. Bitte überprüfen Sie Ihre Tickets.",
      type: ModalType.Error,
      title: "Eskalation",
      action: {
        text: "Tickets überprüfen",
        to: getUrlWithFilter(AppRoutes.Tickets.path, link)
      }
    })

  }, [tickets]);

  return <></>
}