import React from "react";
import { ticketAssignToTeam } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import { useUsers } from "../../../state/api/user/useUsers";
import { ITicket, IUser } from "../../../types/ApiTypes";
import WithPermissions from "../../permissions/WithPermissions";
import AssignmentUserItem from "./AssignmentUserItem";
import { ITeam } from "../../../types/team.schema";

export interface IAssignTicketToOtherTeamMemberButtonProps {
    userId: string, 
    team: ITeam, 
    ticket: ITicket, 
    afterAssigningTicket?: () => void
}

export default function AssignTicketToOtherTeamMemberButton(props: IAssignTicketToOtherTeamMemberButtonProps) {
    
    const {
        userId,
        team, 
        ticket, 
        afterAssigningTicket
    } = props;

    const { 
        usersById
    } = useUsers();

    const realUser = usersById[userId];

    const permissions = useTicketPermission("update", ticket);
    const callApi = useApi();

    const switchTeamsForTicket = async (team: ITeam, assignee: IUser | string) => {
        const res = await callApi(ticketAssignToTeam({teamId: team._id, assignee: typeof assignee === "string" ? assignee : assignee._id, ticketId: ticket._id}));
        if (!res) return;
        if (afterAssigningTicket) afterAssigningTicket();
    }

    const item = (<AssignmentUserItem user={realUser} onClick={async () => await switchTeamsForTicket(team, realUser)}/>);

    if (!item) return null;

    return (
        <WithPermissions permissions={permissions}>
            {item}        
        </WithPermissions>
    )
}