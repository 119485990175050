import React from "react";
import Table from "../tables/Table";
import { ISortedClientResponsibilities } from "../../hooks/useClientResponsibility";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import { useAppSelector } from "../../hooks/reduxHooks";
import Card from "../card/Card";
import UserRoleIndicator from "../user/RoleBanner";
import LabelButton from "../buttons/LabelButton";
import { useUser } from "../../state/api/user/useUser";
import { useTenant } from "../../state/api/tenant/useTenant";

export default function ClientResponsibilityItem({responsibilities}: {responsibilities: ISortedClientResponsibilities}) {
    
    const [datevIdVisible, setDatevIdVisible] = React.useState<boolean>(false);
    const [dbIdVisible, setDbIdVisible] = React.useState<boolean>(false);

    const { user } = useUser();
    const { tenant } = useTenant();

    if (!responsibilities) return null;

    return (
        <TableRow>
            <TableCell>
                <div className="d-flex flex-column gap-1 align-items-start">
                    <strong>{responsibilities.client.number}</strong>
                    {
                        user && user.isSuperAdmin && (
                            <div className="d-flex flex-column align-items-start gap-2">
                                { datevIdVisible && <span>{responsibilities.client.id}</span> }
                                <LabelButton onClick={async () => setDatevIdVisible(!datevIdVisible)} text={datevIdVisible ? "Ausblenden" : "DATEV-ID einblenden"} />
                            </div>
                        )
                    }
                    {
                        user && user.isSuperAdmin && (
                            <div className="d-flex flex-column align-items-start gap-2">
                                { dbIdVisible && <span>{responsibilities.client._id}</span> }
                                <LabelButton onClick={async () => setDbIdVisible(!dbIdVisible)} text={dbIdVisible ? "Ausblenden" : "DB-ID einblenden"} />
                            </div>
                        )
                    }
                </div>
            </TableCell>
            <TableCell content={responsibilities.client.name} />
            <TableCell>
                <div className="d-flex flex-column gap-2">
                    {
                        responsibilities.responsibilities && responsibilities.responsibilities.length 
                        ? responsibilities.responsibilities.map(r => (
                            <div key={`${responsibilities.client._id}-${r.areaOfResponsibility._id}`} className="d-flex flex-column">
                                <span>{r.areaOfResponsibility.name}</span>
                                <div className="d-flex flex-row align-items-center flex-wrap gap-2">
                                    {
                                        r.employees && r.employees.length
                                        ? r.employees.map(e => <UserRoleIndicator user={e.user} key={`${responsibilities.client._id}-${r.areaOfResponsibility._id}-${e._id}`} displayNameAsValue tenant={tenant} />)
                                        : <span>Keine Mitarbeiter</span>
                                    }
                                </div>
                            </div>
                        ))
                        : <span>Keine Zuständigkeiten</span>
                    }
                </div>
            </TableCell>
        </TableRow>
    )
}