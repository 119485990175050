import React from "react";
import { formatDateTiggiStyle } from "../../../util/formatter";
import Button from "../../buttons/Button";
import MonthlyCalendar from "../../calendar/MonthlyCalendar";
import Expandable from "../../comboBox/Expandable";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import "./DateTimePicker.css";
import TimeSelect, { TimeVariant } from "./TimeSelect";

export interface IDateTimePickerPropsBase {
  label?: string,
  readOnly?: boolean,
  dateOnly?: boolean,
  step?: number,
  bold?: boolean,
  min?: Date,
  max?: Date
}

export interface IDateTimePickerProps extends IDateTimePickerPropsBase {
  onChange: (date: Date) => void,
  date: Date
}


export default function DateTimePicker(props: IDateTimePickerProps) {
  const {
    onChange,
    date,
    label,
    dateOnly,
    bold,
    readOnly,
    step = 1,
    min,
    max
  } = props;

  const [availableMinutes, setAvailableMinutes] = React.useState<number[]>([]);
  const [availableHours, setAvailableHours] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (min && min > date) onChange(min);
    if (max && max < date) onChange(max);
  }, [min, max]);

  React.useEffect(() => {
    const minutes = getAvailableValues(TimeVariant.Minutes);
    const hours = getAvailableValues(TimeVariant.Hours);

    setAvailableMinutes(minutes);
    setAvailableHours(hours);
  }, [step]);

  React.useEffect(() => {
    if (!availableHours?.length || !availableMinutes?.length) return;
    if (!date) return;

    const newDate = new Date(date);

    const currentHour = newDate.getHours();
    const currentMinute = newDate.getMinutes();

    const getNextValue = (values: number[], current: number): number => {
      if (values.includes(current)) return current;

      for (const a of values) {
        if (a > current) return a;
      }

      return values[0];
    }

    const nextMinute = getNextValue(availableMinutes, currentMinute);
    const nextHour = getNextValue(availableHours, (
      (nextMinute !== currentMinute && nextMinute === 0)
        ? currentHour + 1
        : currentHour
    ))


    newDate.setHours(nextHour);
    newDate.setMinutes(nextMinute);

    onChange(newDate);
  }, [availableMinutes, availableHours]);

  const getAvailableValues = (variant: TimeVariant): Array<number> => {

    const max = variant === TimeVariant.Hours ? 24 : 60;
    const modifier = variant === TimeVariant.Hours ? 60 : 1;
    const divider = step / modifier < 1 ? 1 : step / modifier;

    const points = max / divider;
    const result = [0];

    for (let i = 1; i < points; i++) {
      result.push(divider * i);
    }

    return result;
  }

  const changeTime = (val: number, variant: TimeVariant) => {
    const newDate = new Date(date);
    newDate[`set${variant}`](val);

    onChange(newDate);
  }

  const calendar = (
    <MonthlyCalendar
      uniColor
      color={undefined}
      onChange={d => {
        console.log(d);
        const newDate = new Date(d);
        newDate.setHours(date.getHours());
        newDate.setMinutes(date.getMinutes());
        onChange(newDate);
      }}
      min={min}
      max={max}
      selectedDay={date}
    />
  );

  if (!date || !(date instanceof Date)) return null;

  return (
    <Flex gap="1">
      {
        label && <Typography bold={bold} color="primary">{label}</Typography>
      }
      {
        readOnly
          ? <Typography bold upper color="primary">{formatDateTiggiStyle(date, dateOnly)}</Typography>
          : (
            <Expandable expander={<Button variant="subtle" color="primary">{formatDateTiggiStyle(date, dateOnly)}</Button>}>
              {
                dateOnly
                  ? calendar
                  : (
                    <div className="d-flex flex-sm-row flex-column gap-3 date-time-picker-container">
                      {calendar}
                      <div
                        className="d-flex flex-column flex-sm-row align-center"
                        style={{
                          backgroundColor: "var(--backgroundLightest)"
                        }}
                      >
                        <TimeSelect
                          value={date.getHours()}
                          onChange={changeTime}
                          values={availableHours}
                          variant={TimeVariant.Hours}
                        />
                        <TimeSelect
                          value={date.getMinutes()}
                          onChange={changeTime}
                          values={availableMinutes}
                          variant={TimeVariant.Minutes}
                        />
                      </div>
                    </div>
                  )
              }
            </Expandable>
          )
      }
    </Flex>
  )
}