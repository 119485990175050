import { IMongooseDocument } from "./ApiTypes";

export enum FolderType {
  Inbox = "inbox",
  Tickets = "tickets",
  SentItems = "sentitems",
  Other = "other"
}

export interface IMailfolderDocument {
  displayName: string,
  type: FolderType,
}

export interface IMailfolder extends IMailfolderDocument, IMongooseDocument {
  unreadItemCount: number,
  parentFolderId: string,
  isRoot?: boolean,
  officeAccount: string,
  folderId: string
}

export const TicketFolderName = "Tickets";
export const FoldersPageSize = 250;
