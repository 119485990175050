import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import PageLoader from "../loader/PageLoader";
import { Status } from "../../config/Status";
import InvoiceItem from "./InvoiceItem";
import Table from "../tables/Table";
import { useTenant } from "../../state/api/tenant/useTenant";
import { useInvoices } from "../../state/api/subscriptions/useInvoices";
import WithPermissions from "../permissions/WithPermissions";

export default function InvoiceOverview() {

    const { tenant } = useTenant();
    const { invoices, loadingInvoices } = useInvoices(tenant?._id || "");

    return (
        <WithPermissions permissions={ ["billing.tenant.read"]}>
            {
                loadingInvoices
                ? <PageLoader />
                : (
                    invoices && invoices.length
                    ? <Table 
                        headers={[{label: "Rechnungsdatum"}, {label: "Ende Abrechnungsperiode"}, {label: "Betrag"}, {label: "Status"}, {label: "Aktionen"}]}
                        renderItem={i => <InvoiceItem key={i._id} invoice={i} />}
                        items={invoices}
                    />
                    : <span>Noch keine Rechnungen verfügbar</span>
                )
            }
        </WithPermissions>
    )
}