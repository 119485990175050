import { FieldArray } from "formik";
import React from "react";
import { tasksCreate, tasksUpdate } from "../../api/Api";
import useApi from "../../hooks/useApi";
import useTaskUtil from "../../hooks/useTaskUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { useTasks } from "../../state/api/tasks/useTasks";
import { useUpcomingTasks } from "../../state/api/tasks/useUpcomingTasks";
import { useUser } from "../../state/api/user/useUser";
import { ITask, ITaskAssignment, ITaskDocument, ITaskReminder, TaskRecurrence } from "../../types/task.schema";
import { getId } from "../../util/mongoUtil";
import Button from "../buttons/Button";
import EditButton from "../buttons/EditButton";
import Card from "../card/Card";
import Select from "../comboBox/Select";
import Flex from "../container/Flex";
import FormikDateTimePicker from "../forms/datetime/FormikDateTimePicker";
import FormikField from "../forms/FormikField";
import Icon from "../icons/Icon";
import ModalForm, { ModalTrigger } from "../modal/ModalForm";
import Pill from "../pills/Pill";
import Editor from "../richText/Editor";
import RichTextViewer from "../richText/RichTextViewer";
import Typography from "../text/Typography";
import UserSelect from "../user/UserSelect";
import DeleteTaskButton from "./DeleteTaskButton";
import TaskAttendanceStatus from "./TaskAttendancePill";
import UpdateTaskStatusButton from "./UpdateTaskStatusButton";

export interface IUpdateTaskButtonProps {
  start?: Date,
  task?: ITask,
  ticket?: string,
  button?: ModalTrigger
}

export default function UpdateTaskButton(props: IUpdateTaskButtonProps) {

  const {
    task,
    start,
    button,
    ticket
  } = props;

  const {
    getAttendanceStatusLabel,
    getAttendanceStatusColor,
    getAttendanceStatusIcon,
    getReminderLabel,
    getAvailableReminders
  } = useTaskUtil();

  const {
    user
  } = useUser();

  const { reloadTasks } = useTasks({ enabled: false });
  const { reloadUpcomingTasks } = useUpcomingTasks({ enabled: false });

  const {
    getName
  } = useUserUtil();

  const availableReminders = getAvailableReminders();
  const callApi = useApi();

  const canEdit = !task || getId(user) !== getId(task.createdBy);

  const getTitle = () => {
    if (!task) return "Neuer Termin";
    if (!canEdit) return task.subject;  
    return "Termin bearbeiten";
  }

  return (
    <ModalForm
      button={button ?? <EditButton variant={task ? "subtle" : "solid"} createText="Neuer Termin" editText="Bearbeiten" readOnly={!canEdit}  isCreate={!task} />}
      title={getTitle()}
      enableReinitialize
      initialValues={task ?? {
        assignedTo: [],
        description: "",
        recurrenceType: TaskRecurrence.None,
        recurrenceOptions: {
          repeatEvery: 1
        },
        reminders: [],
        start,
        subject: "",
        ticket
      } as ITaskDocument}
      sidebar={(formik, close) => (
        <Flex row fullWidth justify="between">
          {
            task && (
              canEdit 
              ? <DeleteTaskButton task={task} afterDelete={close} />
              : <UpdateTaskStatusButton task={task} afterClick={close} />
            )
          }
          <Button
            className="ms-auto"
            onClick={canEdit ? formik.submitForm : () => close()}
            text={canEdit ? "Speichern" : "Schließen"}
            icon={canEdit ? "save" : "x"}
            color={canEdit ? "success" : "primary"}
          />
        </Flex>
      )}
      onSubmit={async (values) => {

        if (!canEdit) return false;

        const res = await callApi(
          task ? tasksUpdate(values as ITask) : tasksCreate(values)
        )

        if (!res?.success) return false;

        await Promise.all([
          reloadTasks(),
          reloadUpcomingTasks()
        ]);

        return true;
      }}
    >
      {
        formik => (
          <Flex fullWidth gap="4">
            <Flex fullWidth gap="3">
              {
                canEdit && <FormikField bold name="subject" label="Betreff" className="w-100" />
              }
              <Flex row fullWidth justify="between" align="end">
                <Flex row align="end" gap={canEdit ? 2 : 4}>
                  <FormikDateTimePicker readOnly={!canEdit} name="start" bold={canEdit} label="Start" step={15} min={start} />
                  {
                    !!formik.values.end && (<FormikDateTimePicker readOnly={!canEdit} bold={canEdit} name="end" label="Ende" min={formik.values.start} step={15} />)
                  }
                  {
                    canEdit && (
                      <Button
                        icon={!!formik.values.end ? "dash" : "plus"}
                        text={!!formik.values.end ? "Ende entfernen" : "Ende hinzufügen"}
                        color={!!formik.values.end ? "error" : "primary"}
                        onClick={() => {
                          if (!formik.values.end) formik.setFieldValue("end", new Date());
                          else formik.setFieldValue("end", undefined);
                        }}
                        variant="text"
                      />

                    )
                  }
                </Flex>
                {
                  canEdit && (
                    <Flex row>
                      {
                        formik.values.recurrenceType !== TaskRecurrence.None && (
                          <>
                            {
                              !!formik.values.recurrenceOptions.end && (
                                <FormikDateTimePicker
                                  name="recurrenceOptions.end"
                                  dateOnly
                                />
                              )
                            }
                            <Button
                              icon={!!formik.values.recurrenceOptions?.end ? "dash" : "plus"}
                              text={!!formik.values.recurrenceOptions?.end ? "Ende entfernen" : "Ende hinzufügen"}
                              color={!!formik.values.recurrenceOptions?.end ? "error" : "primary"}
                              onClick={() => {
                                if (!formik.values.recurrenceOptions?.end) formik.setFieldValue("recurrenceOptions.end", new Date());
                                else formik.setFieldValue("recurrenceOptions.end", undefined);
                              }}
                              variant="text"
                            />
                          </>
                        )
                      }
                      <Select
                        icon={formik.values.recurrenceType === TaskRecurrence.None ? "plus" : "arrow-clockwise"}
                        text={formik.values.recurrenceType === TaskRecurrence.None ? "Wiederholung" : undefined}
                        onChange={v => formik.setFieldValue("recurrenceType", v)}
                        values={[
                          { data: TaskRecurrence.None, label: "Einmaliges Event" },
                          { data: TaskRecurrence.Daily, label: "Täglich" },
                          { data: TaskRecurrence.Weekly, label: "Wöchentlich" },
                          { data: TaskRecurrence.Monthly, label: "Monatlich" },
                          { data: TaskRecurrence.Yearly, label: "Jährlich" },
                        ]}
                        value={formik.values.recurrenceType}
                        closeOnSelect
                      />
                    </Flex>
                  )
                }
              </Flex>
            </Flex>
            {
              (canEdit && formik.values.recurrenceType !== TaskRecurrence.None) && (
                <Flex fullWidth gap="3">
                  <Typography bold color="primary">Wiederholung</Typography>
                  <Flex row>
                    <Typography color="primary">Alle</Typography>
                    <FormikField
                      name="recurrenceOptions.repeatEvery"
                      type="number"
                      min="1"
                      style={{ maxWidth: 60 }}
                    />
                    <Typography color="primary">
                      {
                        formik.values.recurrenceType === TaskRecurrence.Daily
                          ? "Tag(e)"
                          : formik.values.recurrenceType === TaskRecurrence.Weekly
                            ? "Woche(n)"
                            : formik.values.recurrenceType === TaskRecurrence.Monthly
                              ? "Monat(e)"
                              : "Jahr(e)"
                      }
                    </Typography>
                  </Flex>
                  {
                    formik.values.recurrenceType === TaskRecurrence.Weekly && (
                      <Select
                        icon="calendar"
                        placeholder="Wochentage"
                        onChange={v => formik.setFieldValue("recurrenceOptions.weekDays", v)}
                        values={[
                          { data: 1, label: "Montag" },
                          { data: 2, label: "Dienstag" },
                          { data: 3, label: "Mittwoch" },
                          { data: 4, label: "Donnerstag" },
                          { data: 5, label: "Freitag" },
                          { data: 6, label: "Samstag" },
                          { data: 0, label: "Sonntag" },
                        ]}
                        value={formik.values.recurrenceOptions.weekDays}
                        allowMultiple
                      />
                    )
                  }
                  <Flex row gap="3">
                    {
                      (formik.values.recurrenceType === TaskRecurrence.Monthly || formik.values.recurrenceType === TaskRecurrence.Yearly) && (
                        <FormikField
                          bold
                          name="recurrenceOptions.dayOfMonth"
                          label="Tag im Monat"
                          min="1"
                          max="28"
                          type="number"
                          style={{ maxWidth: 75 }}
                        />
                      )
                    }
                    {
                      formik.values.recurrenceType === TaskRecurrence.Yearly && (
                        <Select
                          label="Monat"
                          bold
                          closeOnSelect
                          onChange={v => formik.setFieldValue("recurrenceOptions.month", v)}
                          value={formik.values.recurrenceOptions.month}
                          values={[
                            { data: 1, label: "Januar" },
                            { data: 2, label: "Februar" },
                            { data: 3, label: "März" },
                            { data: 4, label: "April" },
                            { data: 5, label: "Mai" },
                            { data: 6, label: "Juni" },
                            { data: 7, label: "Juli" },
                            { data: 8, label: "August" },
                            { data: 9, label: "September" },
                            { data: 10, label: "Oktober" },
                            { data: 11, label: "November" },
                            { data: 12, label: "Dezember" },
                          ]}
                        />
                      )
                    }
                  </Flex>
                </Flex>
              )
            }
            <Flex fullWidth>
              <Typography bold color="primary">Beschreibung</Typography>
              {
                canEdit 
                ? (
                  <Editor
                    useFieldStyle
                    onChange={(val: string) => formik.setFieldValue("description", val)}
                    content={formik.values.description}
                  />
                )
                : <RichTextViewer content={formik.values.description} />
              }
            </Flex>
            {
              canEdit && (

                <FieldArray name="reminders">
                  {
                    arrayHelpers => (
                      <Flex fullWidth>
                        <Flex row justify="between" fullWidth>
                          <Typography bold color="primary">Erinnerungen</Typography>
                          <Select
                            icon={
                              formik.values.reminders.length
                                ? "pen"
                                : "plus"
                            }
                            text={
                              formik.values.reminders.length
                                ? "Erinnerungen bearbeiten"
                                : "Erinnerung hinzufügen"
                            }
                            enableReinitialize
                            getLabel={getReminderLabel}
                            onChange={(v, all) => {
                              if (!all) {
                                formik.setFieldValue("reminders", []);
                                return;
                              }

                              const newValues = [];

                              for (const a of all) {
                                const existing = formik.values.reminders?.find(b => b.minutesBefore === a);
                                if (!existing) newValues.push({ minutesBefore: a } as ITaskReminder);
                                else newValues.push(existing);
                              }

                              formik.setFieldValue("reminders", newValues);
                            }}
                            allowMultiple
                            value={formik.values.reminders.map(r => r.minutesBefore)}
                            values={availableReminders}
                          />
                        </Flex>
                        <Flex row wrap fullWidth>
                          {
                            !!formik.values.reminders?.length
                              ? formik.values.reminders.map((u, index) => {
                                return (
                                  <Card fitContent noPadding key={`task-reminder-${index}`}>
                                    <Flex row className="p-1 ps-3">
                                      <Icon color="primary" icon="clock" />
                                      <Typography bold upper color="primary" userSelect="none">
                                        {
                                          getReminderLabel(u.minutesBefore)
                                        }
                                      </Typography>
                                      <Button
                                        variant="text"
                                        icon="trash"
                                        color="error"
                                        onClick={() => arrayHelpers.remove(index)}
                                      />
                                    </Flex>
                                  </Card>
                                )
                              })
                              : <Typography>Keine Erinnerungen</Typography>
                          }
                        </Flex>
                      </Flex>
                    )
                  }
                </FieldArray>
              )
            }
            <FieldArray name="assignedTo">
              {
                arrayHelpers => {

                  const addAssignment = (u: ITaskAssignment) => {

                    if (!u.mailAddress && !u.user) return;
                    if (formik.values.assignedTo.find(a => a.mailAddress === u.mailAddress)) return;
                    arrayHelpers.push(u);
                  }

                  return (
                    <Flex fullWidth>
                      <Flex row justify="between" fullWidth>
                        <Typography bold color="primary">Teilnehmer</Typography>
                        {
                          canEdit && (
                            <UserSelect
                              displayed="all"
                              icon="plus"
                              useDefaultValue={false}
                              placeholder="E-Mail oder Nutzer hinzufügen..."
                              allowAnyValue
                              onChange={(u) => {
                                if (!u) return;
                                addAssignment({
                                  user: typeof u === "string" ? undefined : u?._id,
                                  mailAddress: typeof u === "string" ? u : u?.mailAddress
                                });
                              }}
                            />
                          )
                        }
                      </Flex>
                      <Flex row wrap fullWidth>
                        {
                          !!formik.values.assignedTo?.length
                            ? formik.values.assignedTo.map((u, index) => {

                              const key = `${u.mailAddress}_${index}`;
                              const name = u.user ? getName(u.user) : u.mailAddress;

                              if (canEdit) {

                                if (!u.mailAddress && !u.user) return null;
  
                                const attendanceColor = getAttendanceStatusColor(u.attendance);
  
                                return (
                                  <Pill
                                    key={key}
                                    color={attendanceColor}
                                    icon={u.user ? "person" : "at"}
                                    withDivider
                                  >
                                    <Flex className="p-1" gap="0">
                                      <Flex gap="0">
                                        <Flex row gap="3">
                                          <Typography noLinePadding bold basedOnThisBackground={attendanceColor}>
                                            {name}
                                          </Typography>
                                          <Icon
                                            icon="x"
                                            onClick={() => arrayHelpers.remove(index)}
                                          />
                                        </Flex>
                                      </Flex>
                                      <Typography
                                        size="11"
                                        basedOnThisBackground={attendanceColor}
                                      >
                                        {getAttendanceStatusLabel(u.attendance)}
                                      </Typography>
                                    </Flex>
                                  </Pill>
                                )
                              }

                              return (
                                <TaskAttendanceStatus key={key} showAttendandName task={task} assignment={u} />
                              )
                            })
                            : <Typography>Keine Teilnehmer</Typography>
                        }
                      </Flex>
                    </Flex>
                  )
                }
              }
            </FieldArray>
          </Flex>
        )
      }
    </ModalForm >
  )
}