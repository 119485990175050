import React from "react";
import PageLoader from "../../loader/PageLoader";
import Table from "../../tables/Table";
import { useAnyUserAbsence } from "../../../state/api/user/useAnyUserAbsence";
import Card from "../../card/Card";
import UserAbsenceListItem from "./UserAbsenceListItem";
import Flex from "../../container/Flex";
import CreateUserAbsenceButton from "./CreateUserAbsenceButton";
import { useUser } from "../../../state/api/user/useUser";
import { AbsenceType, IUser } from "../../../types/ApiTypes";
import Expandable from "../../comboBox/Expandable";
import Button from "../../buttons/Button";
import WithPermissions from "../../permissions/WithPermissions";

export interface IUserAbsenceListProps {
    user: IUser
}

export default function UserAbsenceList({user}: IUserAbsenceListProps) {

    const {
        user: currentUser
    } = useUser();

    const { loadingUserAbsence, reloadUserAbsence, userAbsence } = useAnyUserAbsence(user);
    const isOwn = currentUser && currentUser._id === user._id;

    if (loadingUserAbsence) return <PageLoader />
    
    return (
        <WithPermissions permissions={isOwn ? ["absence.own.read"] : ["absence.all.read"]}>
            <Flex row justify="between" fullWidth>
                <h6 className="m-0 p-0 fw-bold">Urlaube</h6>
                <Expandable
                    expander={<Button text="Neues Element" icon="plus" color="success" />}
                >
                    <Flex className="p-2" fullWidth>
                        <CreateUserAbsenceButton buttonClass="w-100" user={user} type={AbsenceType.Vacation} text="Neuen Urlaub buchen" title="Neuer Urlaub" /> 
                        <CreateUserAbsenceButton buttonClass="w-100" user={user} type={AbsenceType.Sick} text="Neue Krankmeldung" title="Neue Krankmeldung" /> 
                    </Flex>
                </Expandable>
            </Flex>
            <Card>
                <Table 
                    border 
                    className="w-100" 
                    headers={[{label: "Von"}, {label: "Bis"}, {label: "Vertretung"}, {label: "Erstellt von"}, {label: "Typ"}, {label: "Quelle"}, {label: ""}]}
                    items={userAbsence || []}
                    renderItem={v => <UserAbsenceListItem key={v._id} absence={v} user={user} /> }
                />
            </Card>
        </WithPermissions>
    )
}