import React from "react";
import IElementProps from "../../types/element.types";

export const DailyAgendaDayHeight = 200;
export const DailyAgendaHeaderHeight = 120;

export interface IDailyAgendaSkeletonProps extends IElementProps {
  id: string,
  renderItem: (index: number) => React.ReactNode,
}

export default function DailyAgendaSkeleton(props: IDailyAgendaSkeletonProps) {

  const {
    renderItem,
    children,
    style,
    id
  } = props;

  return (
    <div className="position-relative" style={style}>
      {
        Array.from({ length: 24 }).fill(0).map((_, i) => (
          <div 
            style={{ height: DailyAgendaDayHeight }}
            key={`daily-agenda-skeleton-${id}-${i}`}
          >
            {renderItem(i)}
          </div>
        ))
      }
      {children}
    </div>
  )
}