import { usePointChampApiKey } from "../state/api/integrations/usePointChampApiKey";
import { useCurrentTenantRole } from "../state/api/user/useCurrentTenantRole";
import { useUser } from "../state/api/user/useUser";
import { useUsers } from "../state/api/user/useUsers";
import { IUser } from "../types/ApiTypes";
import useTeamsUtil from "./useTeamsUtil";
import useUserUtil from "./useUserUtil";

export default function useAbsenceUtil() {
    const { pointChampApiKey, loadingPointChampApiKey } = usePointChampApiKey();

    const { user, loadingUser } = useUser();
    const { role, loadingRole } = useCurrentTenantRole();

    const { users } = useUsers();

    const {
        getCurrentTenantAuthority
    } = useUserUtil();

    const {
        getAvailableTeamsForUser
    } = useTeamsUtil();

    
    const getCurrentDate = (val?: Date) => {
        const d = new Date(val || new Date());
        return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`;
    }

    const getAvailableDeputies = (user: IUser) => {
        const authority = getCurrentTenantAuthority(user);

        if (!authority || !authority.userAuthority) return [];
        if (user.isSuperAdmin) return users || [];

        const teams = getAvailableTeamsForUser(user);
        if (!teams || !teams.length) return [];
        return teams.reduce((prev, curr) => prev.concat(curr.members), [] as Array<string | IUser>);
    }

    return {
        loading: loadingUser || loadingRole || loadingPointChampApiKey,
        canAccessAbsenceManagement: user && (user.isSuperAdmin || (role && !role.isClient)),
        isIntegratedWithPointChamp: pointChampApiKey && (pointChampApiKey.domain || pointChampApiKey.isVerified),
        getAvailableDeputies: getAvailableDeputies,
        getDateInAbsenceFormat: getCurrentDate
    }
}