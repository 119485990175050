import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import "./TimeSelect.css";

export enum TimeVariant {
  Hours = "Hours",
  Minutes = "Minutes"
}

export interface ITimeSelectProps {
  value: number,
  onChange: (i: number, v: TimeVariant) => void,
  variant: TimeVariant,
  values: number[],
}

export default function TimeSelect(props: ITimeSelectProps) {

  const {
    value,
    onChange,
    variant,
    values
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!ref.current) return;
    if (!value) return;

    ref.current.scrollTo({
      top: ref.current.scrollHeight / values.length * value,
    })
  }, []);

  const title = variant === TimeVariant.Hours ? "H" : "M";

  return (
    <div className="w-100 d-flex flex-row flex-sm-column align-items-center align-items-sm-start gap-2 justify-content-start justify-content-sm-center pt-sm-2 ps-2 ps-sm-0">
      <div className="fw-bold ps-3 pe-3 text-center" style={{ color: "var(--secondary)" }}>{title}</div>
      <div
        className="h-100 w-100 d-flex flex-row flex-sm-column align-items-center"
        style={{
          scrollbarWidth: "none",
          overflow: "auto",
        }}
        ref={ref}
      >
        {
          values.map(i => {

            const isActive = value === i;

            return (
              <div
                key={`time-picker-${variant}-${i}`}
                className={generateClassName("w-100 text-center ps-3 p-1 pe-3 time-select", {
                  value: isActive,
                  onTrue: "time-select-active"
                })}
                onClick={() => onChange(i, variant)}
              >
                {i}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}