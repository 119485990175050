import React from "react";
import { useTicketActivity } from "../../../state/api/tickets/useTicketActivity";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Flex from "../../container/Flex";
import TicketActivityItem from "../TicketActivityItem";
import { ITicket } from "../../../types/ApiTypes";
import SidebarHeader from "../../menu/sidebar/SidebarHeader";
import { useTicketAssignments } from "../../../state/api/tickets/assignments/useTicketAssignments";
import TicketAssignmentItem from "../assignment/TicketAssignmentItem";
import { useTicketClientContact } from "../../../state/api/tickets/assignments/useTicketClientContact";
import { useTicketAssignee } from "../../../state/api/tickets/assignments/useTicketAssignee";
import Typography from "../../text/Typography";
import AddTicketAssignmentButton from "../assignment/UpdateTicketAssignmentForm";
import { useCurrentTenantRole } from "../../../state/api/user/useCurrentTenantRole";

export default function TicketAssignees({ ticket }: { ticket: ITicket }) {

  const {
    role
  } = useCurrentTenantRole();

  const { loadingTicketAssignee, reloadTicketAssignee, ticketAssignee } = useTicketAssignee(ticket);
  const { loadingTicketClientContact, reloadTicketClientContact, ticketClientContact } = useTicketClientContact(ticket);
  const { loadingTicketAssignments, reloadTicketAssignments, ticketAssignments } = useTicketAssignments(ticket);

  if (role && role.isClient) return null;
  if (loadingTicketAssignments) return <LoadingSpinner />

  const getFilteredAssignments = () => {
    if (!ticketAssignments || !ticketAssignments.length) return [];
    return ticketAssignments.filter(a => {
      if (!ticketAssignee && !ticketClientContact) return true;
      if (ticketAssignee && ticketAssignee._id === a._id) return false;
      if (ticketClientContact && ticketClientContact._id === a._id) return false;
      return true;
    });
  }

  const filteredAssignments = getFilteredAssignments();

  return (
    <Flex>
      <SidebarHeader text="Zuweisungen" />
      <Typography color="primary">Bearbeiter</Typography>
      {
        loadingTicketAssignee
          ? <LoadingSpinner />
          : (
            ticketAssignee
              ? <TicketAssignmentItem assignment={ticketAssignee} isNotMainAssignment={false} ticket={ticket} />
              : <span>Kein Bearbeiter</span>
          )

      }
      <Typography color="primary">Mandantenkontakt</Typography>
      {
        loadingTicketClientContact
          ? <LoadingSpinner />
          : (
            ticketClientContact
              ? <TicketAssignmentItem assignment={ticketClientContact} isNotMainAssignment={false} ticket={ticket} />
              : <span>Kein Mandantenkontakt</span>
          )

      }
      <Flex fullWidth row justify="between">
        <Typography color="primary">Weitere Zuweisungen</Typography>
        <AddTicketAssignmentButton ticket={ticket} />
      </Flex>
      {
        filteredAssignments && filteredAssignments.length
          ? filteredAssignments.map(assignment => <TicketAssignmentItem isNotMainAssignment assignment={assignment} ticket={ticket} key={assignment._id} />)
          : <span>Keine weiteren Zuweisungen</span>
      }
    </Flex>
  )
}