import React from "react";
import { logsDelete } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { useLogs } from "../../../state/api/log/useLogs";
import Button from "../../buttons/Button";

export default function DeleteLogsManuallyButton() {
    const callApi = useApi();
    const { reloadLogs } = useLogs();

    const clickHandler = async () => {
        await callApi(logsDelete());
        await reloadLogs(undefined, { revalidate: true });
    }

    return <Button onClick={clickHandler} color="error" text="Alle Logeinträge löschen" icon="trash" />
}