import { Field, Form, Formik } from "formik";
import React from "react";
import { ticketNoteAdd, ticketNoteDelete } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { useTicketNotes } from "../../../state/api/tickets/notes/useTicketNotes";
import { useUser } from "../../../state/api/user/useUser";
import { ITicket } from "../../../types/ApiTypes";
import { ITicketNote, ITicketNoteDocument } from "../../../types/ticketNotes.schema";
import { formatDate } from "../../../util/formatter";
import Button from "../../buttons/Button";
import Flex from "../../container/Flex";
import Typography from "../../text/Typography";
import RoleBanner from "../../user/RoleBanner";
import "./TicketNote.css";
import Card from "../../card/Card";
import useUserUtil from "../../../hooks/useUserUtil";
import { useUsers } from "../../../state/api/user/useUsers";
import Icon from "../../icons/Icon";

export interface ITicketNoteProps {
  note?: ITicketNote,
  ticket: string
}

export default function TicketNote(props: ITicketNoteProps) {

  const [isAddingNote, setIsAddingNote] = React.useState<boolean>(false);

  const {
    note,
    ticket
  } = props;

  const {
    usersById
  } = useUsers();

  const {
    getName
  } = useUserUtil();

  const callApi = useApi();

  const {
    user
  } = useUser();

  const {
    reloadTicketNotes,
  } = useTicketNotes(ticket);

  if (!user) return null;

  return (
    <Formik
      initialValues={(
        note ?? {
          content: "",
          ticket: ticket,
          user: user._id
        }
      ) as ITicketNoteDocument}
      onSubmit={async (values, actions) => {
        if (!!note || !isAddingNote) return;
        await callApi(ticketNoteAdd(values))
        setIsAddingNote(false);
        actions.resetForm();
        await reloadTicketNotes();
      }
      }
    >
      {
        formik => {

          const u = usersById[formik.values.user];
          const name = getName(u);

          return (
            <Form className="d-flex flex-column gap-2">
              {
                !note && (
                  <Button
                    variant="subtle"
                    icon={
                      isAddingNote
                        ? "x"
                        : "plus"
                    }
                    text={
                      isAddingNote
                        ? "Abbrechen"
                        : "Neu"
                    }
                    onClick={async () => setIsAddingNote(!isAddingNote)}
                  />
                )
              }
              {
                (note || isAddingNote) && (
                  <Card
                    color="#eeec60"
                    strongColor
                    noPadding
                    noHeaderPadding
                    header={(
                      <Flex
                        fullWidth
                        row
                        justify="between"
                        className="pt-1 ps-3 pe-2"
                      >
                        <Flex gap="0">
                          <Typography>
                            {name ?? "Kein Ersteller"}
                          </Typography>
                          <Typography size={9} >
                            {formatDate(note?.createdAt ?? new Date())}
                          </Typography>
                        </Flex>
                        {
                          !!note && (
                            <Icon
                              icon="x"
                              onClick={async () => {
                                const res = await callApi(ticketNoteDelete(note));
                                if (!res || !res.success) return;
                                await reloadTicketNotes();
                              }}
                            />
                          )
                        }
                      </Flex>
                    )}
                  >
                    <Flex className="p-2">
                      <Field
                        name="content"
                        placeholder="Notiz hinzufügen"
                        as="textarea"
                        className="ticket-note-text"
                        disabled={!!note}
                      />
                    </Flex>
                  </Card>
                )
              }
              {
                isAddingNote && (
                  <Button
                    icon="save"
                    text="Speichern"
                    loading={formik.isSubmitting}
                    type="submit"
                  />
                )
              }
            </Form>
          )
        }
      }
    </Formik >
  )
}