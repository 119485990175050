
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IDatevAddresseeRelationshipType } from "../../../types/ApiTypes";
import { Sorted } from "../../../types/collection.types";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useAddresseeRelationshipTypes = () => {
    const { data, isLoading, mutate } = useTiggiQuery<Sorted<IDatevAddresseeRelationshipType>>(ApiRoutes.Addressees.RelationshipTypes);

    return {
        addresseeRelationshipTypesById: data?.byId ?? {},
        addresseeRelationshipTypes: data?.items ?? [],
        loadingAddresseeRelationshipTypes: isLoading,
        reloadAddresseeRelationshipTypes: mutate
    }
} 