import React from "react";
import AddresseeDetail from "../../components/addressee/AddresseeDetail";
import PageLoader from "../../components/loader/PageLoader";
import { AppRouteParams } from "../../config/AppRoutes";
import useRouteParam from "../../hooks/useRouteParam"
import Page from "../../components/container/Page";
import { useAddressee } from "../../state/api/addressees/useAddressee";
import { Locale } from "../../locale/Locale";
import Typography from "../../components/text/Typography";
import Flex from "../../components/container/Flex";
import AddresseeDuplicateStatus from "../../components/addressee/AddresseeDuplicateStatus";
import PageHeader from "../../components/label/PageHeader";

export default function Addressee() {

  const addresseeId = useRouteParam(AppRouteParams.Addressee);

  const {
    addressee,
    loadingAddressee
  } = useAddressee(addresseeId);

  return (
    <Page
      header={(
        <Flex row fullWidth justify="between">
          <PageHeader showBackButton text={addressee?.actualName ?? Locale.titles.addresseeDetail} />
          {
            addressee?.duplicateReason && <AddresseeDuplicateStatus addressee={addressee} />
          }
        </Flex>
      )}
    >
      {
        loadingAddressee
          ? <PageLoader />
          : (
            addressee 
            ? <AddresseeDetail addressee={addressee} />
            : <Typography>Fehler beim Laden</Typography>
          )
      }
    </Page >
  )

}