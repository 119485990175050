import { ApiRoutes } from "../../../api/ApiRoutes";
import useFilterQuery from "../../../hooks/useFilterQuery";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { Sorted } from "../../../types/collection.types";
import { ITask } from "../../../types/task.schema";

export const useRecurringTasks = (day?: Date) => {

  const { getUrlWithFilter } = useFilterQuery();

  const getFirstDayOfMonth = () => {
    if (!day) return null;
    const d = new Date(day);
    d.setDate(-5);
    return d;
  }

  const getLastdayOfMonth = () => {
    if (!day) return null;
    const d = new Date(day);
    d.setMonth(d.getMonth() + 1);
    d.setDate(6);
    return d;
  }

  const from = getFirstDayOfMonth();
  const to = getLastdayOfMonth();

  const { data, isLoading, mutate } = useTiggiQuery<Sorted<ITask, "string", Array<ITask>>>(getUrlWithFilter(ApiRoutes.Tasks.Recurring, {
    from: from?.toISOString(),
    to: to?.toISOString()
  }), { enabled: !!from && !!to });

  return {
    recurringTasks: data?.items ?? [],
    recurringTasksByDate: data?.start ?? {},
    recurringTasksById: data?.byId ?? {},
    loadingRecurringTasks: isLoading,
    reloadRecurringTasks: mutate
  }
} 