import React from "react";
import useTenantSettings from "../../hooks/useTenantSettings";
import { useTeams } from "../../state/api/teams/useTeams";
import { useAnyTeamTicketStatistics } from "../../state/api/tickets/statistics/useAnyTeamTicketStatistics";
import { useAnyUserTicketStatistics } from "../../state/api/tickets/statistics/useAnyUserTicketStatistics";
import { useUser } from "../../state/api/user/useUser";
import Flex from "../container/Flex";
import WithPermissions from "../permissions/WithPermissions";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import Typography from "../text/Typography";
import ProductivityCard from "./ProductivityCard";
import "./ProductivityOverview.css";
import TeamProductivityOverview from "./views/TeamProductivityOverview";
import TenantProductivityOverview from "./views/TenantProductivityOverview";
import UserProductivityOverview from "./views/UserProductivityOverview";
import useLabels, { Label } from "../../hooks/useLabels";

export enum TicketProductivityTab {
  Team = "team",
  Personal = "personal",
  Tenant = "tenant"
}

export default function ProductivityOverview() {

  const [teamId, setTeamId] = React.useState<string>();
  const [userId, setUserId] = React.useState<string>();

  const [tab, setTab] = React.useState<string>(TicketProductivityTab.Personal);

  const { tickets } = useTenantSettings(true);

  const getLabel = useLabels();

  const { user } = useUser();
  const { teams } = useTeams();

  React.useEffect(() => {
    if (!teams || !teams.length) return;
    if (teamId) return;

    setTeamId(teams[0]._id);
  }, [teams]);

  const { loadingUserTicketStatistics, userTicketStatistics } = useAnyUserTicketStatistics(userId || user?._id || "");
  const { loadingTeamTicketStatistics, teamTicketStatistics } = useAnyTeamTicketStatistics(teamId || "");

  const isPerformingOverRequirement = (val: number) => {
    const requirement = tickets?.maxMinutesToFirstResponse || 0;
    return val < requirement;
  }

  return (
    <Flex className="w-100 mb-5" gap={3}>
      <Flex row className="w-100" justify="between">
        <Typography color="primary" className="w-100" size={24}>Deine Tickets im Überblick</Typography>

      </Flex>
      <Flex className="w-100">
        <Typography color="primary" size={20} >Durchschnittl. Antwortzeit erste Nachricht</Typography>
        <div className="response-times-grid w-100">
          <WithPermissions permissions={["statistics.own.read"]}>
            <ProductivityCard
              title="Du"
              icon="person-fill"
              color={isPerformingOverRequirement(userTicketStatistics?.durations?.minutesUntilFirstMessage || 0) ? "success" : "error"}
              value={userTicketStatistics?.durations?.minutesUntilFirstMessage || 0}
              loading={loadingUserTicketStatistics}
            />
          </WithPermissions>
          <WithPermissions permissions={["statistics.team.read"]}>
            <ProductivityCard
              title="Dein Team"
              icon="people-fill"
              color={isPerformingOverRequirement(teamTicketStatistics?.durations?.minutesUntilFirstMessage || 0) ? "success" : "error"}
              value={teamTicketStatistics?.durations?.minutesUntilFirstMessage || 0}
              loading={loadingTeamTicketStatistics}
            />
          </WithPermissions>
          <ProductivityCard title="Vorgabe" icon="arrow-up-right" color="muted" value={tickets?.maxMinutesToFirstResponse || 0} loading={false} />
        </div>
      </Flex>
      <TabSwitcher
        tabQueryParamKey="view"
        saveActiveTab={(tab) => setTab(tab as string)}
        tabs={[
          { data: TicketProductivityTab.Personal, label: "Persönlich", permissions: ["statistics.own.read"] },
          { data: TicketProductivityTab.Team, label: "Team", permissions: ["statistics.team.read"] },
          { data: TicketProductivityTab.Tenant, label: getLabel(Label.TenantName), permissions: ["statistics.tenant.read"] }
        ]}
      />
      {
        (() => {
          switch (tab) {
            case TicketProductivityTab.Personal: return <UserProductivityOverview setUserId={setUserId} userId={userId} />;
            case TicketProductivityTab.Team: return <TeamProductivityOverview setTeamId={setTeamId} teamId={teamId} />;
            case TicketProductivityTab.Tenant: return <TenantProductivityOverview />
          }
        })()
      }

    </Flex>
  )
}
